import React from 'react'
import { useSelector } from 'react-redux'
import { numberToCurrency } from '@/lib/Number'
import { selectCustomAmount, selectAmountType } from '@/features/BookingPayment/bookingPaymentSlice'

export default function PaymentInformation({ prices, balanceDue }) {
  const customAmount = useSelector(selectCustomAmount)
  const amountType = useSelector(selectAmountType)

  return (
    <div className="xbm-checkout-ledger d-block" style={{ overflowY: "visible" }}>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-6'>
          <div className="xbm-ledger-section-costs">
          {prices ? <>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Subtotal</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(prices.basePrice)}
              </div>
            </div>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Taxes &amp; Fees</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(prices.taxesAndFees)}
              </div>
            </div>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Total</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(prices.total)}
              </div>
            </div>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Payment Due</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(balanceDue)}
              </div>
            </div>

            {amountType === "remaining" && <p className="w-100 text-center"><small><em>* Remaining balance for this reservation</em></small></p>}

            </>
            : <>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Total</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(customAmount)}
              </div>
            </div>
            <div className="xbm-ledger-section-type">
              <div className="xbm-ledger-section-type-label">
                <b>Payment Due</b>
              </div>
              <div className="dots"></div>
              <div className="xbm-ledger-section-type-cost">
                {numberToCurrency(balanceDue)}
              </div>
            </div>
            </>
          }
          </div>
        </div>
      </div>
    </div>
  )
}
