import React from 'react'
import { useFormContext } from 'react-hook-form'
import DateExclusionsFields from './DateExclusionsFields'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function DateExclusions({ exclusions, errors, handleUpdate }) {
    const { register } = useFormContext()

    const field = register('date_exclusions', {
      validate: () => {
        let empty_dates = exclusions.filter((e) => !e.to || !e.from)
        return empty_dates.length === 0 || 'All dates must be filled out.'
      }
    })

    const handleAdd = () => {
        handleUpdate('date_exclusions', exclusions.concat({ from: null, to: null }))
    }

    return (
        <div className="date-exclusions mb-4">
            <h4 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Date Exclusions</span>
                    <Helpjuice articleId='date-exclusions' extraClasses='' />
                </span>
            </h4>

            <div className="drawer-side-action btn btn-link" onClick={handleAdd}>
                + Add Exclusion
            </div>

            <DateExclusionsFields
                errors={errors}
                exclusions={exclusions}
                handleUpdate={handleUpdate}
            />

            {
                errors[field.name] && (
                    <div className="invalid-feedback d-block">
                        { errors[field.name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
