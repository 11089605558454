import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    configureModal,
    openModal,
    processChitFulfillment,
    selectCurrentCheck,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectCurrentUser } from '@/features/Session/sessionSlice'

import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import ButtonGroup from '@/features/AdvancedPointOfSale/components/buttons/ButtonGroup'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'

import { useConfirm } from '@/lib/useConfirmHook'
import { sortedAndFilteredTabs } from '@/features/AdvancedPointOfSale/lib/Checks'
import { sortByAddedDateTime } from '@/features/AdvancedPointOfSale/lib/Items'
import { acknowledgementText } from '@/features/AdvancedPointOfSale/lib/Printers'
import scrollHinting from '@/lib/ScrollHinting'
import titleize from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function ViewChit({ onFulfilled=() => {} }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const check       = useSelector(selectCurrentCheck)
    const currentUser = useSelector(selectCurrentUser)

    const [selectedChit, setSelectedChit] = useState(null)
    const [isEmpty, setIsEmpty] = useState(null)
    const [unavailableItemIds, setUnavailableItemIds] = useState([])
    const [unavailableModifierIds, setUnavailableModifierIds] = useState([])

    const chitServers = useMemo(() => (
        (check?.chits || []).filter(chit => !chit.fulfilled_at && chit.server).map(chit => chit.server)
    ), [check])

    const handleSelectServer = (serverID) => {
        const chit = check.chits.find(chit => (
            !chit.fulfilled_at && (chit.server.id === parseInt(serverID))
        ))

        setSelectedChit(chit)
    }

    const isModifierUnavailable = (config) => (
        !!config.find(modifier => (modifier.chit_action === null || modifier.chit_action === 'add') && unavailableModifierIds.includes(modifier.id))
    )

    const handleManageItem = (tab, data, isUnavailable, isModifierUnavailable) => {
        dispatch(configureModal({ modal: 'manageChitItem', config: { tab, item:data, isUnavailable, isModifierUnavailable, unavailableModifierIds }}))
        dispatch(openModal('manageChitItem'))
    }

    const handleProcessChitFulfillment = async () => {
        dispatch(processChitFulfillment(selectedChit)).then((data) => {
            if (data.success) {
                onFulfilled()

                if (data.with_errors) {
                    const delay = 1800

                    dispatch(configureModal({
                        modal: 'popModal',
                        config: { text: data.message, icon: 'fa-check bg-warning', delay }
                    }))
                    dispatch(openModal('popModal'))

                    window.setTimeout(async () => {
                        if (
                            !await confirm(
                                acknowledgementText(data, data.jobs_created), {
                                header_text: 'Warning!',
                                confirm_only: true,
                                confirm_text: 'OK',
                                dangerous: true,
                                rawHTML: true,
                                size: 'md'
                            })
                        )  {
                            return
                        }
                    }, delay + 50)
                }
            } else {
                if (data.unavailable_item_ids || data.unavailable_modifier_ids) {
                    setUnavailableItemIds(data.unavailable_item_ids)
                    setUnavailableModifierIds(data.unavailable_modifier_ids)
                }
            }
        })
    }

    useEffect(() => {
        scrollHinting.search()
    }, [])

    useEffect(() => {
        const chit = (check.chits || []).find(chit => (
            !chit.fulfilled_at && (chit.server?.id === parseInt(currentUser.id))
        ))

        setSelectedChit(chit)
    }, [currentUser, check])

    useEffect(() => {
        if (selectedChit) {
            setIsEmpty(
                sortedAndFilteredTabs(selectedChit.tabs)
                    .filter((t) => !t.is_closed)
                    .reduce((total, tab) => total + (tab?.items || []).length, 0) === 0
            )
        }
    }, [selectedChit])

    return (
        <>
            {/^(super|company_admin|manager)$/i.test(currentUser.role) &&
                <div className='w-100'>
                    <select
                        className='custom-select'
                        value={selectedChit?.server?.id || ''}
                        onChange={e => handleSelectServer(e.target.value)}
                        >
                        <option value=''>Select Server</option>
                        {chitServers.map(server => <option key={server.id} value={server.id}>{server.name}</option>)}
                    </select>
                </div>
            }
            <div className={`d-flex align-items-center justify-content-end text-white pt-2 ${debug && !!selectedChit ? 'pb-2' : ''} w-100`}>
                { debug && !!selectedChit && <small className='d-block px-2'>CHIT ID: {selectedChit?.id}</small>}
            </div>

            <div className={`tab--content scrollhint ${isEmpty ? 'd-flex align-items-center justify-content-center' : ''}`.trim()}>
                <div className='scrollhint--inner'>
                    {
                        isEmpty ? (
                            <CenteredText text='Empty' textClassName='text-gray2' />
                        ) : <>
                            {
                                (sortedAndFilteredTabs(selectedChit?.tabs) || []).filter((tab) => !tab.is_closed).map((tab) => (
                                    <div key={tab.id} className='order-queue-item mb-4'>
                                        <h6 className='border-bottom thick-border px-2 pb-2 mb-1'>
                                            <span className='d-flex align-items-center'>
                                                {
                                                    tab.is_tab_of_primary_check_owner && (
                                                        <i className='fas fa-star text-yellow mr-2' />
                                                    )
                                                }

                                                { tab.membership &&
                                                    <span className='badge badge-info mr-2' style={{ fontSize: '75%', padding: '4px 6px', position: 'relative', top: '-1px' }}>
                                                        <i className="fa-solid fa-id-badge"></i>
                                                    </span>
                                                }

                                                { tab.is_general && check.booking?.name || tab.name }

                                                <TabStatus tab={check.tabs.find(checkTab => checkTab.uuid === tab.uuid)} />
                                            </span>
                                        </h6>

                                        {
                                            tab.items.length === 0
                                            ? <em className='text-gray4'>— None</em>
                                            : sortByAddedDateTime(tab.items).map((data, index) => {
                                                const { item, config, notes } = data
                                                const isUnavailable = unavailableItemIds.includes(item.id)

                                                const itemModifiers = [...config].filter((modifier) => modifier.is_available && modifier.chit_action !== null)
                                                                                 .sort((a, b) => (a.chit_action > b.chit_action) ? 1 : -1)

                                                return (
                                                    <div key={`chit-item-${index}`} className='chit-item'>
                                                        <div className='d-flex justify-content-between align-items-center mx-1'>
                                                            <span>
                                                                {item.name}
                                                                {(isUnavailable || isModifierUnavailable(config)) && <>
                                                                    <span className='warning-stack fa-layers ml-2 fa-fade'>
                                                                        <i className="fas fa-triangle-exclamation text-danger" />
                                                                        <i className="fa-inverse fas fa-exclamation text-white" data-fa-transform="shrink-7 down-1" />
                                                                    </span>
                                                                </>}
                                                            </span>

                                                            {selectedChit.server.id === parseInt(currentUser.id) &&
                                                                <span
                                                                    role='button'
                                                                    className='item-menu-button'
                                                                    children={<i className='fa-light fa-ellipsis h3 m-0 p-0' />}
                                                                    onClick={() => handleManageItem(tab, data, isUnavailable, isModifierUnavailable(config))}
                                                                />
                                                            }
                                                        </div>

                                                        {
                                                            (!!notes || itemModifiers.length > 0) && (
                                                                <div className='d-flex flex-column mr-5 mt-n1 mb-2 item-detail'>
                                                                    {
                                                                        itemModifiers.length > 0 && (
                                                                            <table className='table-sm w-100 border-bottom-none'>
                                                                                <tbody>
                                                                                    {
                                                                                        itemModifiers.map((modifier) => (
                                                                                            <tr key={modifier.id}>
                                                                                                <td className='pt-0 pb-1'>
                                                                                                    <span className='ml-2'>
                                                                                                        <small>
                                                                                                            — { modifier.chit_action === 'add' ? 'ADD' : 'REMOVE'}&nbsp; &nbsp;
                                                                                                            { titleize(modifier.name) }&nbsp;&nbsp;
                                                                                                        </small>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        )
                                                                    }

                                                                    { notes && <small className='py-1 px-3 mt-1 font-italic w-100 border-top border-top-gray2'>"{notes}"</small> }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ))
                            }
                        </>
                    }
                </div>
            </div>

            <ButtonGroup className='tab--footer theme--outline-solid'>
                <button
                    type='button'
                    children='Send / Print'
                    disabled={isEmpty}
                    onClick={handleProcessChitFulfillment}
                />
            </ButtonGroup>
        </>
    )
}
