import React from 'react'
import { useFormContext } from 'react-hook-form'
import GroupForm from './GroupForm'
import GroupList from './GroupList'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function Groups({
  resources,
  durations,
  groups,
  pricing,
  pricingType,
  selectedCustomerTypes,
  isSpecialEvent,
  errors,
  handleManualUpdate
}) {

    const { register } = useFormContext()

    const field = register('groups', {
        validate: () => groups.length >= 1 || 'The package must have at least one group size.'
    })

    return (
        <div className="groups">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Groups Sizes</span>
                    <Helpjuice articleId='group-sizes' extraClasses='' />
                </span>
            </h3>
            <GroupForm
                id='add-group-form'
                key='add-group-form'
                durations={durations}
                groups={groups}
                pricing={pricing}
                pricingType={pricingType}
                selectedCustomerTypes={selectedCustomerTypes}
                isSpecialEvent={isSpecialEvent}
                handleManualUpdate={handleManualUpdate}
            />

            <GroupList
                resources={resources}
                groups={groups}
                pricing={pricing}
                durations={durations}
                selectedCustomerTypes={selectedCustomerTypes}
                pricingType={pricingType}
                isSpecialEvent={isSpecialEvent}
                handleManualUpdate={handleManualUpdate}
            />

            {
                errors[field.name] && (
                    <div className="invalid-feedback d-block">
                        { errors[field.name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
