import React from 'react'
import Checkbox from '../Form/Checkbox'
import PromotionFields from './PromotionFields'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function Promotions({
    form,
    formType=null,
    handleManualUpdate,
    promoCodes,
    customerTypes,
    location,
    allowDollarDiscount=true,
    errors
}) {
    return (
        <div className='package-promo-codes pt-0'>
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Promotions</span>
                    <Helpjuice articleId={formType === 'resource_type' ? 'resource-types-special-pricing' : 'promotions-promo-codes'} extraClasses='' />
                </span>
            </h3>
            <Checkbox
                name='allow_promo_codes'
                label='Allow Promotions'
                value={form.allow_promo_codes}
                handleChange={e => handleManualUpdate('allow_promo_codes', !form.allow_promo_codes)}
            />

            {
                form.allow_promo_codes && (
                    <PromotionFields
                        form={form}
                        promoCodes={promoCodes}
                        customerTypes={customerTypes}
                        location={location}
                        errors={errors}
                        allowDollarDiscount={allowDollarDiscount}
                        handleManualUpdate={handleManualUpdate}
                    />
                )
            }
        </div>
    )
}
