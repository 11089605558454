import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectHasVirtualKeyboard } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import NameInput from '@/features/AdvancedPointOfSale/components/forms/NameInput'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function NewNamedCheck({ shouldVerticallyCenter=() => {} }) {

    const hasVirtualKeyboard = useSelector(selectHasVirtualKeyboard)

    useEffect(() => {
        window.sessionStorage.setItem(PREVENT_LOADER, true)

        return () => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [])

    return (
        <div>
            <NameInput
                className='pt-4'
                field='check_name'
                autoComplete='chrome-off'
                role='presentation'
                onFocus={() => { if (hasVirtualKeyboard) { shouldVerticallyCenter(false) }}}
                onBlur={() => { if (hasVirtualKeyboard) { window.setTimeout(() => shouldVerticallyCenter(true), 200) }}}
                onEnter={() => document.getElementById('create-named-check-submit-button').click()}
            />
        </div>
    )
}
