import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentCheck } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Incrementer from '@/features/AdvancedPointOfSale/components/Incrementer'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import scrollHinting from '@/lib/ScrollHinting'
import { numberToCurrency } from '@/lib/Number'
import { debug } from '@/lib/Debug'

let initialSplitConfig

/*
 * Split Config Example
 *
 *  {
 *      Tab UUID        CustomerTypeId => Count
 *      206284720151 => { 15=>0, 16=>0, 17=>0 },
 *      678639070433 => { 15=>0, 16=>2, 17=>0 },
 *      795420872654 => { 15=>2, 16=>0, 17=>1 }
 *  }
*/

export default function UniformOrCustomerTypePricing({
    id=null,
    tabs=[],
    prices=null,
    existingSplitConfig=null,
    shouldTriggerReset=null,
    onChangesMade=() => {},
    onChange=() => {},
    onValidate=() => {},
}) {
    const check   = useSelector(selectCurrentCheck)
    const booking = useMemo(() => check?.booking, [check])

    const [loaded, setLoaded]             = useState(false)
    const [splitConfig, setSplitConfig]   = useState(null)
    const [generalTabId, setGeneralTabId] = useState(null)

    const handleReset = (id) => {
        const newSplitConfig = JSON.parse(JSON.stringify(splitConfig))
        tabs.map(({ uuid:tabId }) => { newSplitConfig[tabId][id] = initialSplitConfig[tabId][id] })
        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    const handleIncrease = (tabId, id) => {
        if (splitConfig[generalTabId][id] === 0) { return }

        const newSplitConfig             = JSON.parse(JSON.stringify(splitConfig))
        newSplitConfig[generalTabId][id] = newSplitConfig[generalTabId][id] - 1
        newSplitConfig[tabId][id]        = newSplitConfig[tabId][id] + 1
        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    const handleDecrease = (tabId, id) => {
        if (splitConfig[tabId][id] === 0) { return }

        const newSplitConfig             = JSON.parse(JSON.stringify(splitConfig))
        newSplitConfig[generalTabId][id] = newSplitConfig[generalTabId][id] + 1
        newSplitConfig[tabId][id]        = newSplitConfig[tabId][id] - 1
        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    // LOAD OR CREATE SPLIT CONFIG
    useEffect(() => {
        // use the config sourced from the check
        if (existingSplitConfig !== null && splitConfig === null) {
            // set initial using JSON so we assign by value instead of by reference
            initialSplitConfig = JSON.parse(JSON.stringify(existingSplitConfig))

            setGeneralTabId(Number.parseInt(tabs.filter((tab) => tab.is_general)[0]['uuid'], 10))
            setSplitConfig(existingSplitConfig)
            setLoaded(true)

            if (debug && console) { console.log('LOADED EXISTING SPLIT CONFIG', existingSplitConfig) }
        }

        // create a brand new split config
        if (existingSplitConfig === null && splitConfig === null) {
            let newSplitConfig = {}

            tabs.map(({ uuid:tabId, is_general }) => {
                newSplitConfig[tabId] = {}

                booking.participant_types.map(({ id, count:initialCount }) => {
                    newSplitConfig[tabId][id] = is_general ? initialCount : 0
                })
            })

            // set initial using JSON so we assign by value instead of by reference
            initialSplitConfig = JSON.parse(JSON.stringify(newSplitConfig))

            setGeneralTabId(Number.parseInt(tabs.filter((tab) => tab.is_general)[0]['uuid'], 10))
            setSplitConfig(newSplitConfig)
            setLoaded(true)

            if (debug && console) { console.log('CREATED NEW SPLIT CONFIG', newSplitConfig) }
        }
    }, [])

    // respond to a config reset trigger event
    useEffect(() => {
        if (loaded && !!splitConfig) {
            booking.participant_types.map(({ id }) => handleReset(id))
            onChangesMade(false)
        }
    }, [shouldTriggerReset])

    // announce to the parent that the split config has changed
    useEffect(() => {
        if (loaded && !!splitConfig) {
            onChange(splitConfig)
            onValidate(true)
        }
    }, [splitConfig])

    // init scrollhinting
    useEffect(() => scrollHinting.search(), [loaded])

    return loaded && !!splitConfig && (
        <div id={id} className='scrollhint tab-list container-fluid px-4 pt-4'>
            <div className='scrollhint--inner px-3'>
                {
                    tabs.map((tab, tabIndex) => {
                        const name = tab.is_general ? check?.booking?.name : tab.name

                        let totalCents = 0

                        // calculate the cost of the items assigned to the tab
                        booking.participant_types.map(({ id }) => {
                            try {
                                const price = prices?.filter((price) => price.config.customer_type_id == id)?.[0]
                                totalCents += !!price?.item ? (price.item.total_cents * splitConfig[tab.uuid][id]) : 0
                            } catch(e) { console.error(e) }
                        })

                        return (
                            <div
                                key={`tab-${tab.uuid}`}
                                id={`tab-${tab.uuid}`}
                                className={`${(tabIndex < tabs.length - 1) ? 'pb-4 mb-4 border-bottom border-bottom-gray5' : ''}`}
                            >
                                <div className='tab-header d-flex justify-content-between align-items-end mb-2'>
                                    <div className='h5 mb-0 d-flex align-items-center justify-content-center'>
                                        {
                                            tab.is_tab_of_primary_check_owner && (
                                                <i className='fas fa-star text-yellow mr-2' />
                                            )
                                        }

                                        <strong>{name}</strong>

                                        <TabStatus tab={tab} />
                                    </div>

                                    <div className='h5 mb-0'>
                                        {numberToCurrency(totalCents / 100)}
                                    </div>
                                </div>

                                <div className='tab-participants row'>
                                    {
                                        booking.participant_types.map(({ id, name }, typeIndex) => {
                                            const pricing    = prices?.filter((price) => price.config.customer_type_id == id)?.[0]
                                            const priceCents = (pricing?.item?.total_cents || 0)

                                            let cardStyle

                                            switch(true) {
                                                case tab.is_general  && splitConfig[generalTabId][id] === 0 :
                                                case !tab.is_general && splitConfig[tab.uuid][id]     === 0 :
                                                    cardStyle = { opacity: 0.5 }
                                                    break

                                                default :
                                                    cardStyle = null
                                            }

                                            return (
                                                <div key={`${tab.uuid}-${id}-${typeIndex}`} className='col-4 d-flex my-2 px-2'>
                                                    <div
                                                        className='participant-type-card rounded py-4 px-3 d-flex justify-content-between align-items-center w-100 h-100'
                                                        style={cardStyle}
                                                    >
                                                        <div className="d-flex flex-column align-items-start">
                                                            <span className='label'>{name}</span>
                                                            <small className='price'>{numberToCurrency(priceCents / 100)} ea.</small>
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-end">
                                                            {
                                                                tab.is_general ? (
                                                                    <span className='increment-field d-flex align-items-center'>
                                                                        <input
                                                                            className='text-center mx-3'
                                                                            type="number"
                                                                            value={splitConfig[tab.uuid][id]}
                                                                            readOnly
                                                                        />
                                                                        <button
                                                                            type='button'
                                                                            className="btn pressable p-0 input-group-append"
                                                                            onClick={() => handleReset(id)}
                                                                        >
                                                                            <div className="input-group-text d-flex justify-content-center align-items-center">
                                                                                <i className="fa-solid fa-arrow-rotate-left" />
                                                                            </div>
                                                                        </button>
                                                                    </span>
                                                                ) : (
                                                                    <Incrementer
                                                                        value={splitConfig[tab.uuid][id]}
                                                                        disableDecrement={splitConfig[tab.uuid][id] === 0}
                                                                        disableIncrement={splitConfig[generalTabId][id] === 0}
                                                                        preventTyping
                                                                        onDecrease={() => handleDecrease(tab.uuid, id)}
                                                                        onIncrease={() => handleIncrease(tab.uuid, id)}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
