import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { UI } from '@/lib/Constants'

export const helpjuiceSlice = createSlice({
    name: 'helpjuice',
    initialState: {
        article: null,
        open: false
    },
    reducers: {
        setArticle: (state, action) => {
            state.article = action.payload
        },
        setOpen: (state) => {
            state.open = true
            document.body.classList.add(UI.classes.HELPJUICE_OPEN)
        },
        setClose: (state) => {
            state.open    = false
            state.article = null
            document.body.classList.remove(UI.classes.HELPJUICE_OPEN)
        }
    }
})

export const {
    setArticle,
    setOpen,
    setClose
} = helpjuiceSlice.actions

export const selectArticle = state => state.helpjuice?.article
export const selectOpen    = state => state.helpjuice.open

export function fetchArticle(articleId) {
    return async (dispatch) => {
        axios.get(`/helpjuice/get_article?article=${articleId}.json`).then(({ data }) => {
            dispatch(setArticle(data))
        }).catch((e) => {
            console.log('Error: ', e)
        })
    }
}

export default helpjuiceSlice.reducer
