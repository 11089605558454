import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    selectTerminals,
    setSelectedTerminal,
    selectSelectedTerminal,
    fetchTerminals,
    selectTerminalResponse,
    selectProcessing,
} from '@/features/Adyen/adyenSlice'

import StoredCreditCard from '@/features/AdvancedPointOfSale/components/StoredCreditCard'

import {
    SELECTED_TERMINAL_ADYEN,
    SELECTED_LOCATION,
    ADYEN_TERMINAL_SALE_ID,
    ADYEN_TERMINAL_SERVICE_ID
} from '@/lib/Storage'

import { SecureHash } from '@/lib/Crypto'
import { debug } from '@/lib/Debug'

export default function AdyenTerminalTransaction({
    id=null,
    className=null,
    locationId,
    hideButton=false,
    buttonText='Process',
    buttonTextProcessing='Processing',
    buttonTextProcessed='Processed',
    buttonClassName='',
    menuClassName='',
    setTerminal=null,
    displayAuthorizedCards=false,
    displayLabel=false,
    displayAsInline=true,
    isAmountValid=true,
    transactionCallback=() => {},
}) {

    const dispatch         = useDispatch()
    const terminals        = useSelector(selectTerminals)
    const selectedTerminal = useSelector(selectSelectedTerminal)
    const terminalResponse = useSelector(selectTerminalResponse)
    const processing       = useSelector(selectProcessing)

    const [loaded, setLoaded]         = useState(false)
    const [storedCard, setStoredCard] = useState(null)

    const noTerminalsAvailable = terminals.length === 0

    const determineInitiallySelectedTerminal = () => {
        try {
            const selectedLocation = window.localStorage.getItem(SELECTED_LOCATION)
            const selectedTerminal = window.localStorage.getItem(SELECTED_TERMINAL_ADYEN)
            if (!selectedLocation || !selectedTerminal) { return null }
            if (selectedTerminal.split('|')[1] == selectedLocation) { return selectedTerminal.split('|')[0] }
            return null
        } catch(e) {
            if (console) { console.log(e) }
            return null
        }
    }

    const handleDeviceSelect = (e) => {
        handleSetTerminal(e.target.value)
    }

    const handleSetTerminal = (term) => {
        dispatch(setSelectedTerminal(term))

        if (!!setTerminal && typeof setTerminal === 'function') {
            setTerminal(term)
            setLoaded(true)
        }
    }

    const handleSubmit = () => {
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))
        transactionCallback()
    }

    useEffect(() => {
        if (!!terminals && terminals.length > 0) { return }

        dispatch(fetchTerminals(locationId)).then(() => {
            handleSetTerminal(determineInitiallySelectedTerminal())
        })
    }, [])

    useEffect(() => {
        if (!!terminalResponse) {
            if (debug && console) { console.log(terminalResponse) }
            setStoredCard(terminalResponse?.storedPaymentMethods?.[0])
        }
    }, [terminalResponse])

    return (
        <div id={id} className={className}>
            <div className="row">

                <div className={hideButton || !displayAsInline ? 'col-12' : 'col-8 pr-2'}>
                    <div className="form-group">
                        { displayLabel && <label>Terminal</label> }

                        <select
                            className={`custom-select ${menuClassName}`.trim()}
                            multiple={false}
                            value={selectedTerminal || ''}
                            disabled={noTerminalsAvailable}
                            onChange={handleDeviceSelect}
                        >
                            <option value='' disabled>
                                { noTerminalsAvailable ? (loaded ? 'NO TERMINALS AVAILABLE' : 'LOADING...') : 'Select a Terminal' }
                            </option>
                            {
                                terminals.map((device, index) => (
                                    <option key={index} value={device.id}>
                                        {device.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {
                    !hideButton && (
                        <div className={`${displayAsInline ? 'col-4' : 'col-12'} pl-2`}>
                            <div className="form-group">
                                { displayLabel && <label>&nbsp;</label> }

                                <button
                                    type='button'
                                    className={`btn ${displayAsInline ? 'w-100' : ''} text-white ${!processing && !!storedCard ? 'btn-success' : 'btn-primary'} ${buttonClassName}`.trim()}
                                    children={
                                        processing ? buttonTextProcessing
                                                   : !!storedCard ? buttonTextProcessed : buttonText
                                    }
                                    disabled={processing || !selectedTerminal || !!storedCard || !isAmountValid}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    )
                }
            </div>

            {
                !processing && displayAuthorizedCards && !!storedCard && (
                    <div className='row'>
                        <div className='col-12 px-3 mt-n2'>
                            <StoredCreditCard card={storedCard} />
                        </div>
                    </div>
                )
            }
        </div>
    )
}
