import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    fetchCheck,
    selectCurrentCheck,
    selectCurrentChit,
    selectSidebars,
    setSidebarTabs,
    openSidebarTab,
    resetCheckAndChitSidebar,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import TabNav from '@/features/AdvancedPointOfSale/components/tabs/TabNav'
import ViewCheck from '@/features/AdvancedPointOfSale/components/ordering/ViewCheck'
import ViewChit from '@/features/AdvancedPointOfSale/components/ordering/ViewChit'
import { outerHeight } from '@/lib/Elements'
import { debug } from '@/lib/Debug'

export default function CheckAndChit({ id=null, className='' }) {

    const dispatch                      = useDispatch()
    const currentCheck                  = useSelector(selectCurrentCheck)
    const currentChit                   = useSelector(selectCurrentChit)
    const { checkAndChit:sidebar }      = useSelector(selectSidebars)
    const [checkLoaded, setCheckLoaded] = useState(null)
    const isChitEmpty                   = currentChit?.tabs.reduce((count, tab) => count + tab.items.length, 0) === 0

    /**
      * Calculate the height of the tab content area with JS
      * beacuse I'm really tired of fighting with Flex/Grid CSS
      */
    const styles = (() => {
        const navEl   = document.getElementById('advanced-pos--terminal-navigation')
        const cardEl  = document.getElementById('advanced-pos--terminal-sidebar--order--check-card')
        const staffEl = document.getElementById('advanced-pos--terminal-sidebar--order--staff')
        const height  = (outerHeight(navEl) || 0) + (outerHeight(cardEl) || 0) + (outerHeight(staffEl) || 0)

        return {
            minHeight: `calc(100vh - ${height}px)`,
            maxHeight: `calc(100vh - ${height}px)`
        }
    })()

    const handleViewTab = (tab) => {
        dispatch(openSidebarTab({ sidebar: 'checkAndChit', tab: tab }))
    }

    const handleChitFulfilled = () => {
        if (debug && console) { console.log('Chit fulfilled!') }
    }

    /**
      * Since we only fetched a compact version of the check in the
      * OpenChecks component, we need to fetch the full version here
      */
    useEffect(() => {
        if (!currentCheck.tabs && !currentCheck.chits && !currentCheck.comps) {
            dispatch(fetchCheck(currentCheck.id)).then(() => setCheckLoaded(true))
        }
    }, [currentCheck])

    /**
      * Update sidebar tab nav and create a chit if necessary
      */
    useEffect(() => {
        // re-insert the order queue tab if a chit becomes present
        if (sidebar.tabs.length === 1 && sidebar.tabs[0].id === 1 && !!currentChit) {
            dispatch(resetCheckAndChitSidebar())
            return
        }

        // remove the order queue tab if the check is open and does not
        // have any chits while simultaneously attempting to create a new chit
        if (sidebar.tabs.length === 2) {
            let updatedTabs = [...sidebar.tabs]
            updatedTabs.pop()
            dispatch(setSidebarTabs({ sidebar: 'checkAndChit', tabs: updatedTabs, tab: updatedTabs[0] }))
        }

        // add a warning icon to the order queue tab if the chit
        // is unfulfilled and has items in any of its tabs
        if (sidebar.tabs.length === 2 && !currentChit?.fulfilled_at) {
            let updatedTabs = JSON.parse(JSON.stringify(sidebar.tabs))

            if (!!currentChit) {
                updatedTabs[1].append = isChitEmpty ? '' : 'warning'
            }

            dispatch(setSidebarTabs({ sidebar: 'checkAndChit', tabs: updatedTabs }))
        }

        return () => {
            dispatch(resetCheckAndChitSidebar())
        }
    }, [checkLoaded, currentCheck, currentChit, isChitEmpty])

    return (
        <div id={id} className={`tabs--container ${className}`.trim()} style={styles}>
            <TabNav
                tabs={sidebar.tabs}
                currentTab={sidebar.tab.title}
                handleChange={handleViewTab}
            />

            {
                sidebar.tab.id === 1 && (
                    <ViewCheck id='advanced-pos--terminal-sidebar--order--view-check' />
                )
            }

            {
                sidebar.tab.id === 2 && (
                    <ViewChit
                        id='advanced-pos--terminal-sidebar--order--view-chit'
                        onFulfilled={handleChitFulfilled}
                    />
                )
            }
        </div>
    )
}
