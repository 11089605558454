import React, { forwardRef, useEffect } from 'react'
import scrollHinting from '@/lib/ScrollHinting'

export default forwardRef(function Modal({
    isOpen=false,
    title=null,
    body=null,
    size='',
    allowScrollHintContentRoot=true,
    verticallyCenter=true,
    footerButtons=null,
    className='',
    style=null,
    prepend=null,
    append=null,
    children,
    onClose=() => {},
}, ref) {

    const handleKeyDown = e => {
        e.stopPropagation()
        if (e.key === 'Escape') { onClose() }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [isOpen])

    useEffect(() => {
        if (allowScrollHintContentRoot) {
            scrollHinting.search()
        }
    }, [isOpen, body, children])

    return (
        <div
            ref={ref}
            className={`modal ${isOpen && (!!body || !!children || !!prepend || !!append) ? 'd-block' : 'd-none'} ${className}`.trim()}
        >
            <div className={`modal-dialog ${verticallyCenter ? 'modal-dialog-centered' : 'modal-dialog-top'} ${size}`.trim()} style={style}>
                <div className="modal-content mx-auto rounded-xl">
                    {
                        !!title && (
                            <div className={`modal-header ${size === 'modal-sm' ? 'py-2' : 'py-3'}`}>
                                <h5 className="modal-title px-0 font-weight-normal">
                                    {title}
                                </h5>
                            </div>
                        )
                    }

                    {
                        !!prepend && (
                            <div className="modal-prepend-body d-block">
                                {prepend}
                            </div>
                        )
                    }

                    {
                        (!!body || !!children) && <>
                            {
                                allowScrollHintContentRoot ? (
                                    <div className='scrollhint'>
                                        <div className="modal-body d-block scrollhint--inner">
                                            {body || children}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="modal-body d-block">
                                        {body || children}
                                    </div>
                                )
                            }
                        </>
                    }

                    {
                        !!append && (
                            <div className="modal-append-body d-block">
                                {append}
                            </div>
                        )
                    }

                    {
                        !!footerButtons && (
                            <div className="modal-footer border-top border-top-gray0 border-thick">
                                {footerButtons}
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="modal-outside-close">
                <button onClick={onClose}>
                    <span className="h6 font-weight-bold mr-3">CANCEL</span>
                    <i className="far fa-times h3" />
                </button>
            </div>
        </div>
    )
})
