import React from 'react'
import { Provider } from 'react-redux'
import axios from 'axios'
import store from '@/stores/advancedPointOfSaleStore'
import { ErrorBoundary } from 'react-error-boundary'
import Loading from '@/components/Loading'
import KurtLoader from '@/features/AdvancedPointOfSale/_KurtLoader'
import LockScreen from '@/features/AdvancedPointOfSale/LockScreen'
import ChannelSubscription from '@/features/AdvancedPointOfSale/components/ChannelSubscription'
import NotificationList from '@/features/Notifications/NotificationList'
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import ManagerOverrideDialog from '@/features/AdvancedPointOfSale/components/modals/ManagerOverrideDialog'
import PopModal from '@/features/AdvancedPointOfSale/components/modals/PopModal'
import PartialAuthAlert from '@/features/Terminal/PartialAuthAlert'
import { ERROR } from '@/features/AdvancedPointOfSale/components/Messages'

export default function Container(props) {

    // preload data into the redux store/state
    const preloadedState = {
        companyId: props.companyId,
        locationId: props.locationId,
    }

    // Necessary to force Axios requests to send "as XHR"
    axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

    return (
        <ErrorBoundary fallback={ERROR}>
            <Provider store={store(preloadedState)}>
                <Loading delay='300' allowForceReload />
                <NotificationList />
                <PartialAuthAlert />
                <ConfirmationDialog />
                <ChannelSubscription locationId={props.locationId} />

                <LockScreen
                    companyId={props.companyId}
                    locationId={props.locationId}
                    timeoutSeconds={props.sessionTimeoutSeconds}
                />

                <ManagerOverrideDialog locationId={props.locationId} />
                <PopModal />

                <KurtLoader {...props} />
            </Provider>
        </ErrorBoundary>
    )
}
