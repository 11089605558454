import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setErrors, setStep, setDuration, setDate, selectPreloaded, selectDuration, selectDate, trackAbandonedBooking } from '../../bookingSlice'
import { selectDurations } from '../../../Packages/packageSlice'
import { selectDates } from '../../../Availability/availabilitySlice'
import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import SelectDate from './SelectDate'
import SelectDuration from './SelectDuration'
import SelectContact from './SelectContact'

export default function Step2({ modalStyles }) {

    const dispatch           = useDispatch()
    const preloaded          = useSelector(selectPreloaded)
    const duration           = useSelector(selectDuration)
    const date               = useSelector(selectDate)
    const availableDurations = useSelector(selectDurations)
    const availableDates     = useSelector(selectDates)

    const handleNext = () => {
        let errors = []

        if (!duration) { errors.push('A duration is required.') }
        if (!date) { errors.push('Please select a date.') }

        if (errors.length === 0) {
            dispatch(trackAbandonedBooking())
            dispatch(setErrors({ type: 'clear' }))
            dispatch(setStep('3'))
        } else {
            dispatch(setErrors({ type: 'add', errors }))
        }
    }

    return <>
        <div className="xbm-step-content">
            <div className="xbm-step-content-main">
                {/*<div className="xbm-step-counter">Step 2</div>*/}
                <div className="container">
                    <div className="row align-items-stretch">
                        <div className="col-sm col-md-6 mb-4 mb-0-sm">
                            <SelectDuration />
                        </div>
                        <div className="col-sm col-md-6 mb-4 mb-0-sm">
                            <SelectDate />
                        </div>
                    </div>
                </div>

                <SelectContact />
            </div>

            <BookingModalLedger />
        </div>

        <BookingModalFooter
            modalStyles={modalStyles}
            disableNext={
              Object.keys(availableDurations).length === 0
              || Object.keys(availableDates).length === 0
              || !date
              || !duration
            }
            onBack={() => {
                if (!preloaded) {
                    dispatch(setDuration(null))
                    dispatch(setDate(null))
                }
                dispatch(setStep('1'))
            }}
            onNext={handleNext}
        />
    </>
}
