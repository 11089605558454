import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function Loading({
    delay=500, /* milliseconds */
    allowForceReload=false,
    debug=false
}) {

    const loaderRef       = useRef()
    const [show, setShow] = useState(false)

    const __debugger = (options) => {
        if (debug) { console.log(options) }
    }

    const startTimer = () => {
        window._axiosLoading =
            window.setTimeout(() => {
                __debugger('showing loader')
                setShow(true)
            }, Number(delay))
    }

    const clearTimer = () => {
        window.clearTimeout(window._axiosLoading)
        __debugger('timer cleared')
    }

    const clearRequests = () => {
        window._axiosRequests = 0
        __debugger('request counter cleared')
    }

    const incrementRequests = () => {
        window._axiosRequests++
        __debugger(['incremented', window._axiosRequests])
    }

    const decrementRequests = () => {
        window._axiosRequests--
        __debugger(['decremented', window._axiosRequests])
    }

    const hideLoaderIfLastRequestComplete = () => {
        if (window._axiosRequests === 0) {
            __debugger('hiding loader')
            setShow(false)
        }
    }

    const handleForceReload = () => {
        if (allowForceReload) {
            window.location.reload()
        }
    }

    /**
     * We hook into all Axios requests/responses via its interceptors. Each
     * time a request is made, we start a timer and then increment a counter
     * (and then decrement it later on once a response is received—even an
     * error response). When the counter reaches zero, we hide the loader.
     *
     * The loader animation itself only displays after a delayed amount of
     * time has passed so that we don't annoy the user by displaying it too soon.
     */
    useEffect(() => {
        clearRequests()

        /**
         * Hook into all Axios requests
         */
        axios.interceptors.request.use((config) => {
            clearTimer()
            incrementRequests()
            startTimer()
            __debugger(['REQUEST: start!', config.url])

            return config
        }, (error) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            __debugger(['REQUEST: error occurred!', error])
            return Promise.reject(error)
        })

        /**
         * Hook into all Axios responses
         */
        axios.interceptors.response.use((response) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            return response
        }, (error) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            __debugger(['RESPONSE: error occurred!', error])
            return Promise.reject(error)
        })

        /**
         * Handle unmounting this component
         * should it ever happen (it shouldn't)
         */
        return () => {
            setShow(false)
            clearRequests()
            clearTimer()
        }
    }, [])

    useEffect(() => {
        if (show && !!loaderRef.current) {
            loaderRef.current.addEventListener('click', handleForceReload)
        }

        return () => {
            if (!!loaderRef.current) {
                loaderRef.current.removeEventListener('click', handleForceReload)
            }
        }
    }, [show])

    return show && !(window.sessionStorage.getItem(PREVENT_LOADER) == 'true') ? (
        <div ref={loaderRef} id="global-loading">
            <div id="global-loading--content">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                    <div className="rect6" />
                </div>
                Loading
            </div>
        </div>
    ) : null
}
