import React, { useState } from 'react'
import ResourceTypeTabs from "./ResourceTypeTabs"
import ResourceTypeSetupTab from "./ResourceTypeSetupTab"
import ResourceTypeCustomOpenHoursTab from "./ResourceTypeCustomOpenHoursTab"
import ResourceTypeCustomPeakHoursTab from './ResourceTypeCustomPeakHoursTab'
import ResourceTypeSpecialPricingTab from "./ResourceTypeSpecialPricingTab";

export default function ResourceForm({
  form,
  errors,
  time_zone,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
  new: newResourceType,
  location,
  customerTypes
}) {
  const [tab, setTab] = useState('setup')

  const renderTab = () => {
    switch (tab) {
      case 'setup':
        return (
          <ResourceTypeSetupTab
            form={form}
            location={location}
            errors={errors}
            handleUpdate={handleUpdate}
            handleCheckboxUpdate={handleCheckboxUpdate}
            handleManualUpdate={handleManualUpdate}
            handleFormUpdate={handleFormUpdate}
          />
        )
      case 'custom_open_hours':
        return (
          <ResourceTypeCustomOpenHoursTab
            form={form}
            errors={errors}
            handleUpdate={handleUpdate}
            handleCheckboxUpdate={handleCheckboxUpdate}
            handleManualUpdate={handleManualUpdate}
            handleFormUpdate={handleFormUpdate}
            time_zone={time_zone}
          />
        )
      case 'custom_peak_hours':
        return (
          <ResourceTypeCustomPeakHoursTab
            form={form}
            time_zone={time_zone}
            handleUpdate={handleUpdate}
            handleFormUpdate={handleFormUpdate}
          />
        )
      case 'special_pricing':
        return (
            <ResourceTypeSpecialPricingTab
                handleManualUpdate={handleManualUpdate}
                form={form}
                errors={errors}
                customerTypes={customerTypes}
                location={location}
                isNew={newResourceType} />
        )
    }
  }

  return (
    <div>
      <ResourceTypeTabs
        handleClick={(tab) => setTab(tab)}
        activeTab={tab}
        form={form}
      />

      { renderTab() }
    </div>
  )
}
