import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'
import { selectConfirmation, selectConfirmationOptions } from '@/features/Notifications/notificationSlice'

export default function ConfirmationDialog({
  headerText='Please Confirm...',
  cancelText='No',
  confirmText='Yes',
  confirmOnly=false
}) {
    const { show:showDialog, text:question } = useSelector(selectConfirmation)
    const confirmationOptions                = useSelector(selectConfirmationOptions)
    const { onCancellation, onConfirmation } = useConfirm()
    const [ready, setReady]                  = useState(false)

    const handleKeyDown = (e) => {
        switch(e.key) {
          case 'Escape' :
            onCancellation()
            break

          case 'Enter' :
            onConfirmation()
            break
        }
    }

    /*
     * Force text selection to clear out when the modal
     * opens up because weird stuff is being weird. Sometimes.
     *
     * https://stackoverflow.com/questions/6562727/is-there-a-function-to-deselect-all-text-using-javascript
     */
    const clearSelection = () => {
      if (window.getSelection) { window.getSelection().removeAllRanges() }
      else if (document.selection) { document.selection.empty() }
    }

    useEffect(() => {
        if (!ready) { return }

        if (showDialog) {
            clearSelection()
        }
    }, [showDialog])

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)
        if (!ready) { setReady(true) }

        // cleanup/reset on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return !showDialog ? '' : (
        <div className={`modal modal-backdrop modal--${confirmationOptions?.size || 'sm'} confirmation--modal`} style={confirmationOptions?.style || null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-container modal-content">
                    {
                        !confirmationOptions?.hideHeader && (
                            <div className="modal-header">
                                {
                                    confirmationOptions?.dangerous
                                        ? (
                                            <div
                                                className='modal-title text-danger font-weight-bold text-center'
                                                dangerouslySetInnerHTML={{ __html: confirmationOptions?.header_text || headerText }}
                                            />
                                        )
                                        : <div className='modal-title' dangerouslySetInnerHTML={{ __html: confirmationOptions?.header_text || headerText }} />
                                }

                                {
                                    !(confirmationOptions?.confirm_only || confirmOnly) && (
                                        <button type='button' className="modal-close" onClick={onCancellation}>
                                            <span>{ confirmationOptions?.close_text || 'Close'}</span>
                                            <i className="far fa-times" />
                                        </button>
                                    )
                                }
                            </div>
                        )
                    }

                    {
                        (!!question || question?.length > 0) && (
                            <div className="modal-body">
                                <div className="modal-body-main">
                                    <div className="modal-body-main-section text-center p-5">
                                        {
                                            confirmationOptions?.rawHTML
                                                ? <div className="m-0 p-0" dangerouslySetInnerHTML={{ __html: question }} />
                                                : <h4 className="m-0 p-0" dangerouslySetInnerHTML={{ __html: question }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        !(confirmationOptions?.hideFooterButtons || false) && (
                            <div className="modal-footer">
                                {
                                    !(confirmationOptions?.confirm_only || confirmOnly) && (
                                        <button
                                            type='button'
                                            className={`btn ${confirmationOptions?.dangerous ? 'btn-success text-white' : 'btn-outline-primary'} px-5 mr-auto`}
                                            onClick={onCancellation}
                                        >
                                            { confirmationOptions?.cancel_text || cancelText }
                                        </button>
                                    )
                                }

                                <button
                                    type='button'
                                    className={`btn ${confirmationOptions?.dangerous ? 'btn-danger text-white' : 'btn-primary'} px-5`}
                                    onClick={onConfirmation}
                                >
                                    { confirmationOptions?.confirm_text || confirmText }
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>

            {
                !(confirmationOptions?.confirm_only || confirmOnly) && (
                    <div className="modal-outside-close">
                        <button type='button' onClick={onCancellation}>
                            <span className="h6 font-weight-bold mr-3">CANCEL</span>
                            <i className="far fa-times h3" />
                        </button>
                    </div>
                )
            }
        </div>
    )
}
