import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setClose, selectOpen, selectArticle } from '@/features/Helpjuice/helpjuiceSlice'

export default function HelpjuiceModal() {

    const dispatch = useDispatch()
    const open     = useSelector(selectOpen)
    const article  = useSelector(selectArticle)

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(setClose())
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [open])

    return (!open || !article) ? null : (
        <div className="modal modal-backdrop modal--xl confirmation--modal helpjuice--modal">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="modal-title">
                        {article?.article?.name}
                    </div>

                    <button type='button' className="modal-close" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>

                <div className="modal-body pb-5">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section text-left p-4">
                            <h5 className='border-bottom pb-2 mb-3'>{article?.article?.description}</h5>
                            <div dangerouslySetInnerHTML={{ __html: article?.article?.answer?.body }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
