import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCheckoutTab, selectTipAmount, selectAmountTowardsBalance } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectCreditToken, setCreditToken } from '@/features/Adyen/adyenSlice'
import PaymentForm from '../PaymentForm'
import StoredCreditCard from '@/features/AdvancedPointOfSale/components/StoredCreditCard'
import { formatAdyenToken } from '@/lib/PaymentProfile'
import { numberToCurrency } from '@/lib/Number'

export default function CardOnFile({
    self,
    totalDue,
    tabs,
    currentTab,
    isProcessing,
    onTabChange,
    onLoad,
    onSubmit
}) {

    const dispatch             = useDispatch()
    const tab                  = useSelector(selectCheckoutTab)
    const tipAmount            = useSelector(selectTipAmount)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const creditToken          = useSelector(selectCreditToken)
    const paymentProfile       = tab?.payment_profile
    const storedCard           = useMemo(() => paymentProfile?.storedPaymentMethods?.[0], [paymentProfile])

    const amountToProcess = useMemo(() => (
        parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0)
    ), [tipAmount, amountTowardsBalance])

    const processButton = useMemo(() => (
        <button
            children={isProcessing ? 'Please Wait...' : `Process ${numberToCurrency(amountToProcess)}`}
            className='btn btn-primary process-button'
            disabled={isProcessing || !storedCard || !creditToken}
            onClick={() => {
                onSubmit({
                    totalReceivedAmount: amountToProcess,
                    tipAmount: tipAmount || 0,
                })
            }}
        />
    ), [isProcessing, amountToProcess, storedCard, creditToken, tipAmount])

    useEffect(() => {
        if (!!paymentProfile && !!storedCard) {
            dispatch(setCreditToken((formatAdyenToken(paymentProfile, storedCard))))
        }
    }, [paymentProfile])

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            creditTabs={tabs}
            creditCurrentTab={currentTab}
            onCreditTabChange={onTabChange}
        >
            <div className='mt-4 mb-n2 pt-4 border-top border-thick border-color-gray4'>
                {
                    !!storedCard ? (
                        <div className="list-group payment-profiles mb-3 py-2">
                            <div className='list-group-item mb-2'>
                                <StoredCreditCard card={storedCard} />
                            </div>
                        </div>
                    ) : (
                        <p className="py-4 text-center text-secondary">No credit cards on file.</p>
                    )
                }
            </div>
        </PaymentForm>
    )
}
