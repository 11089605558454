import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import moment from 'moment'
import 'moment-timezone'

import {
    clearAdyenState,
    createSession,
    selectSessionId,
    selectSessionData,
    selectAdyenErrors,
    setAdyenErrors,
    selectAdyenPayment,
    setAdyenPayment,
    selectAdyenClient
} from '@/features/Adyen/adyenSlice'

import AdyenCheckout from '@adyen/adyen-web'
import Input from '@/components/Form/Input'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { validationPatterns } from '@/lib/Errors'

export default function AdyenCreditCard({
    className=null,
    locationId,
    locationTimeZone,
    booking=null,
    environment='test',
    zipcode='',
    captureZipCode=true,
    validateZipCode=true,
    adyenCheckout=null,
    setAdyenCheckout=() => {},
    setZipCode=() => {},
    onSubmit=() => {},
}) {

    const containerRef          = useRef()
    const { errors:formErrors } = useFormState()

    const dispatch     = useDispatch()
    const sessionId    = useSelector(selectSessionId)
    const sessionData  = useSelector(selectSessionData)
    const adyenErrors  = useSelector(selectAdyenErrors)
    const adyenPayment = useSelector(selectAdyenPayment)
    const adyenClient  = useSelector(selectAdyenClient)

    const [renderZipTrigger, triggerRenderZip] = useState(false)

    useEffect(() => {
        // **********************************************************************
        // NOTE always remember to dispatch(clearAdyenState()) once the payment
        // has been submitted, either explicity or implicitly once this component
        // has been unmounted, or else the next instance could re-use the same
        // session/payment info incorrectly!!!
        // **********************************************************************
        return () => {
            setAdyenCheckout(null)
            dispatch(clearAdyenState())
        }
    }, [])

    useEffect(() => {
        if (sessionId) { return null }

        dispatch(createSession(booking?.reservation_number, locationId))
    }, [sessionId, booking?.id])

    // THIS TRIGGERS THE PAYMENT TO SUBMIT
    useEffect(() => {
        if (!adyenPayment) { return }

        // whenever we have an adyen payment response in state and it
        // has propogated properly, we can submit the onSubmit callback.
        onSubmit()
    }, [adyenPayment])

    if (!sessionId || !sessionData || !adyenClient) {
        return null
    }

    if (!adyenCheckout) {
        const config = {
            environment: environment,
            clientKey: adyenClient,
            analytics: {
                enabled: true,
            },
            session: {
                id: sessionId,
                sessionData: sessionData
            },
            paymentMethodsConfiguration: {
                card: {
                    brands: ['mc','visa','amex', 'discover', 'diners'],
                    minimumExpiryDate: moment().tz(locationTimeZone).format('MM/YY'),
                    autoFocus: false,
                },
            },
            onSubmit: (state, component) => {
                if (console) { console.log('Payment submit: ', [state, component]) }

                dispatch(setAdyenPayment(state))
            },
            onPaymentCompleted: (result, component) => {
                // NOTE this is not used, we use the above onSubmit instead!
                // result.resultCode = 'Refused'
                if (console) { console.log('Payment result: ', [result, component]) }

                switch (result.resultCode) {
                    case 'Refused':
                        dispatch(setAdyenErrors([
                            'The credit card you attempted to use was declined.',
                            'Please try with another card or contact your card provider.'
                        ]))
                        break

                    case 'Authorised':
                        // resultCode : "Authorised"
                        // sessionData : "Ab02b4c0!BQABAgCb..."
                        // sessionResult : "X3XtfGC7!H4sIAA..."

                    default:
                        if (console) { console.log('Unhandled payment result code: ', result.resultCode) }
                }
            },
            onError: (error, component) => {
                if (console) { console.log('Payment ERROR: ', [error, component]) }
            },
        }

        // INITIALIZE CREDIT CARD FORM USING ADYEN-WEB COMPONENTS
        //
        // https://docs.adyen.com/online-payments/build-your-integration/?platform=Web&integration=Components&version=5.53.2
        AdyenCheckout(config)
            .then((checkout) => {
                const cardComponent = checkout.create('card').mount(containerRef.current)

                // what happens here is we have to set the adyen checkout object in the parent Step6 component,
                // because that's where we call submit in the BookingModalFooter component. when that button gets clicked
                // we have to call .submit() on this cardComponent object that we are passing up, therefore when the AdyenCheckout
                // Promise is resolved, we have to pass that object up instead of using it directly here. Nothing else happens here,
                // it only initializes the payment session and renders the form, the rest is handled in the Step6/BookingModalFooter components.
                setAdyenCheckout(cardComponent)
            })
            .then(() => {
                window.setTimeout(() => triggerRenderZip(true), 1150)
            })
    }

    return (
        <div className={className}>
            <div ref={containerRef} className='mb-2' />

            {
                captureZipCode && validateZipCode && renderZipTrigger && (
                    <Input
                        cols='px-0 pt-2'
                        label='zipcode'
                        type='text'
                        value={zipcode}
                        req={validateZipCode}
                        validation={{ 'zipcode': {
                            required: 'A zip code is required.',
                            pattern: validationPatterns.zip,
                        }}}
                        minLength='5'
                        maxLength='10'
                        errors={errorsFor(formErrors)}
                        handleChange={(e) => setZipCode(e)}
                    />
                )
            }

            {
                adyenErrors.length > 0 && (
                    <div className="alert alert-danger">
                        <ul className='mb-0'>
                            {
                                adyenErrors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </div>
    )
}
