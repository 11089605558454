import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { checkMemberIds, selectMemberCount, selectMembers, setStep } from '../../bookingSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import LedgerLocation from '../Ledger/LedgerLocation'
import LedgerPartySize from '../Ledger/LedgerPartySize'
import LedgerDuration from '../Ledger/LedgerDuration'
import LedgerDateTime from '../Ledger/LedgerDateTime'
import LedgerCustomerTypes from '../Ledger/LedgerCustomerTypes'
import MobileBookingModalLedger from '../Ledger/MobileBookingModalLedger'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import MemberInput from './MemberInput'
import BookingModalFooter from '../BookingModalFooter'

export default function Members({ modalStyles }) {

    const formMethods = useForm()
    const dispatch    = useDispatch()
    const pkg         = useSelector(selectPackage)
    const memberCount = useSelector(selectMemberCount)
    const members     = useSelector(selectMembers)

    // this will be true if the number of filled-out
    // member ids is less than the member count
    const isDisabled = useMemo(() => (
        members.filter(m => !!m?.memberId)?.length < memberCount
    ), [members, memberCount])

    const handleBack = () => {
        dispatch(setStep(pkg.allow_members_only ? '3' : '4'))
    }

    const handleNext = () => {
        dispatch(checkMemberIds())
    }

    return (
        <FormProvider {...formMethods}>
            <div className="xbm-step-content">
                <div className="xbm-step-content-main">
                    <div className="xbm-step-question">
                        <h2 className="mb-3">Member Information</h2>
                    </div>

                    <div className="xbm-checkout-ledger d-block d-md-none border-bottom pb-0 mb-n2">
                        <LedgerLocation />
                        <LedgerPartySize />
                        <LedgerCustomerTypes />
                        <LedgerDuration />
                        <LedgerDateTime />
                    </div>

                    <MobileBookingModalLedger />

                    <div className="container form-container checkout-form">
                        {
                            [...Array(memberCount).keys()].map((index) => (
                                <MemberInput
                                    key={index}
                                    member={members[index]}
                                    index={index}
                                />
                            ))
                        }
                    </div>
                </div>

                <BookingModalLedger />
            </div>

            <BookingModalFooter
                modalStyles={modalStyles}
                disableNext={isDisabled}
                onBack={handleBack}
                onNext={handleNext}
            />
        </FormProvider>
    )
}
