import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useManagerOverride } from '@/lib/useManagerOverrideHook'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { selectModal, validateManagerCode } from '@/features/ManagerCodes/managerCodeSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import NumberKeyPad from '@/components/NumberKeyPad'

/*
 * USAGE:
 *
 * import { useManagerOverride } from '@/lib/useManagerOverrideHook'
 *
 * const { authorize } = useManagerOverride()
 *
 * const handleClick = async () => {
 *     if (await authorize()) {
 *         do something!
 *     }
 * }
 */

export default function ManagerOverrideDialog({ locationId=null, limit=4 }) {

    const { onAuthorization, onCancellation } = useManagerOverride()

    const codeRef             = useRef()
    const dispatch            = useDispatch()
    const [code, setCode]     = useState(null)
    const { show:showDialog } = useSelector(selectModal)

    const handleDelete = () => {
        if (codeRef.current !== undefined && codeRef.current !== null && codeRef.current.length > 0) {
            codeRef.current = codeRef.current.toString().substring(0, codeRef.current.toString().length-1)
            setCode(codeRef.current)
        }
    }

    const handleChange = (value) => {
        // prevent too many characters from being entered
        if ((codeRef.current?.length || 0) === limit) { return }

        // respond to only numbers
        if (/\d/.test(value)) {
            codeRef.current = `${!!codeRef.current ? codeRef.current : ''}${value}`.trim()
            setCode(codeRef.current)
        }

        // once we've hit the limit, try authorizing the code
        if (codeRef.current.length === limit) {
            window.setTimeout(() => {
                setCode(null)
            }, 400)

            window.setTimeout(() => {
                dispatch(validateManagerCode(locationId, codeRef.current, true))
                    .then(() => { onAuthorization() })
                    .catch(() => { dispatch(addAlert({ type: 'error', text: 'Unauthorized!' })) })
                    .finally(() => { codeRef.current = null })
            }, 600)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            e.stopPropagation()
            onCancellation()
        }
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        // cleanup/reset on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return !showDialog ? null : (
        <Modal
            title='Authorization Required'
            footerButtons={
                <button
                    type='button'
                    children='Cancel'
                    className='btn btn-link w-50'
                    onClick={onCancellation}
                />
            }
            size='modal-md'
            isOpen={true}
            onClose={() => onCancellation()}
        >
            <div className='passcode-keypad mx-auto mt-4 mb-2'>
                <div className='row no-gutters'>
                    <div className='col-12 px-1'>
                        <div className='passcode rounded bg-black text-white mb-2 w-100'>
                            { code?.replace(/./g, '*') || '' }
                        </div>
                    </div>
                </div>

                <NumberKeyPad
                    buttons={[1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, 'backspace']}
                    isKeyDisabled={{ 'backspace': (code === null || code.length === 0) }}
                    onChange={handleChange}
                    onDelete={handleDelete}
                />
            </div>
        </Modal>
    )
}
