import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useManagerOverride } from '@/lib/useManagerOverrideHook'
import { useConfirm } from '@/lib/useConfirmHook'

import {
    openModal,
    closeModal,
    configureModal,
    selectCurrentCheck,
    selectModals,
    removeItemFromCheckTab,
    fetchMenuItemContent,
    setCurrentComponent,
    setItemEditMode,
    setItemConfig,
    setTemporary,
    setLoadedItemId,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { selectCurrentUser } from '@/features/Session/sessionSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import MoveCheckItemModal from '@/features/AdvancedPointOfSale/components/modals/MoveCheckItemModal'
import DiscountModal from '@/features/AdvancedPointOfSale/components/modals/DiscountModal'
import CompModal from '@/features/AdvancedPointOfSale/components/modals/CompModal'
import ItemCard from '@/features/AdvancedPointOfSale/components/cards/ItemCard'
import { isOpen, inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function ManageCheckItemModal() {

    const dispatch                          = useDispatch()
    const { confirm }                       = useConfirm()
    const { authorize }                     = useManagerOverride()
    const check                             = useSelector(selectCurrentCheck)
    const user                              = useSelector(selectCurrentUser)
    const { manageCheckItem:modal }         = useSelector(selectModals)
    const [currentStep, setCurrentStep]     = useState('manageCheckItem')
    const [title, setTitle]                 = useState('')
    const [content, setContent]             = useState(null)
    const [footerButtons, setFooterButtons] = useState(null)

    const otherTabsPresent = (
        (check?.tabs || [])
            .filter((tab) => tab?.id !== modal.tab?.id)
            .filter((tab) => !tab.is_booking)
            .filter((tab) => !tab.payment_locked_by_id)
            .filter((tab) => (isOpen(check) && !tab.is_closed) || (inAlteration(check) && tab.is_closed))
            .length > 0
    )

    const comp = check?.comps?.find(comp => comp.item_uuid === modal.item?.item.uuid)

    const handleResetToManage = () => {
        setCurrentStep('manageCheckItem')
    }

    const handleClose = () => {
        // reset modal state
        setCurrentStep('manageCheckItem')

        dispatch(configureModal({
            modal: 'manageCheckItem',
            config: { tab: null, item: null }
        }))

        dispatch(closeModal('manageCheckItem'))
    }

    const handleRemove = async () => {
        const tempModal = modal

        if (/^(super|company_admin|manager)$/i.test(user.role)) {
            if (await confirm('Are you sure you want to remove this item?')) {
                dispatch(closeModal('manageCheckItem'))
                dispatch(removeItemFromCheckTab(tempModal.tab.id, tempModal.item.item.uuid))
            }
        } else {
            dispatch(closeModal('manageCheckItem'))

            if (await authorize()) {
                dispatch(removeItemFromCheckTab(tempModal.tab.id, tempModal.item.item.uuid))
            } else {
                configureFor('manageCheckItem', modal.tab, modal.item)
                dispatch(openModal('manageCheckItem'))
            }
        }
    }

    const handleEdit = async () => {
        const tempModal = modal

        const editActions = () => {
            dispatch(setCurrentComponent('MenuItem'))
            dispatch(setItemEditMode('check-item'))
            dispatch(setTemporary({
                tabId: tempModal.tab.id,
                itemUuid: tempModal.item.item.uuid,
                itemConfig: tempModal.item.config,
                modifierGroups: tempModal.item.item.modifier_groups,
                modifierGroupsSortOrder: tempModal.item.item.modifier_groups_sort_order,
                defaultModifiers: tempModal.item.item.default_modifiers,
            }))
            dispatch(setItemConfig({ modifiers: tempModal.item.config, notes: tempModal.item.notes }))
        }

        if (/^(super|company_admin|manager)$/i.test(user.role)) {
            if (await confirm('Are you sure you want to edit this item?')) {
                dispatch(fetchMenuItemContent(tempModal.item.item.id)).then(() => {
                    dispatch(closeModal('manageCheckItem'))
                    editActions()
                })
            } else {
                configureFor('manageCheckItem', modal.tab, modal.item)
                dispatch(openModal('manageCheckItem'))
            }
        } else {
            dispatch(closeModal('manageCheckItem'))

            if (await authorize()) {
                dispatch(fetchMenuItemContent(tempModal.item.item.id)).then(() => {
                    editActions()
                })
            } else {
                configureFor('manageCheckItem', modal.tab, modal.item)
                dispatch(openModal('manageCheckItem'))
            }
        }
    }

    const handleComp = () => {
        setCurrentStep('comp')

        if (!comp) {
            setTitle('Comp Reason')
            setContent(<CompModal tab={modal.tab} handleClose={handleResetToManage} />)
            setFooterButtons(null)
        }
    }

    const handleDiscount = async () => {
        if (!/^(super|company_admin|manager)$/i.test(user.role)) {
            const tempModal = modal

            dispatch(closeModal('manageCheckItem'))

            if (await authorize()) {
                dispatch(openModal('manageCheckItem'))
                setCurrentStep('discount')
                dispatch(configureModal({ modal: 'manageCheckItem', config: { tab: tempModal.tab, item: tempModal.item, itemIndex: tempModal.itemIndex }}))
            } else {
                configureFor('manageCheckItem', modal.tab, modal.item)
                dispatch(openModal('manageCheckItem'))
            }
        } else {
            setCurrentStep('discount')
        }
    }

    const handleReOrder = async () => {
        if (await confirm('Are you sure you want to re-order this item?')) {
            dispatch(setLoadedItemId(modal.item.item.id))
            dispatch(setItemConfig({ modifiers: modal.item.config, notes: modal.item.notes }))
            dispatch(closeModal('manageCheckItem'))
            dispatch(setCurrentComponent('MenuItem'))
        }
    }

    const configureFor = (step, tab=null, item=null) => {
        if (!!tab && !!item) {
            dispatch(configureModal({ modal: 'manageCheckItem', config: { tab: tab, item: item }}))
        }

        if (step === 'manageCheckItem') {
            setTitle('Manage Item')
            setContent(manageCheckItem)
            setFooterButtons(
                <button
                    children='Cancel'
                    className='btn btn-link text-blue'
                    onClick={handleClose}
                />
            )

        } else if (step === 'moveCheckItem') {
            setTitle('Move Item To')
            setContent(<MoveCheckItemModal onClose={handleResetToManage} onSuccess={handleClose} />)
            setFooterButtons(null)

        } else if (step === 'discount') {
            setTitle('Discount Item')
            setContent(<DiscountModal onClose={handleResetToManage} onSuccess={handleClose} />)
            setFooterButtons(null)
        }
    }

    useEffect(() => {
        configureFor(currentStep)
    }, [currentStep, modal.item])

    const manageCheckItem = <>
        <div className='d-flex align-items-center justify-content-between'>
            {
                !inAlteration(check) && modal?.item?.item?.type === 'item' && <>
                    <button
                        children={<span><i className="fa-solid fa-rotate-right mr-2" />Re-Order</span>}
                        className='btn btn-primary w-100 mr-2'
                        onClick={handleReOrder}
                    />

                    <button
                        children={<span><i className="fa-solid fa-pen-to-square mr-2" />Edit</span>}
                        className='btn btn-primary w-100 ml-2'
                        disabled={
                            check?.individual_tab_payment_disabled_by_id
                                || /^(pending|approved)$/i.test(comp?.status || '')
                                || modal.tab?.payment_locked_by_id
                                || modal.item.payment_made
                                || modal.item?.discount
                        }
                        onClick={handleEdit}
                    />
                </>
            }

            {
                otherTabsPresent && (
                    <button
                        children={<span><i className="fa-solid fa-arrows-up-down-left-right mr-2" />Move</span>}
                        className={`btn btn-primary w-100 ${!inAlteration(check) && modal?.item?.item?.type === 'item' ? 'ml-3' : ''}`}
                        disabled={
                            (inAlteration(check) && !/^(manager_adjustment)$/i.test(modal?.item?.item?.type))
                                || /^(pending)$/i.test(comp?.status || '')
                                || check?.individual_tab_payment_disabled_by_id
                                || modal.tab?.payment_locked_by_id
                                || modal?.item?.payment_made
                        }
                        onClick={() => setCurrentStep('moveCheckItem')}
                    />
                )
            }
        </div>

        {
            !inAlteration(check) && modal?.item?.item?.type === 'item' && <>
                <hr className='my-4 border-thick' />

                <div className='d-flex align-items-center justify-content-between'>
                    <button
                        children={<span><i className="fa-solid fa-trash-can mr-2" />Void</span>}
                        className='btn btn-danger text-white w-100 mr-2'
                        disabled={
                            /^(pending|approved)$/i.test(comp?.status || '')
                                || check?.individual_tab_payment_disabled_by_id
                                || modal.tab?.payment_locked_by_id
                                || modal?.item?.payment_made
                        }
                        onClick={handleRemove}
                    />

                    {
                        !!comp ? (
                            <button
                                children={
                                    comp.status === 'pending' ? (
                                        <span><i className='fa-solid fa-hour-glass-start mr-2' />Comp Pending</span>
                                    ) : (
                                        <span>
                                            <i className={`fa-solid fa-thumbs-${/^(approved)$/i.test(comp.status) ? 'up' : 'down'} mr-2`} />
                                            {/^(approved)$/i.test(comp.status) ? 'Comped' : 'Comp Denied'}
                                        </span>
                                    )
                                }
                                className='btn btn-outline-primary w-100 mx-2 px-3'
                                disabled
                                onClick={handleComp}
                            />
                        ) : (
                            <button
                                children={<span><i className='fa-regular fa-circle-minus mr-2' />Comp</span>}
                                className='btn btn-outline-primary w-100 mx-2'
                                disabled={
                                    check?.individual_tab_payment_disabled_by_id
                                        || modal.tab?.payment_locked_by_id
                                        || modal.item.payment_made
                                }
                                onClick={handleComp}
                            />
                        )
                    }

                    <button
                        children={<span><i className="fa-sharp fa-solid fa-money-check-dollar-pen mr-2" />Discount</span>}
                        className='btn btn-primary w-100 ml-2'
                        disabled={
                            /^(pending|approved)$/i.test(comp?.status || '')
                                || check?.individual_tab_payment_disabled_by_id
                                || modal.tab?.payment_locked_by_id
                                || modal.item.payment_made
                                || !!modal.item.discount?.membership_id
                        }
                        onClick={handleDiscount}
                    />
                </div>
            </>
        }
    </>

    return modal.isOpen && (
        <Modal
            className={`manage-check-item-modal ${currentStep === 'comp' ? 'comp-modal' : ''}`}
            title={title}
            size={modal.size}
            isOpen={modal.isOpen}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <ItemCard
                className='rounded mt-4 mx-4'
                item={modal.item}
                tab={modal.tab}
            />

            <div className="d-flex flex-column justify-content-center w-100 p-4">
                {content}
            </div>
        </Modal>
    )
}
