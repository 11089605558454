import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCheckoutTab, selectCreditMethod, setCreditMethod } from '../../../advancedPointOfSaleSlice'
import CardOnFile from './CardOnFile'
import Terminal from './Terminal'
import Manual from './Manual'

export default function Credit(props) {

    const dispatch     = useDispatch()
    const checkTab     = useSelector(selectCheckoutTab)
    const creditMethod = useSelector(selectCreditMethod)

    const tabs = useMemo(() => {
        const array = ['terminal', 'manual']

        if (!!checkTab?.payment_profile?.['recurring.shopperReference']) {
            array.splice(0,0,'card_on_file')
        }

        return array
    }, [checkTab])

    const handleTabChange = (tab) => {
        dispatch(setCreditMethod(tab))
    }

    const renderCreditForm = () => {
        switch (creditMethod) {
            case 'terminal' : return (
                <Terminal
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )
            case 'card_on_file' : return (
                <CardOnFile
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )
            case 'manual' : return (
                <Manual
                    {...props}
                    tabs={tabs}
                    currentTab={creditMethod}
                    onTabChange={handleTabChange}
                />
            )
        }
    }

    useEffect(() => {
        dispatch(setCreditMethod(tabs[0]))
    }, [])

    return <>{ renderCreditForm() }</>
}
