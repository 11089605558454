import React from 'react'
import { SpecialHoursForm } from './SpecialHoursForm'
import { SpecialHoursList } from './SpecialHoursList'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function LocationHoursSpecialHours({ specialHours=[], newLocation, tz, handleManualUpdate, handleCheckboxUpdate, errors }) {
  return (
    <div className="special-hours">
      <h3>Special Hours<Helpjuice articleId='special-hours' extraClasses='' /></h3>

      <SpecialHoursForm
        newLocation={newLocation}
        specialHours={specialHours}
        tz={tz}
        handleManualUpdate={handleManualUpdate}
        handleCheckboxUpdate={handleCheckboxUpdate}
      />

      <SpecialHoursList
        specialHours={specialHours}
        tz={tz}
        handleManualUpdate={handleManualUpdate}
      />
    </div>
  )
}
