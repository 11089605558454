import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { sendTestEmail } from '@/features/Locations/locationSlice'
import LocationCustomizedEmailEditModal from '@/components/Locations/LocationCustomizedEmailEditModal'
import RichTextEditor from '@/components/Form/RichTextEditor'
import Input from '@/components/Form/Input'
import Checkbox from '@/components/Form/Checkbox'
import { useConfirm } from '@/lib/useConfirmHook'
import { validationPatterns } from '@/lib/Errors'
import { sortedAsc } from '@/lib/Array'
import titleize from '@/lib/String'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function LocationEmails({ form, errors, handleUpdate, handleManualUpdate, emailSubjects, handleCheckboxUpdate }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()

    const [emails, setEmails]                       = useState([])
    const [testEmail, setTestEmail]                 = useState(null)
    const [testEmailAddress, setTestEmailAddress]   = useState(null)
    const [currentlyEditing, setCurrentlyEditing]   = useState(null)
    const [hasUnsavedContent, setHasUnsavedContent] = useState(false)
    const [original]                                = useState(window.btoa(encodeURIComponent(form.customized_emails)))

    const handleClear = (email_name) => {
        let updatedContent         = { ...JSON.parse(form.customized_emails) }
        updatedContent[email_name] = { subject: '', content: '' }
        updatedContent             = JSON.stringify(updatedContent)

        if (window.btoa(encodeURIComponent(updatedContent)) !== original) {
            setHasUnsavedContent(true)
        }

        handleManualUpdate('customized_emails', updatedContent)
    }

    const handleEmailAddressChange = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleSendTest()
        } else {
            setTestEmailAddress(e.target.value)
        }
    }

    const handleToggleRetargetingEmail = async (e) => {
        if (form[e.target.name]) {
            const email_name = e.target.name.replace('enable_', '')
            const email      = emails[email_name]

            if (!!email && JSON.stringify(emails[email_name]) !== JSON.stringify({ subject: '', content: '' })) {
                if (await confirm(`Are you sure?<br />All customizations of the<br />"${titleize(email_name)}" email will be lost.`)) {
                    handleCheckboxUpdate(e)
                }
            } else {
                handleCheckboxUpdate(e)
            }
        } else {
            handleCheckboxUpdate(e)
        }
    }

    const handleSendTest = () => {
        if (testEmailAddress === '') { return }

        if (RegExp(validationPatterns.email.value).test(testEmailAddress)) {
            dispatch(sendTestEmail(
                form.send_test_email_url,
                testEmailAddress,
                testEmail
            ))
        } else {
            dispatch(addAlert({ type: 'error', text: 'Invalid Email Address!' }))
        }
    }

    const handleDisable = email => {
        let updatedContent = { ...JSON.parse(form.customized_emails) }

        if (updatedContent[email]["disabled"]) {
            updatedContent[email]["disabled"] = false
        } else {
            updatedContent[email]["disabled"] = true
        }

        updatedContent = JSON.stringify(updatedContent)
        handleManualUpdate('customized_emails', updatedContent)
    }

    /**
     * Parse and update emails array if the main form object's
     * customized_emails content ever changes (ex: on load or when cleared)
     */
    useEffect(() => {
        setEmails(sortedAsc(JSON.parse(form.customized_emails)))
    }, [form.customized_emails])

    /**
     * Handle injecting/removing custom email template content for
     * retargeting emails whenever these options are enabled/disabled
     */
    useEffect(() => {
        if (emails.length === 0 || !form.enable_retargeting) { return }

        [
            'retargeting_email_1',
            'retargeting_email_2',
            'retargeting_email_3',
        ].forEach((key) => {
            // enabling / injecting
            if (form[`enable_${key}`] && !Object.keys(emails).includes(key)) {
                setEmails(sortedAsc( {...emails, ...{[key]: {subject: '', content: ''}}} ))
            }
            // disabling / removing
            if (!form[`enable_${key}`] && Object.keys(emails).includes(key)) {
                setEmails(sortedAsc( Object.fromEntries(Object.entries(emails).filter(([k]) => !key.includes(k))) ))
            }
        })
    }, [
        emails,
        form.enable_retargeting,
        form.enable_retargeting_email_1,
        form.enable_retargeting_email_2,
        form.enable_retargeting_email_3,
    ])

    return (
        <div className="pt-0">
            <h3 className="mb-4 d-flex align-content-start justify-content-between">
                <span>Mailchimp Email Marketing<Helpjuice articleId='mailchimp-email-marketing' extraClasses='' /></span>
                <Checkbox
                    label='&nbsp;'
                    className='mr-n3'
                    name='add_opt_in_emails_to_list'
                    value={form.add_opt_in_emails_to_list}
                    handleChange={handleCheckboxUpdate}
                />
            </h3>
            {
                form.add_opt_in_emails_to_list && (
                    <div className="row">
                        <Input
                            name='mailchimp_api_key'
                            validation={{ 'mailchimp_api_key': { required: true }}}
                            handleChange={handleUpdate}
                            value={form.mailchimp_api_key}
                            placeholder='ddc93f41a00d30b0d9f8db440212d12f-us19'
                            cols='6'
                            toolTipOptions={{ html: true, clickable: true, place: 'top', className: 'ml-1' }}
                            labelTooltip="Click <a href='https://us1.admin.mailchimp.com/account/api' target='_blank'>this link</a> to navigate to the API Keys section of your Mailchimp account."
                            errors={errors}
                        />
                        <Input
                            name='mailchimp_server'
                            validation={{ 'mailchimp_server': { required: true }}}
                            label='Mailchimp server prefix'
                            handleChange={handleUpdate}
                            value={form.mailchimp_server}
                            placeholder='us19'
                            cols='3'
                            toolTipOptions={{ html: true, place: 'top', className: 'ml-1' }}
                            labelTooltip="Log into your Mailchimp account and look at the URL in your browser.<br>You’ll see something like https://us19.admin.mailchimp.com/<br>The us19 part is the server prefix."
                            errors={errors}
                        />
                        <Input
                            name='mailchimp_list_id'
                            validation={{ 'mailchimp_list_id': { required: true }}}
                            handleChange={handleUpdate}
                            value={form.mailchimp_list_id}
                            placeholder='1a8e68fcc3'
                            cols='3'
                            toolTipOptions={{ html: true, clickable: true, place: 'top', className: 'ml-1' }}
                            labelTooltip="<a href='https://mailchimp.com/help/find-audience-id/' target='_blank'>Find Your Audience ID</a>"
                            errors={errors}
                        />
                    </div>
                )
            }

            <h3 className="mb-4 d-flex align-content-center justify-content-between">
                <span>Abandoned Reservation Re-Targeting<Helpjuice articleId='abandoned-reservation-retargeting' extraClasses='' /></span>

                <Checkbox
                    name='enable_retargeting'
                    label='&nbsp;'
                    className='mr-n3'
                    value={form.enable_retargeting}
                    handleChange={handleCheckboxUpdate}
                />
            </h3>
            {
                form.enable_retargeting && (
                    <div className='row'>
                        <Checkbox
                            cols='4'
                            name='enable_retargeting_email_1'
                            label='Email 1: Soft Reminder<br /><small>(End of First Day)</small>'
                            value={form.enable_retargeting_email_1}
                            handleChange={handleToggleRetargetingEmail}
                        />
                        <Checkbox
                            cols='4'
                            name='enable_retargeting_email_2'
                            label='Email 2: Incentive to Return<br /><small>(24hrs after 1st)</small>'
                            value={form.enable_retargeting_email_2}
                            handleChange={handleToggleRetargetingEmail}
                        />
                        <Checkbox
                            cols='4'
                            name='enable_retargeting_email_3'
                            label='Email 3: Last Ditch Message<br /><small>(24hrs after 2nd)</small>'
                            value={form.enable_retargeting_email_3}
                            handleChange={handleToggleRetargetingEmail}
                        />
                    </div>
                )
            }

            <h3 className='mb-4 d-flex align-content-start justify-content-between'>
                <span>Thank You Email Fields<Helpjuice articleId='follow-up-email-fields' extraClasses='' /></span>
            </h3>

            <div className="row">
                <div className="col-12 col-md-6">
                    <Input
                        cols='12'
                        req={false}
                        value={form.google_review_link}
                        errors={errors}
                        validation={{ 'google_review_link': { required: false, pattern: validationPatterns.url }}}
                        handleChange={handleUpdate}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        cols='12'
                        label='"Experience Could Have Been Better" Email Address'
                        placeholder='person@example.com'
                        req={false}
                        value={form.bad_experience_email}
                        errors={errors}
                        validation={{ 'bad_experience_email': { required: false, pattern: validationPatterns.email }}}
                        handleChange={handleUpdate}
                    />
                </div>
            </div>

            {
                Boolean(form.id) && <>

                    <h3 className="mb-4">Test Emails<Helpjuice articleId='test-emails' extraClasses='' /></h3>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <select
                                className="custom-select"
                                name="email"
                                defaultValue=''
                                onChange={(e) => setTestEmail(e.target.value)}
                            >
                                <option value=''>Please choose...</option>
                                {
                                    Object.keys(emails).map((email_name) => (
                                        <option key={email_name} value={email_name}>
                                            { /email/i.test(email_name) ? titleize(email_name) : `${titleize(email_name)} Email` }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group">
                                <input
                                    name="email-address"
                                    type="email"
                                    placeholder="Email Address"
                                    defaultValue=""
                                    className="form-control"
                                    onKeyPress={(e) => handleEmailAddressChange(e)}
                                    onChange={(e) => handleEmailAddressChange(e)}
                                />
                                <div className="input-group-append">
                                    <button
                                        className={`btn ${testEmail && testEmailAddress ? 'btn-primary' : 'btn-secondary btn-disabled'}`}
                                        type="button"
                                        disabled={!(testEmail && testEmailAddress)}
                                        onClick={handleSendTest}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {
                hasUnsavedContent && currentlyEditing === null &&
                    <div className='alert alert-danger mt-5 text-center' role='alert'>
                        Your customizations have not been saved yet. Please remember to click the <strong>"Save & Close"</strong> button.
                    </div>
            }

            <h3>Customized Email Content<Helpjuice articleId='automated-notifications' extraClasses='' /></h3>
            {
                Object.keys(emails).map((email_name) => (
                    <div key={email_name} className="row mx-0">
                        <div className={`d-flex align-items-center ${email_name === 'follow_up' ? 'col-8 col-md-10' : 'col-10 col-md-11'} ${emails[email_name]["disabled"] ? 'text-strikethrough' : ''}`}>
                            <span className='mr-2'>{ /email/i.test(email_name) ? titleize(email_name) : `${titleize(email_name)} Email` }</span>
                            {email_name === 'retargeting_email_1' && <Helpjuice articleId='abandoned-cart-email-1' extraClasses='' />}
                            {email_name === 'retargeting_email_2' && <Helpjuice articleId='abandoned-cart-email-2' extraClasses='' />}

                            { (JSON.stringify(emails[email_name]) !== JSON.stringify({ subject: '', content: '' })) &&
                                <button
                                    type='button'
                                    className='btn btn-link p-0 m-0'
                                    title='Click to clear all customizations'
                                    onClick={async () => {
                                        if (await confirm(`Are you sure?<br />All customizations of the<br />"${titleize(email_name)}" email will be lost.`)) {
                                            handleClear(email_name)
                                        }
                                    }}
                                >
                                    <span className='badge badge-primary text-white py-2'>
                                        Customized
                                        <i className="far fa-times ml-3" />
                                    </span>
                                </button>
                            }
                        </div>

                        {email_name === 'follow_up' &&
                        <div className='col-2 col-md-1'>
                            <button
                                type='button'
                                className={`btn btn-link ${emails[email_name]["disabled"] ? 'text-success' : 'text-danger'}`}
                                onClick={() => handleDisable(email_name)}
                            >
                                {emails[email_name]["disabled"] ? 'Enable' : 'Disable'}
                            </button>
                        </div>
                        }

                        <div className='col-2 col-md-1'>

                            <button
                                type='button'
                                className='btn btn-link text-danger'
                                onClick={() => setCurrentlyEditing(email_name)}
                            >
                                Edit
                            </button>

                            <LocationCustomizedEmailEditModal
                                showModal={currentlyEditing === email_name}
                                email={email_name}
                                emailSubject={emailSubjects[email_name]}
                                form={form}
                                errors={errors}
                                handleClose={() => setCurrentlyEditing(null)}
                                handleManualUpdate={(name, content) => {
                                    handleManualUpdate(name, content)
                                    if (window.btoa(encodeURIComponent(content)) !== original) {
                                        setHasUnsavedContent(true)
                                    }
                                }}
                            />
                        </div>
                    </div>
                ))
            }

            <hr />

            <h3 className="mb-4">Universal Email Footer<Helpjuice articleId='universal-footer' extraClasses='' /></h3>

            <div className="row">
                <RichTextEditor
                    cols='12'
                    value={form.customized_emails_universal_footer || ''}
                    errors={errors}
                    hideLabel={true}
                    req={false}
                    validation={{ 'customized_emails_universal_footer': {}}}
                    handleChange={handleManualUpdate}
                />
            </div>
        </div>
    )
}
