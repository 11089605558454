import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import { USE_DARK_MODE } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

const useDarkMode  = window.localStorage.getItem(USE_DARK_MODE) === 'true'

export const utilitiesSlice = createSlice({
    name: 'utilities',
    initialState: {
        darkMode: useDarkMode,
    },
    reducers: {
        setDarkMode: (state, action) => {
            // set it to null first to always *force* a change
            // if the incoming value is the same as the stored value
            state.darkMode = null
            state.darkMode = action.payload
        },
    }
})

export const {
    setDarkMode
} = utilitiesSlice.actions

export const selectDarkMode = state => state.utilities.darkMode

export function updateDarkModePreference(userId, payload) {
    return async (dispatch, getState) => {
        dispatch(setDarkMode(payload))

        if (!userId) { return }

        return axios.patch(`/users/${userId}/toggle-dark-mode`, {
            authenticity_token: getState().session.formToken,
            use_dark_mode: payload
        }).then(response => {
            return response.data.success
        }).catch((error) => {
            if (debug && console) { console.error(error) }
        })
    }
}

export default utilitiesSlice.reducer
