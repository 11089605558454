import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectLocationId } from '@/features/Locations/locationSlice'

import {
    configureModal,
    closeModal,
    selectCurrentCheck,
    selectModals,
    updateCardOnTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import {
    selectTerminalResponse,
    authorizeCardViaTerminal,
    cancelTerminalTransaction,
    clearAdyenState
} from '@/features/Adyen/adyenSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import ExistingCardsOnFile from '@/features/AdvancedPointOfSale/components/ExistingCardsOnFile'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function AddCardToTabModal() {

    const dispatch               = useDispatch()
    const { addCardToTab:modal } = useSelector(selectModals)
    const locationId             = useSelector(selectLocationId)
    const currentCheck           = useSelector(selectCurrentCheck)
    const terminalResponse       = useSelector(selectTerminalResponse)

    const [selectedProfile, setSelectedProfile] = useState(null)

    const handleClose = () => {
        setSelectedProfile(null)
        dispatch(cancelTerminalTransaction())
        dispatch(configureModal({
            modal: 'addCardToTab',
            config: { tabId: null },
        }))
        dispatch(closeModal('addCardToTab'))
        dispatch(clearAdyenState())
    }

    const handleSaveAndClose = () => {
        if (!!selectedProfile) {
            dispatch(updateCardOnTab(modal.tabId, 'add-existing', selectedProfile)).then(() => {
                handleClose()
            })
        }
    }

    const handleAuthorizeCard = () => {
        setSelectedProfile(null)
        dispatch(authorizeCardViaTerminal(locationId))
    }

    // automatically save and close once the
    // response comes back from the terminal
    useEffect(() => {
        if (modal.isOpen && !!terminalResponse) {
            dispatch(updateCardOnTab(modal.tabId, 'add')).then(() => {
                handleClose()
            })
        }
    }, [modal.isOpen, terminalResponse])

    useEffect(() => {
        if (modal.isOpen) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            dispatch(clearAdyenState())
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [modal.isOpen])

    return modal.isOpen && (
        <Modal
            className='add-card-to-tab-modal'
            title='Add Card To Tab'
            isOpen={modal.isOpen}
            footerButtons={<>
                <button
                    type='button'
                    className='btn btn-link text-bold'
                    children='Cancel'
                    onClick={handleClose}
                />

                {
                    !!selectedProfile && (
                        <button
                            type='button'
                            className='btn btn-primary ml-auto py-2 text-bold'
                            children='Save'
                            onClick={handleSaveAndClose}
                        />
                    )
                }
            </>}
            onClose={handleClose}
        >
            {
                currentCheck?.booking?.payment_profiles?.length > 0 && (
                    <div className="alert alert-warning rounded mt-4 mx-4 mb-2 text-center font-weight-bold font-italic" role="alert">
                        <p className='mb-0'>Either capture a new credit card or choose an existing stored card.</p>
                    </div>
                )
            }

            <div className="p-4 w-100">
                <h6>
                    Capture a credit card
                    <small className='pl-1 font-weight-light'>
                        ({currentCheck?.booking?.payment_profiles?.length > 0 ? 'Optional' : 'Required'})
                    </small>
                </h6>
                {
                    // NOTE we intentionally wrap this component in the
                    // isOpen condition to force it to re-render whenever
                    // the modal opens/closes so that it's internal state
                    // does not become stale
                    modal.isOpen && (
                        <AdyenTerminalTransaction
                            locationId={locationId}
                            buttonText='Capture'
                            buttonTextProcessing='Capturing...'
                            buttonTextProcessed='Captured!'
                            menuClassName='border border-color-gray4'
                            transactionCallback={handleAuthorizeCard}
                        />
                    )
                }
            </div>

            <ExistingCardsOnFile
                profiles={currentCheck?.booking?.payment_profiles}
                selected={selectedProfile}
                isOptional={currentCheck?.booking?.payment_profiles?.length > 0}
                onChange={(profile) => {
                    dispatch(cancelTerminalTransaction())
                    setSelectedProfile(profile)
                }}
            />
        </Modal>
    )
}
