export function sortedByArray(items=[], sortOrder=null, key='id') {
    if (!Array.isArray(items) || !Array.isArray(sortOrder) || sortOrder.length === 0) {
        return items
    }

    return items.sort((a,b) => sortOrder.indexOf(a[key]) - sortOrder.indexOf(b[key]))
}

export function sortedAsc(object={}) {
    return Object.fromEntries(
        Object.entries(object).sort((a,b) => a[0].localeCompare(b[0]))
    )
}

export function unique(items=[], key=null) {
    return items.reduce((acc, obj) => {
        if (key === null) {
            if (!acc.some(o => o === obj)) {
                acc.push(obj)
            }
        } else {
            if (!acc.some(o => o[key] === obj[key])) {
                acc.push(obj)
            }
        }
        return acc
    }, [])
}
