import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    fullCheckout,
    tabCheckout,
    configureModal,
    openModal,
    closeCheck,
    printReceipt,
    resetCheckout,
    selectCurrentCheck,
    setCurrentComponent,
    selectPaymentMethod,
    setPaymentMethod,
    selectCheckoutTab,
    setCheckoutTab,
    setCurrentBalance,
    selectAmountTowardsBalance,
    selectTipAmount,
    setIsAmountValid,
    selectCurrentBalance,
    closeTab,
    selectShowCalculator,
    setShowCalculator,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { selectCurrentUserId } from '@/features/Session/sessionSlice'
import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import Calculator from '@/features/AdvancedPointOfSale/components/Calculator'
import CheckoutSidebar from '@/features/AdvancedPointOfSale/components/sidebars/Checkout'
import CheckoutHeader from '@/features/AdvancedPointOfSale/components/checkout/Header'
import Cash from '@/features/AdvancedPointOfSale/components/checkout/Cash'
import Check from '@/features/AdvancedPointOfSale/components/checkout/Check'
import GiftCard from '@/features/AdvancedPointOfSale/components/checkout/GiftCard'
import Credit from '@/features/AdvancedPointOfSale/components/checkout/credit/Credit'
import PaymentProcessing from '@/features/AdvancedPointOfSale/components/checkout/PaymentProcessing'
import Icon from '@/components/FontAwesomeIcon'
import {
    isPaid,
    checkNameForBreadcrumbs,
    checkConfirmationQuestion,
    tabConfirmationQuestion
} from '@/features/AdvancedPointOfSale/lib/Checks'
import { useConfirm } from '@/lib/useConfirmHook'

export default function Checkout({
    self='Checkout',
    environment,
    onChange=() => {},
}) {
    const dispatch             = useDispatch()
    const { confirm }          = useConfirm()
    const currentUserId        = useSelector(selectCurrentUserId)
    const check                = useSelector(selectCurrentCheck)
    const tab                  = useSelector(selectCheckoutTab)
    const paymentMethod        = useSelector(selectPaymentMethod)
    const currentBalance       = useSelector(selectCurrentBalance)
    const tipAmount            = useSelector(selectTipAmount)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const showCalculator       = useSelector(selectShowCalculator)

    const [processButtonContent, setProcessButtonContent] = useState(null)
    const [checkUpdateCount, setCheckUpdateCount]         = useState(0)
    const [tabUpdateCount, setTabUpdateCount]             = useState(0)
    const [isProcessing, setProcessing]                   = useState(false)

    const successPopModalDelay = 2000

    const totalDue = useMemo(() => (
        !!tab
            ? Number((currentBalance * 100).toFixed(2)) || tab?.balance_cents
            : isPaid(check)
                ? null
                : (Number((currentBalance * 100).toFixed(2)) || check?.balance_cents)
    ), [check, tab, currentBalance])

    // NOTE
    // We first convert each amount to a whole cents integer, then add them
    // together, and finally convert the amount back to a floating point number.
    //
    // Doing it this way avoids weird floating point math issues that result in
    // SUPER tiny remainder amounts which can cause an incorrect remaining balance
    // on the receipt of "-$0.00".
    const amountToProcess = useMemo(() => (
        (((amountTowardsBalance || 0) * 100) + ((tipAmount || 0) * 100)) / 100
    ), [tipAmount, amountTowardsBalance])

    const validateAmount = () => {
        if (!amountTowardsBalance && !tipAmount) { return false }
        return Number(amountTowardsBalance) <= currentBalance
    }

    const displaySuccessModal = (text, icon, delay) => {
        dispatch(configureModal({
            modal: 'popModal',
            config: { text: text, icon: `${icon} bg-green` },
            delay
        }))
        dispatch(openModal('popModal'))
    }

    const handleSwitchPaymentMethod = (e) => {
        dispatch(setPaymentMethod(e.target.name))
    }

    const handleSubmit = async (params={}) => {
        const action = !tab ? fullCheckout : tabCheckout

        let noRemainingBalanceOnCheck
        let noRemainingBalanceOnTab

        const shouldOpenCashDrawerIfAvailable = (
            (Number.parseFloat(params.totalReceivedAmount) - Number.parseFloat(params.tipAmount) > 0)
            && paymentMethod === 'cash'
        )

        if (!!tab) {
            dispatch(setCheckoutTab({ ...tab, payment_locked_by_id: null }))
        }

        setProcessing(true)

        dispatch(action(params))
        .then(async (data) => {
            if (data.success) {
                noRemainingBalanceOnCheck = data.no_remaining_balance_on_check
                noRemainingBalanceOnTab   = data.no_remaining_balance_on_tab

                displaySuccessModal(
                    data.message,
                    data.is_terminal_transaction ? 'fa-hourglass-start' : 'fa-check',
                    data.is_terminal_transaction ? successPopModalDelay + 1000 : successPopModalDelay
                )

                window.setTimeout(async() => {
                    setProcessing(false)
                    dispatch(resetCheckout())

                    if (noRemainingBalanceOnCheck || noRemainingBalanceOnTab) {
                        if (await confirm('This action cannot be undone.', {
                            header_text: noRemainingBalanceOnCheck ? checkConfirmationQuestion : tabConfirmationQuestion,
                            dangerous: true
                        })) {
                            dispatch(setCurrentComponent('PrintPay'))

                            if (noRemainingBalanceOnCheck) {
                                dispatch(closeCheck())
                            }

                            if (noRemainingBalanceOnTab) {
                                dispatch(closeTab(tab))
                            }
                        } else {
                            dispatch(setCurrentComponent('PrintPay'))
                        }
                    // we still have a balance to pay, so stay on the form
                    } else {
                        dispatch(setCurrentComponent('PrintPay'))
                    }

                    if (!data.is_terminal_transaction) {
                        dispatch(printReceipt({
                            ...(!tab ? {} : { tab }),
                            shouldOpenCashDrawerIfAvailable,
                            displayPopModal: false,
                            changeDueCents: data.change_due_cents,
                        }))
                    }
                }, successPopModalDelay + 500)

            // payment failed!
            } else {
                if (!!tab) {
                    dispatch(setCheckoutTab({ ...tab, payment_locked_by_id: currentUserId }))
                }
                setProcessing(false)
            }
        })
        .catch((e) => {
            if (console) { console.error(e) }
            setProcessing(false)
        })
    }

    const content = useMemo(() => {
        switch(paymentMethod) {
            case 'cash' : return (
                <Cash
                    self={self}
                    totalDue={totalDue}
                    isProcessing={isProcessing}
                    onLoad={(content) => setProcessButtonContent(content)}
                    onSubmit={handleSubmit}
                />
            )

            case 'check' : return (
                <Check
                    self={self}
                    totalDue={totalDue}
                    isProcessing={isProcessing}
                    onLoad={(content) => setProcessButtonContent(content)}
                    onSubmit={handleSubmit}
                />
            )

            case 'gift_card' : return (
                <GiftCard
                    self={self}
                    totalDue={totalDue}
                    isProcessing={isProcessing}
                    onLoad={(content) => setProcessButtonContent(content)}
                    onSubmit={handleSubmit}
                />
            )

            case 'credit' : return (
                <Credit
                    self={self}
                    totalDue={totalDue}
                    isProcessing={isProcessing}
                    environment={environment}
                    onLoad={(content) => setProcessButtonContent(content)}
                    onSubmit={handleSubmit}
                />
            )
        }
    }, [paymentMethod, isProcessing])

    useEffect(() => {
        onChange(
            <Breadcrumbs
                id="advanced-pos--terminal-navigation--breadcrumbs"
                data={[
                    { id: check.id, name: checkNameForBreadcrumbs(check, check.booking?.resource_name), type: 'check', component: 'DefaultMenu' },
                    { id: null, name: 'Manage Check', type: null, component: 'ManageCheck' },
                    { id: null, name: 'Print / Pay', type: null, component: 'PrintPay' },
                    { id: null, name: 'Checkout', type: null },
                ]}
                onClick={(bc) => dispatch(setCurrentComponent(bc.component))}
            />
        )
        dispatch(setPaymentMethod('credit'))
    }, [])

    useEffect(() => {
        const newBalance = (tab || check).balance_cents / 100
        if (!isNaN(newBalance)) { dispatch(setCurrentBalance(newBalance)) }
    }, [tab, check])

    useEffect(() => {
        dispatch(setIsAmountValid(validateAmount()))
    }, [amountTowardsBalance, tipAmount])

    useEffect(async () => {
        if (!isProcessing) {
            setCheckUpdateCount(checkUpdateCount + 1)

            if (checkUpdateCount > 0 && !check.individual_tab_payment_disabled_by_id) {
                dispatch(setCurrentComponent('PrintPay'))

                if (!await confirm('Another device unlocked full check payment', {
                    header_text: 'Notice',
                    confirm_only: true,
                    confirm_text: 'OK',
                    size: 'sm'
                }))  { return }
            }
        }
    }, [check.individual_tab_payment_disabled_by_id])

    useEffect(async () => {
        setTabUpdateCount(tabUpdateCount + 1)

        if (tabUpdateCount > 0) {
            const updatedTab = check.tabs.find(({ id }) => id === tab?.id)

            if (
                !!updatedTab
                && updatedTab.payment_locked_by_id === null
                && !!tab.payment_locked_by_id
            ) {
                dispatch(setCurrentComponent('PrintPay'))

                if (!await confirm('Another device unlocked tab payment', {
                    header_text: 'Notice',
                    confirm_only: true,
                    confirm_text: 'OK',
                    size: 'sm',
                })) { return }
            }
        }
    }, [check.tabs])

    return (
        <div id={`advanced-pos-terminal--${parameterize(self)}`}>
            <div id={`advanced-pos-terminal--${parameterize(self)}--container`}>
                <div id={`advanced-pos-terminal--${parameterize(self)}--content`} className='p-4'>
                    <div id={`advanced-pos-terminal--${parameterize(self)}--header`}>
                        <CheckoutHeader
                            self={self}
                            totalDue={totalDue}
                            currentPaymentMethod={paymentMethod}
                            onClick={handleSwitchPaymentMethod}
                        />
                    </div>

                    <div id={`advanced-pos-terminal--${parameterize(self)}--body`}>
                        <div id={`advanced-pos-terminal--${parameterize(self)}--payment-form--container`} className='row'>
                            {showCalculator &&
                                <div id={`advanced-pos-terminal--${parameterize(self)}--calculator`} className='col-6 p-4'>
                                    <Calculator />
                                </div>
                            }
                            <div className={`col-${showCalculator ? '6' : '9 offset-3'} p-3 d-flex flex-column`}>
                                { content }
                            </div>
                        </div>
                    </div>
                </div>

                <CheckoutSidebar
                    currentBalance={currentBalance}
                    tipAmount={Number(tipAmount)}
                    amountToProcess={amountToProcess}
                />
            </div>

            <div
                id={`advanced-pos-terminal--${parameterize(self)}--footer`}
                className='bg-gray1 d-flex flex-row justify-content-between align-items-center pl-2 pr-4'
            >
                <div>
                    <button
                        type='button'
                        children={<>
                            <Icon
                                icon={showCalculator ? 'fa-calculator fa-xl text-green' : 'fa-calculator fa-xl text-white'}
                                packs={['fa-solid']}
                            />

                            <span className='ml-1 pl-2 text-white' children='CALCULATOR' />
                        </>}
                        className='btn pl-3 mr-auto my-0'
                        onClick={() => dispatch(setShowCalculator(!showCalculator))}
                    />
                </div>

                <div>{ processButtonContent }</div>
            </div>

            <PaymentProcessing />
        </div>
    )
}
