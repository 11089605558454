import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import FormRow from '../Form/FormRow'
import MomentTimePicker from '@/components/Form/MomentTimePicker'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function ResourceTypeCustomPeakHoursTab({
  form,
  time_zone,
  handleUpdate,
  handleFormUpdate
}) {
    const [initialRender, setInitialRender] = useState(true)

    //
    // note: MomentTimePicker can accept null or
    // Moment objects as values, just not strings
    //
    const value_for = (value, allowBlank=false) => {
      // supply the timepicker with null so that it renders
      // a blank/empty field since we don't have a value
      if (!value && allowBlank) { return null }

      // for existing resource types, supply the timepicker with
      // a moment object in the location's preferred timezone
      if (value !== null && typeof value === 'string') {
        return moment(value).tz(time_zone)
      }

      // in all other case, supply the timepicker with
      // null so that it renders a blank/empty field
      if (console) { console.warn('ResourceTypePeakTimes: this should never happen!') }
      return null
    }

    useEffect(() => {
        setInitialRender(false)
    }, [])

    useEffect(() => {
        if (
          !initialRender
          && form.uses_scoring_app
          && !form.waivers_required
          && !form.use_status_icons
        ) {
            handleFormUpdate({ ...form, ...{
                use_status_icons: true,
                waivers_required: true,
            }})
        }
    }, [form.uses_scoring_app])

    useEffect(() => {
      if (
        !initialRender
        && form.uses_scoring_app
        && (
          !form.waivers_required || !form.use_status_icons
        )
      ) {
        handleFormUpdate({ ...form, ...{ uses_scoring_app: false }})
      }
    }, [form.waivers_required, form.use_status_icons])

    return <>
      <h3 className="d-flex justify-content-between align-items-center">
        <span className='d-flex'>
          <span>Custom Peak Hours</span>
          <Helpjuice articleId='resource-types-custom-peak-hours' extraClasses='' />
        </span>
      </h3>
      <div className="form-group">
        {
          ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day, index) => {
            const spacingClasses = index > 0 ? 'border-top mt-3 pt-4' : ''

            return (
              <FormRow key={`day_${index}`} className={`p-2 ${spacingClasses}`}>
                <div className="col-2">
                  <h2 className="mt-4 text-capitalize">{day}</h2>
                </div>

                <MomentTimePicker
                  cols='5'
                  label='Peak Time Starts At'
                  name={`${day}_peak_start`}
                  value={value_for(form[`${day}_peak_start`], true)}
                  tz={time_zone}
                  placeholder='XX:00 AM'
                  preventTyping={true}
                  disabled={form[`${day}_closed`]}
                  handleUpdate={handleUpdate}
                />

                <MomentTimePicker
                  cols='5'
                  label='Peak Time Ends At'
                  name={`${day}_peak_end`}
                  value={value_for(form[`${day}_peak_end`], true)}
                  tz={time_zone}
                  placeholder='XX:00 PM'
                  preventTyping={true}
                  disabled={form[`${day}_closed`]}
                  handleUpdate={handleUpdate}
                />
              </FormRow>
            )
          })
        }
      </div>
    </>
}
