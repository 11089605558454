import React from 'react'
import titleize from '../../lib/String'

export default function Label({ name, req, children=null }) {
    return (
        <label className={req ? 'req' : ''}>
            {typeof name === 'string' ? titleize(name) : name}
            {children}
        </label>
    )
}
