import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    configureModal,
    closeModal,
    openModal,
    selectModals,
    updateCardOnTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import StoredCreditCard from '@/features/AdvancedPointOfSale/components/StoredCreditCard'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import { useConfirm } from '@/lib/useConfirmHook'

export default function EditCardOnTabModal() {

    const dispatch                = useDispatch()
    const { confirm }             = useConfirm()
    const { editCardOnTab:modal } = useSelector(selectModals)

    const handleClose = () => {
        dispatch(configureModal({
            modal: 'editCardOnTab',
            config: { tabId: null, storedCard: null },
        }))
        dispatch(closeModal('editCardOnTab'))
    }

    const handleRemove = async () => {
        if (await confirm('Are you sure you want to remove this card?', { dangerous: true })) {
            dispatch(updateCardOnTab(modal.tabId, 'remove')).then(() => {
                handleClose()
            })
        }
    }

    const handleReplace = async () => {
        if (await confirm('<p>Are you sure you want to replace this card?</p><p>The existing card will be removed first even if a new card is unable to be stored.</p>', { dangerous: true })) {
            await dispatch(updateCardOnTab(modal.tabId, 'remove')).then(() => {
                dispatch(configureModal({
                    modal: 'addCardToTab',
                    config: { tabId: modal.tabId },
                }))
                dispatch(openModal('addCardToTab'))
                handleClose()
            })
        }
    }

    return modal.isOpen && (
        <Modal
            className='edit-card-on-tab-modal'
            title='Edit Card On Tab'
            isOpen={modal.isOpen}
            footerButtons={<>
                <button
                    children='Cancel'
                    type='button'
                    className='btn btn-link text-bold'
                    onClick={handleClose}
                />
            </>}
            onClose={handleClose}
        >
            {
                !!modal.storedCard ? (
                    <div className="p-4 mt-4 d-flex align-items-center">
                        <StoredCreditCard card={modal.storedCard} />

                        <button
                            children='Remove'
                            type='button'
                            className='btn btn-link-danger py-0 px-3 ml-auto mr-2'
                            onClick={handleRemove}
                        />

                        <button
                            children='Replace'
                            type='button'
                            className='btn btn-primary py-0 px-3'
                            onClick={handleReplace}
                        />
                    </div>
                ) : (
                    <CenteredText
                        text='No Card On File'
                        textClassName='text-gray4 h5 mt-2 py-5'
                    />
                )
            }
        </Modal>
    )
}
