import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Label from '../Form/Label'
import Image from "../Form/Image";
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import Checkbox from '@/components/Form/Checkbox'
import TextArea from '@/components/Form/TextArea'
import Tooltip from '@/components/Form/Tooltip'
import Addresses from '@/components/Locations/LocationAddresses'
import Helpjuice from '@/features/Helpjuice/Helpjuice'
import { isAdvancedPointOfSaleEnabled, isAdvancedPointOfSaleInTransition } from '@/lib/AdvancedPointOfSale'
import { validationPatterns } from '@/lib/Errors'

export default function LocationSetupForm({
  form,
  errors,
  imagesUrl,
  directUploadUrl,
  loaded,
  membershipsEnabled,
  superUser,
  handleTabLoaded,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
}) {

  const [previewImages, setPreviewImages] = useState({
    currentReceiptLogo: null,
  })

  const [imagesFetched, setImagesFetched] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)

  useEffect(() => {
    if (!loaded) { handleTabLoaded() }
  }, [])

  useEffect(() => {
    // we don't do anything if we've already fetched the images or if we have no url to fetch from (new record)
    if (imagesFetched || !imagesUrl) {
      return
    }

    // turn on spinners
    setImagesLoading(true)

    axios.get(imagesUrl).then(({ data }) => {
      setPreviewImages({
        currentReceiptLogo: data.adv_pos_receipt_logo,
      })
    }).catch((error) => {
      console.warn('Unable to load images...', error)
    }).finally(() => {
      setImagesLoading(false)
      setImagesFetched(true)
    })
  }, [imagesFetched])

  return (
    <>
      <h4>Basic Location Setup<Helpjuice articleId='basic-location-setup' extraClasses='' /></h4>

      <FormRow>
        <Input
          value={form.name}
          label="Location Name"
          cols={9}
          errors={errors}
          validation={{ 'name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          mask="***"
          name='reservation_prefix'
          value={form.reservation_prefix}
          cols={3}
          handleChange={handleUpdate}
          validation={{ 'reservation_prefix': {
            required: true,
            pattern: validationPatterns.locationPrefix
          }}}
          errors={errors} />
      </FormRow>

      <FormRow>
        <Input
          value={form.website}
          cols={4}
          errors={errors}
          validation={{ 'website': {
            required: true,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.reservations_page_link}
          cols={4}
          errors={errors}
          req={false}
          validation={{ 'reservations_page_link': {
            required: false,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.contact_page_link}
          cols={4}
          errors={errors}
          validation={{ 'contact_page_link': {
            required: true,
            pattern: validationPatterns.url,
          }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <Addresses
        form={form}
        errors={errors}
        handleUpdate={handleUpdate}
        handleCheckboxUpdate={handleCheckboxUpdate}
      />

      <h4>Advanced Location Setup<Helpjuice articleId='location-setup-continued' extraClasses='' /></h4>

      <FormRow>
        <Input
          value={form.contact_email}
          cols={4}
          errors={errors}
          validation={{ 'contact_email': {
            required: true,
            pattern: validationPatterns.email,
          }}}
          handleChange={handleUpdate}
        />

        <Input
          value={form.contact_name}
          cols={4}
          errors={errors}
          validation={{ 'contact_name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          mask='999-999-9999'
          value={form.contact_phone}
          cols={4}
          errors={errors}
          validation={{ 'contact_phone': {
            required: true,
            pattern: validationPatterns.phone,
          }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <h3>Taxation</h3>

      <FormRow>
        <Input
          cols='3'
          label='Global Sales Tax Rate'
          value={form.tax_rate}
          type='number'
          min={0}
          max={100}
          step='1'
          append={true}
          errors={errors}
          validation={{ 'tax_rate': {
            required: true,
            pattern: validationPatterns.taxRate
          }}}
          handleChange={handleUpdate}
        >
          <div className="input-group-append">
              <div className="input-group-text">%</div>
          </div>
        </Input>

        <Checkbox
          cols='4'
          className="mt-4 pt-1 pl-3 offset-1"
          name='reservations_taxable'
          label='Reservations Taxable?'
          value={form.reservations_taxable}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h3>Reservations</h3>

      <FormRow>
        <Checkbox
          cols='6'
          name='send_text_confirmation'
          label='Send Text Confirmation?'
          value={form.send_text_confirmation}
          handleChange={handleCheckboxUpdate}
        />

        <Checkbox
          cols='6'
          name='include_payment_link_in_confirmation'
          label={`Include payment link in confirmation ${form.send_text_confirmation ? 'text / email' : 'email'}?`}
          value={form.include_payment_link_in_confirmation}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h3>Quoting</h3>

      <FormRow>
        <Select
          name='quote_expiring_soon_hours'
          label='Quote Expiration Reminder'
          cols='3'
          handleChange={handleUpdate}
          req={true}
          options={[
            { value: 24, display: "24 Hours Before" },
            { value: 48, display: "48 Hours Before" },
            { value: 72, display: "72 Hours Before" },
          ]}
          value={form.quote_expiring_soon_hours}
          errors={errors}
          validation={{ 'quote_expiring_soon_hours': { required: true } }}
        />

        <Checkbox
          cols='6'
          className="mt-4 pt-1 pb-0 pl-3"
          name='allow_customer_to_update_quote_status'
          label='Allow customer to accept/reject quote from email?'
          value={form.allow_customer_to_update_quote_status}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h3 className='d-flex align-content-center justify-content-between'>
        <span>
          <span>Advanced Point of Sale</span>

          {
            !superUser && isAdvancedPointOfSaleInTransition(form.advanced_point_of_sale_status) && (
              <span className='badge badge-warning py-1 ml-3' style={{ fontSize: '0.8rem' }}>In Transition</span>
            )
          }
        </span>

        {
          superUser ? (
            <Select
              cols='auto pr-0 mt-n2'
              name='advanced_point_of_sale_status'
              options={[
                { value: 'enabled', display: 'Enabled' },
                { value: 'in_transition', display: 'In-Transition' },
                { value: 'disabled', display: 'Disabled' },
              ]}
              value={form.advanced_point_of_sale_status}
              errors={errors}
              validation={{ 'advanced_point_of_sale_status': { required: true } }}
              prepend
              hideLabel
              req
              handleChange={handleUpdate}
            >
              <div className='input-group-prepend'>
                <span className='input-group-text'>Status</span>
              </div>
            </Select>
          ) : (
            <Checkbox
              className='mt-2 mr-4'
              cols=''
              label=' '
              name='advanced_point_of_sale_status'
              tooltip={
                isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true })
                  ? 'This feature is permanently enabled'
                  : 'Please contact us about enabling this feature'
              }
              value={isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true })}
              disabled
              readOnly
            />
          )
        }
      </h3>

      {
        isAdvancedPointOfSaleEnabled(form.advanced_point_of_sale_status, { allowTransition: true }) && <>
          <FormRow>
            <Input
              cols='6'
              label='Session Timeout'
              value={form.session_timeout_seconds}
              type='number'
              min='0'
              step='1'
              append={true}
              errors={errors}
              req={false}
              validation={{ 'session_timeout_seconds': { required: false }}}
              handleChange={handleUpdate}
            >
              <div className="input-group-append">
                  <div className="input-group-text">
                    {form.session_timeout_seconds == 1 ? 'Second' : 'Seconds'}
                    {
                      form.session_timeout_seconds < 60
                        ? ''
                        : form.session_timeout_seconds > 60
                          ? ' / ' + (form.session_timeout_seconds / 60).toFixed(2).replace('.00', '') + ' Minutes'
                          : ' / 1 Minute'
                    }
                  </div>
              </div>
            </Input>
          </FormRow>

          <FormRow>
            <div className='col-12'>
              <Image
                  loading={imagesLoading}
                  name='adv_pos_receipt_logo'
                  label='Receipt Logo'
                  handleChange={handleManualUpdate}
                  previewUrl={previewImages.currentReceiptLogo}
                  directUploadUrl={directUploadUrl}
                  tooltip='Maximum of 100 pixels in height, recommended 200 x 100 pixels'
              />
            </div>
          </FormRow>

          <div className='form-group'>
            <Label name='Suggested Tip Brackets' req={true} />
            <FormRow>
              <Input
                cols='2'
                value={form.suggested_tip_percentage_1}
                type='number'
                min='0'
                step='1'
                placeholder='18'
                append={true}
                hideLabel={true}
                errors={errors}
                validation={{ 'suggested_tip_percentage_1': { required: true }}}
                handleChange={handleUpdate}
              >
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </Input>
              <Input
                cols='2'
                value={form.suggested_tip_percentage_2}
                type='number'
                min='0'
                step='1'
                placeholder='20'
                append={true}
                hideLabel={true}
                errors={errors}
                validation={{ 'suggested_tip_percentage_2': { required: true }}}
                handleChange={handleUpdate}
              >
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </Input>
              <Input
                cols='2'
                value={form.suggested_tip_percentage_3}
                type='number'
                min='0'
                step='1'
                placeholder='22'
                append={true}
                hideLabel={true}
                errors={errors}
                validation={{ 'suggested_tip_percentage_3': { required: true }}}
                handleChange={handleUpdate}
              >
                <div className="input-group-append">
                  <div className="input-group-text">%</div>
                </div>
              </Input>
            </FormRow>
          </div>
        </>
      }

      <h3 className='d-flex align-content-center justify-content-between'>
        <span>Memberships</span>
        <Checkbox
          className={`mt-2 ${membershipsEnabled || form.payment_processor_id === 1 ? 'mr-4' : ''}`}
          cols=''
          label=' '
          name='memberships_enabled'
          tooltip={
            membershipsEnabled
              ? 'This feature is permanently enabled'
              : form.payment_processor_id === 1
                ? 'Please contact us about enabling this feature'
                : ''
          }
          disabled={membershipsEnabled || form.payment_processor_id === 1}
          value={form.memberships_enabled}
          handleChange={handleCheckboxUpdate}
        />
      </h3>

      <FormRow>
        {
          form.memberships_enabled && (
            <Checkbox
              cols={4}
              name='memberships_taxable'
              label='Memberships Taxable?'
              value={form.memberships_taxable}
              handleChange={handleCheckboxUpdate}
            />
          )
        }
      </FormRow>

      <h3>Usage Analytics</h3>

      <h4 className='mt-4'>
        Facebook Pixel
        <Tooltip id='facebook' place='right'>
          Facebook pixel code
        </Tooltip>
      </h4>

      <FormRow>
        <TextArea
          name='facebook_pixel'
          handleChange={handleUpdate}
          value={form.facebook_pixel}
          placeholder='<OPTIONAL GOES CODE HERE>'
          cols='12'
          hideLabel={true} />
      </FormRow>

      <h4 className='mt-1'>
        Google Analytics
        <Tooltip id='google' place='right'>
          Google analytics code
        </Tooltip>
      </h4>

      <FormRow>
        <TextArea
          name='google_analytics'
          placeholder='<OPTIONAL GOES CODE HERE>'
          handleChange={handleUpdate}
          value={form.google_analytics}
          cols='12'
          hideLabel={true} />
      </FormRow>
    </>
  )
}
