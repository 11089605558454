import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    configureModal,
    openModal,
    lockTabPayments,
    unlockTabPayments,
    fetchCheck,
    selectCurrentCheck,
    setCheckoutTab,
    setCurrentComponent,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { selectCurrentUserId } from '@/features/Session/sessionSlice'
import { useManagerOverride } from '@/lib/useManagerOverrideHook'
import { numberToCurrency } from '@/lib/Number'
import { isPartiallyPaid } from '@/features/AdvancedPointOfSale/lib/Checks'
import { debug } from '@/lib/Debug'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'

export default function TabTableRow({
    tab={},
    pendingComps={},
    transactionsAllowed=false,
    asFullCheckPayment=false,
    onConfirmCompPending=() => {},
}) {
    const dispatch      = useDispatch()
    const { authorize } = useManagerOverride()
    const check         = useSelector(selectCurrentCheck)
    const currentUserId = useSelector(selectCurrentUserId)

    const hasPendingComp = !!tab.items.find(({ item }) => (
        pendingComps.map((comp) => comp.item_uuid).includes(item.uuid)
    ))

    const handleViewTab = () => {
        dispatch(configureModal({
            modal: 'receipt',
            config: { tab, footer: 'complex' }
        }))

        dispatch(openModal('receipt'))
    }

    const handlePrintTab = () => {
        dispatch(configureModal({
            modal: 'printOption',
            config: {
                title: 'Print Tab',
                data: {
                    tab: tab,
                    includeTipLine: true,
                    includeSignatureLine: true,
                }
            }
        }))
        dispatch(openModal('printOption'))
    }

    const handleUnlockTabPayment = async () => {
        if (await authorize()) {
            dispatch(unlockTabPayments(tab))
        }
    }

    const handlePayTab = () => {
        if (hasPendingComp) {
            onConfirmCompPending()
        } else {
            dispatch(lockTabPayments(tab)).then((data) => {
                if (data.success && data.payload.server_id) {
                    dispatch(fetchCheck(check.id)).then((_check) => {
                        if (!!_check) {
                            dispatch(setCheckoutTab({ ...tab, payment_locked_by_id: data.payload.server_id }))
                            dispatch(setCurrentComponent('Checkout'))
                        }
                    })
                }
            })
        }
    }

    return (
        <tr>
            { debug && <td className='text-left pl-2'>{tab.id}</td> }

            <td className='text-left pl-2'>
                <div className='d-flex justify-items-start align-items-center'>
                    { tab.is_general && <i className="fa-solid fa-star text-yellow h6 m-0 mr-2" /> }
                    { !!tab.payment_profile && <i className="fa-regular fa-credit-card mr-2 text-white" /> }
                    { tab.is_general && check.booking?.name || tab.name }
                </div>
            </td>

            <td className='text-right'>
                <TabStatus tab={tab} iconSize='fa-lg' compact />
            </td>

            <td className="text-right text-green">
                {!check.individual_tab_payment_disabled_by_id ? numberToCurrency(-1 * (tab.discount_cents / 100)) : ''}
            </td>

            <td className='text-right'>
                {!check.individual_tab_payment_disabled_by_id ? numberToCurrency(tab.total_cents / 100) : ''}
            </td>

            <td className='text-right'>
                {!check.individual_tab_payment_disabled_by_id ? numberToCurrency((tab.total_paid - tab.refund_total) / 100) : ''}
            </td>

            <td className='text-right'>
                {
                    !check.individual_tab_payment_disabled_by_id && (
                        <span
                            className={tab.balance_cents < 0 ? 'text-danger font-weight-bold fa-fade' : ''}
                            children={numberToCurrency(tab.balance_cents / 100)}
                        />
                    )
                }
            </td>

            <td className='px-0'>
                {
                    tab.items.length > 0 && (
                        <button
                            children='View Receipt'
                            type='button'
                            className="btn btn-link text-blue text-capitalize"
                            onClick={handleViewTab}
                        />
                    )
                }
            </td>

            <td className='text-right'>
                <button
                    children={asFullCheckPayment ? 'Print Full Check' : 'Print Tab'}
                    type='button'
                    className="btn btn-outline-primary text-capitalize w-100 px-1"
                    disabled={tab.items.length === 0}
                    onClick={handlePrintTab}
                />
            </td>

            <td className='text-right pr-0'>
                {
                    !tab.payment_locked_by_id || tab.payment_locked_by_id === currentUserId ? (
                        <button
                            type='button'
                            className={[
                                'btn',
                                (tab.amount_cents <= 0 || tab.balance_cents <= 0) ? 'btn-outline-primary' : 'btn-primary',
                                hasPendingComp ? 'comp-pending' : '',
                                'text-capitalize w-100 px-1',
                            ].join(' ')}
                            disabled={
                                !transactionsAllowed
                                || tab.items.length === 0
                                || check.has_unallocated_booking_credits
                                || check.individual_tab_payment_disabled_by_id
                            }
                            onClick={handlePayTab}
                        >
                            { check.individual_tab_payment_disabled_by_id && <span className='mr-2'><i className="fa-solid fa-lock" /></span> }

                            {
                                tab.balance_cents > 0
                                    ? asFullCheckPayment
                                        ? isPartiallyPaid(check)
                                            ? 'Pay Remaining Balance'
                                            : 'Pay Full Check'
                                        : 'Pay Tab'
                                    : 'Add Tip'
                            }

                            { hasPendingComp && <span className='info-icon'><i className="fa-solid fa-info"></i></span> }
                        </button>
                    ) : (
                        <button
                            type='button'
                            className="btn btn-outline-danger text-white text-capitalize w-100 px-1"
                            onClick={handleUnlockTabPayment}
                        >
                            <span className='mr-2'><i className="fa-solid fa-unlock" /></span>Unlock Payment
                        </button>
                    )
                }
            </td>
        </tr>
    )
}
