import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'

import {
    closeModal,
    configureModal,
    fetchMenuItemContent,
    selectCurrentChit,
    selectModals,
    setCurrentComponent,
    setItemEditMode,
    setItemConfig,
    setTemporary,
    removeItemFromChitTab,
    openModal,
    openSidebarTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import MoveChitItemModal from '@/features/AdvancedPointOfSale/components/modals/MoveChitItemModal'
import ItemCard from '@/features/AdvancedPointOfSale/components/cards/ItemCard'
import Alert from '@/features/AdvancedPointOfSale/components/Alert'

export default function ManageChitItemModal() {

    const dispatch                      = useDispatch()
    const { confirm }                   = useConfirm()
    const currentChit                   = useSelector(selectCurrentChit)
    const { manageChitItem:modal }      = useSelector(selectModals)
    const [currentStep, setCurrentStep] = useState('manageChitItem')
    const [title, setTitle]             = useState('')
    const [content, setContent]         = useState(null)

    const otherTabsPresent = useMemo(() => (
        (currentChit?.tabs || [])
            .filter((tab) => tab.id !== modal.tab?.id)
            .filter((tab) => !tab.is_booking)
            .length > 0
    ), [currentChit?.tabs, modal.tab?.id])

    const handleClose = () => {
        // reset modal state
        setCurrentStep('manageChitItem')

        dispatch(configureModal({
            modal: 'manageChitItem',
            config: {
                tab: null,
                item: null,
            }
        }))

        dispatch(closeModal('manageChitItem'))
    }

    const handleEditItem = () => {
        if (!modal?.item?.item?.id) { return }

        dispatch(fetchMenuItemContent(modal.item.item.id)).then(() => {
            dispatch(setItemEditMode('chit-item'))

            let isEdited = false

            // this is config that will be shown on edit item mode
            // if the item contains 86'd modifier(s), we need to remove them from config
            // before moving to edit item mode
            const updatedItemConfig = [...modal.item.config].map(modifier => {
                if (modal.unavailableModifierIds.includes(parseInt(modifier.id))) {
                    if (modifier.chit_action === null) {
                        // default modifier
                        if (!isEdited) { isEdited = true }
                        return { ...modifier, chit_action: 'unavailable', is_available: false }
                    } else if (modifier.chit_action === 'add') {
                        // non default modifier
                        if (!isEdited) { isEdited = true }
                        return undefined
                    } else {
                        return modifier
                    }
                } else {
                    return modifier
                }
            }).filter(config => config !== undefined)

            dispatch(setItemConfig({ modifiers: updatedItemConfig, notes: modal.item.notes }))
            dispatch(setTemporary({ tabId: modal.tab.id, itemUuid: modal.item.item.uuid, isEdited: isEdited }))
            dispatch(setCurrentComponent('MenuItem'))
            handleClose()
        })
    }

    const handleRemove = async () => {
        if (!modal?.item?.item?.id) { return }

        if (await confirm(`Are you sure you want to remove<br /><em class="d-inline-block pt-2">${modal.item.item.name}</em>?`)) {
            dispatch(removeItemFromChitTab())
            handleClose()
            dispatch(openSidebarTab({ sidebar: 'checkAndChit', tabIndex: 1 }))
        }
    }

    const handleDuplicate = () => {
        if (!modal?.item?.item?.id) { return }

        dispatch(closeModal('manageChitItem'))
        dispatch(openModal('addToChitTab'))
        dispatch(configureModal({
            modal: 'addToChitTab',
            config: {
                duplicateItem: {
                    id: modal.item.item.id,
                    config: { modifiers: modal.item.config, notes: modal.item.notes },
                },
                title: 'Duplicate Item To',
            }
        }))
    }

    useEffect(() => {
        if (currentStep === 'manageChitItem') {
            setTitle('Manage Item')
            setContent(manageChitItem)

        } else if (currentStep === 'moveChitItem') {
            setTitle('Move Item To')
            setContent(<MoveChitItemModal handleClose={handleClose} />)
        }
    }, [currentStep, modal.item])

    const manageChitItem = <>
        <button
            children='Duplicate Item'
            className='btn btn-primary'
            disabled={modal.isUnavailable || modal.isModifierUnavailable}
            onClick={handleDuplicate}
        />

        <button
            children='Edit Item'
            className='btn btn-primary mt-3'
            disabled={modal.isUnavailable}
            onClick={handleEditItem}
        />

        {
            otherTabsPresent && (
                <button
                    children='Move to a Different Tab'
                    className='btn btn-primary mt-3'
                    disabled={modal.isUnavailable || modal.isModifierUnavailable}
                    onClick={() => setCurrentStep('moveChitItem')}
                />
            )
        }

        <hr className='my-4' />

        <button
            children='Remove Item'
            className='btn btn-danger text-white'
            onClick={handleRemove}
        />

        <button
            children='Cancel'
            className='btn btn-outline-secondary text-white mt-3'
            onClick={handleClose}
        />
    </>

    return modal.isOpen && (
        <Modal
            className='manage-chit-item-modal'
            title={title}
            size={modal.size}
            isOpen={modal.isOpen}
            onClose={handleClose}
        >

            <ItemCard
                className='rounded mt-4 mx-4'
                item={modal.item}
                tab={modal.tab}
            />

            {modal.isUnavailable && <Alert text="This item is 86'd" className='my-3 mx-4' />}
            {modal.isModifierUnavailable && <Alert text="One or more modifiers has been 86'd." className='my-3 mx-4' />}

            <div className="d-flex flex-column justify-content-center w-100 p-4">
                {content}
            </div>
        </Modal>
    )
}
