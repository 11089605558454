import React from 'react'
import { useDispatch } from 'react-redux'
import { setBalanceRemainingCents } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { numberToCurrency } from '@/lib/Number'

export default function Totals({
    className='',

    // primarily used when viewing/previewing receipts
    subtotalCents=null,
    discountCents=null,
    compsCents=null,
    taxCents=null,
    autoGratuityCents=null,
    totalCents=null,
    grandTotalCents=null,
    amountPaidCents=null,

    // primarily used in the checkout flow
    balanceCents=null,
    tipCents=null,
    paymentAmountCents=null,
    managerAdjustmentCreditCents=null,

    hideAmountPaid=false,
    hideTipsCollected=false,
    hideRemainingBalance=false,
    checkoutMode=false,
    compact=false,
}) {

    const dispatch = useDispatch()

    // delay it so that we don't get rendering errors from React
    window.setTimeout(() => {
        dispatch(setBalanceRemainingCents(
            (!!balanceCents || balanceCents === 0) && (!!paymentAmountCents || paymentAmountCents === 0)
                ? (balanceCents - (paymentAmountCents - tipCents))
                : null
        ))
    }, 100)

    const discountsAndCompsCents = (discountCents || 0) + (compsCents || 0)

    const totalDue = totalCents || grandTotalCents || 0

    return (
        <div className={className}>
            <table className='table-sm w-100 border-bottom-none'>
                <colgroup>
                    <col width='100%' />
                    <col width='auto' />
                </colgroup>
                <tfoot>
                    {
                        (!!subtotalCents || subtotalCents === 0) && (
                            <tr>
                                <td className={`text-right ${subtotalCents === 0 ? '' : 'border-top border-top-gray4 border-thick'} py-1`}>
                                    <small className='font-weight-bolder pr-3'>SUBTOTAL</small>
                                </td>
                                <td className={`text-right ${subtotalCents === 0 ? '' : 'border-top border-top-gray4 border-thick'} py-1`}>
                                    <small className='font-weight-bolder'>
                                        { numberToCurrency((subtotalCents + discountsAndCompsCents) / 100) }
                                    </small>
                                </td>
                            </tr>
                        )
                    }

                    {
                        !!discountsAndCompsCents && (
                            <tr>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder pr-3'>COMPS & DISCOUNTS</small>
                                </td>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder'>
                                        { numberToCurrency(discountsAndCompsCents / 100 * -1) }
                                    </small>
                                </td>
                            </tr>
                        )
                    }

                    {
                        !!taxCents && (
                            <tr>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder pr-3'>TAXES & FEES</small>
                                </td>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder'>
                                        { numberToCurrency(taxCents / 100) }
                                    </small>
                                </td>
                            </tr>
                        )
                    }

                    {
                        !!autoGratuityCents && (
                            <tr>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder pr-3'>AUTO-GRATUITY</small>
                                </td>
                                <td className='text-right py-1'>
                                    <small className='font-weight-bolder'>
                                        { numberToCurrency(autoGratuityCents / 100) }
                                    </small>
                                </td>
                            </tr>
                        )
                    }

                    {
                        (!!totalDue || totalDue === 0) && (
                            <tr>
                                <td className={`text-right border-top border-top-gray4 ${compact ? '' : 'border-bottom border-bottom-gray4'} border-thick py-2`.trim()}>
                                    <span className='font-weight-bolder pr-3'>TOTAL DUE</span>
                                </td>
                                <td className={`text-right border-top border-top-gray4 ${compact ? '' : 'border-bottom border-bottom-gray4'} border-thick py-2`.trim()}>
                                    <span className='font-weight-bolder'>
                                        { numberToCurrency(totalDue / 100) }
                                    </span>
                                </td>
                            </tr>
                        )
                    }

                    {
                        !!managerAdjustmentCreditCents && (
                            <tr>
                                <td className={`text-right border-top border-top-gray4 ${compact ? '' : 'border-bottom border-bottom-gray4'} border-thick py-2`.trim()}>
                                    <span className='font-weight-bolder pr-3 text-pink'>MANAGER ADJUSTMENTS</span>
                                </td>
                                <td className={`text-right border-top border-top-gray4 text-pink ${compact ? '' : 'border-bottom border-bottom-gray4'} border-thick py-2`.trim()}>
                                    <span className='font-weight-bolder'>
                                        { numberToCurrency(managerAdjustmentCreditCents / 100) }
                                    </span>
                                </td>
                            </tr>
                        )
                    }

                    {
                        !checkoutMode && <>
                            {
                                !!amountPaidCents && !hideAmountPaid && (
                                    <tr>
                                        <td className='text-right py-1'>
                                            <small className='font-weight-bolder pr-3'>
                                                BALANCE PAID
                                            </small>
                                        </td>
                                        <td className='text-right py-1'>
                                            <small className='font-weight-bolder'>
                                                { numberToCurrency(amountPaidCents / 100) }
                                            </small>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                !!tipCents && (!hideAmountPaid || !hideTipsCollected) && (
                                    <tr>
                                        <td className='text-right py-1'>
                                            <small className='font-weight-bolder pr-3'>{checkoutMode ? 'TIP AMOUNT' : 'TIPS PAID'}</small>
                                        </td>
                                        <td className='text-right py-1'>
                                            <small className='font-weight-bolder'>
                                                { numberToCurrency(tipCents / 100) }
                                            </small>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                (!!balanceCents || balanceCents === 0) && totalDue > 0 && !hideRemainingBalance && (
                                    <tr>
                                        <td className='text-right border-top border-top-gray4 border-thick py-2'>
                                            <span className='font-weight-bolder pr-3'>
                                                REMAINING BALANCE
                                            </span>
                                        </td>
                                        <td className='text-right border-top border-top-gray4 border-thick py-2'>
                                            <span className='font-weight-bolder'>
                                                { numberToCurrency(balanceCents / 100)}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                        </>
                    }

                    {
                        checkoutMode && <>
                            {
                                !!amountPaidCents && (
                                    <tr>
                                        <td className='text-right py-1'>
                                            <span className='font-weight-bolder pr-3'>
                                                BALANCE PAID
                                            </span>
                                        </td>
                                        <td className='text-right py-1'>
                                            <span className='font-weight-bolder'>
                                                { numberToCurrency(amountPaidCents / 100) }
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                (!!balanceCents || balanceCents === 0) && (
                                    <tr>
                                        <td className='text-right py-1'>
                                            <span className='font-weight-bolder pr-3'>
                                                REMAINING BALANCE
                                            </span>
                                        </td>
                                        <td className='text-right py-1'>
                                            <span className={`font-weight-bolder ${balanceCents === 0 ? 'text-success' : 'text-white'}`}>
                                                { numberToCurrency(balanceCents / 100)}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                        </>
                    }
                </tfoot>
            </table>
        </div>
    )
}
