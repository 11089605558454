import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen, selectArticle, fetchArticle } from '@/features/Helpjuice/helpjuiceSlice'

export default function Helpjuice({ articleId, extraClasses='float-left ml-2' }) {

    const dispatch = useDispatch()
    const article  = useSelector(selectArticle)

    const handleOpen = () => {
        dispatch(setOpen())

        // for now don't re-load the article if it's already loaded, just open the modal again
        if (article?.article?.id != articleId) {
            dispatch(fetchArticle(articleId))
        }
    }

    return (
        <span className={`helpjuice ${extraClasses}`}>
            <button
                type='button'
                className='helpjuice-toggle btn-none'
                title='Click for help on this topic'
                onClick={handleOpen}
            >
                <i className='far fa-question-square' />
            </button>
        </span>
    )
}
