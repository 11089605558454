import React from 'react'
import titleize from '../../lib/String'

export default function ResourceTypeTabs({ handleClick, activeTab, form }) {
  let tabs = ['setup', 'custom_open_hours', 'custom_peak_hours', 'special_pricing']

  return (
    <div className="drawer-tabs">
      {
        tabs.map((tabName) => (
          <div
            key={tabName}
            className={`tab ${tabName === activeTab ? 'active' : ''}`}
            onClick={() => handleClick(tabName)}
          >
            { titleize(tabName) }
          </div>
        ))
      }
    </div>
  )
}
