import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen, selectArticle, fetchArticle } from '@/features/Helpjuice/helpjuiceSlice'

export default function HelpjuiceLink({ linkText=null, articleId }) {
    const dispatch = useDispatch()
    const article  = useSelector(selectArticle)

    const handleOpen = () => {
        dispatch(setOpen())

        // for now don't re-load the article if it's already loaded, just open the modal again
        if (article?.article?.id != articleId) {
            dispatch(fetchArticle(articleId))
        }
    }
    return <>
        {linkText ?
            <div
                className='btn btn-link'
                onClick={handleOpen}
            >
                {linkText}
            </div>
        :
            <span className='btn btn-none' onClick={handleOpen}><i className='far fa-question-square' /></span>
        }
    </>
}
