import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectModals,
    closeModal,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import { numberToCurrency } from '@/lib/Number'
import { parameterize } from 'inflected'

export default function ReportModal() {

    const dispatch          = useDispatch()
    const { report: modal } = useSelector(selectModals)
    const isEndOfShift      = /^(endOfShift)$/i.test(modal.reportType)

    const handleCloseOnly = () => dispatch(closeModal('report'))

    const footerButtons = <>
        <button
            children="Cancel"
            className="btn btn-link py-3 px-5 w-50"
            onClick={handleCloseOnly}
        />
        <button
            children="Close & Print"
            className="btn btn-primary text-white ml-auto"
            onClick={modal.onClose}
        />
    </>

    return modal.isOpen && (
        <Modal
            title={`${isEndOfShift ? 'End of Shift' : 'Close of Day'} Report`}
            size={modal.size}
            isOpen={modal.isOpen}
            footerButtons={footerButtons}
            onClose={handleCloseOnly}
            className='report-modal'
        >
            <div className='report-body'>
                { isEndOfShift ? <>
                    <div className='category'>SHIFT DETAILS</div>
                    <table className='w-100'>
                        <tbody>
                            <tr><td>Checks Closed</td><td>{modal.data.check_count}</td></tr>
                        </tbody>
                    </table>
                    <div className='category'>TIP DETAILS</div>
                    <table className='w-100'>
                        <tbody>
                            <tr><td>Cash Sales</td><td>{numberToCurrency(modal.data.cash_sales / 100.0)}</td></tr>
                            <tr><td>Cash Tips</td><td>{numberToCurrency(modal.data.cash_tip_cents / 100.0)}</td></tr>
                            <tr><td>Non-Cash Tips</td><td>{numberToCurrency(modal.data.non_cash_tip_cents / 100.0)}</td></tr>
                            <tr><td>Cash Owed to You</td><td>{numberToCurrency(modal.data.cash_owed_to_you / 100.0)}</td></tr>
                            <tr><td>Cash Owed to House</td><td>{numberToCurrency(modal.data.cash_owed_to_house / 100.0)}</td></tr>
                        </tbody>
                    </table>
                </>:<>
                    <table className='w-100'>
                        <tbody>
                            <tr><td>Number of Reservations</td><td>{modal.data.booking_count}</td></tr>
                        </tbody>
                    </table>
                    <div className='category'>RESOURCE TYPES</div>
                    <table className='w-100'>
                        <tbody>
                            {modal.data.retail_check_count > 0 && <tr><td>Retail Check</td><td>{modal.data.retail_check_count}</td></tr>}
                            {Object.keys(modal.data.resource_type_count).map((title, index) => (
                                <tr key={parameterize(`${index}-${title}`)}><td>{title}</td><td>{modal.data.resource_type_count[title]}</td></tr>
                            ))}
                        </tbody>
                    </table>
                </>}

                <div className='category'>SALES</div>
                <table className='w-100'>
                    <tbody>
                        <tr><td>Reservation Sales</td><td>{numberToCurrency(modal.data.reservation_sales / 100.0)}</td></tr>
                        <tr><td>POS Sales</td><td>{numberToCurrency(modal.data.pos_total_cents / 100.0)}</td></tr>
                        <tr className='border-bottom'><td>Auto-Gratuity</td><td>{numberToCurrency(modal.data.auto_gratuity_total_cents / 100.0)}</td></tr>
                        <tr><td>Reservation Discounts</td><td>{numberToCurrency(modal.data.reservation_discounts / 100.0 * -1)}</td></tr>
                        <tr><td>POS Discounts</td><td>{numberToCurrency(modal.data.pos_discounts / 100.0 * -1)}</td></tr>
                        <tr className='border-bottom'><td>POS Comps</td><td>{numberToCurrency(modal.data.comp_total_cents / 100.0 * -1)}</td></tr>
                        <tr><td>Tax</td><td>{numberToCurrency(modal.data.tax_total_cents / 100.0)}</td></tr>
                        <tr><td>Reservation Fees</td><td>{numberToCurrency(modal.data.fee_total_cents / 100.0)}</td></tr>
                        <tr><td>Cash Tips</td><td>{numberToCurrency(modal.data.cash_tip_cents / 100.0)}</td></tr>
                        <tr><td>Non-Cash Tips</td><td>{numberToCurrency(modal.data.non_cash_tip_cents / 100.0)}</td></tr>
                        <tr className='border-bottom'><td>Refund by Amount</td><td>{numberToCurrency(modal.data.refund_total_cents / 100.0 * -1)}</td></tr>
                        <tr className='bold'><td>Total Collected</td><td>{numberToCurrency(modal.data.payment_total_cents / 100.0)}</td></tr>
                        <tr className='bold'><td>Total Collected Today</td><td>{numberToCurrency(modal.data.payment_total_cents_today / 100.0)}</td></tr>
                    </tbody>
                </table>

                <div className='category'>PAYMENT TYPES</div>
                <table className='w-100'>
                    <tbody>
                        <tr><td>Card x {modal.data.payment_card_count}</td><td>{numberToCurrency(modal.data.payment_card_total_cents / 100.0)}</td></tr>
                        <tr><td>Cash x {modal.data.payment_cash_count}</td><td>{numberToCurrency(modal.data.payment_cash_total_cents / 100.0)}</td></tr>
                        <tr><td>Gift Card Red. x {modal.data.payment_gift_card_count}</td><td>{numberToCurrency(modal.data.payment_gift_card_total_cents / 100.0)}</td></tr>
                        <tr className='border-bottom'><td>Check x {modal.data.payment_check_count}</td><td>{numberToCurrency(modal.data.payment_check_total_cents / 100.0)}</td></tr>
                        <tr className='bold'><td>Total Collected</td><td>{numberToCurrency(modal.data.payment_total_cents / 100.0)}</td></tr>
                        <tr className='bold'><td>Total Collected Today</td><td>{numberToCurrency(modal.data.payment_total_cents_today / 100.0)}</td></tr>
                    </tbody>
                </table>

                <div className='category'>POS ITEM TYPES SALES</div>
                <table className='w-100'>
                    <tbody>
                        {Object.keys(modal.data.sales_by_item_type).map((itemTypeName, index) => (
                            <tr key={index} className={(index + 1) === Object.keys(modal.data.sales_by_item_type).length ? 'border-bottom' : ''}>
                                <td>
                                    {itemTypeName} x {modal.data.sales_by_item_type[itemTypeName].quantity}
                                </td>
                                <td>{numberToCurrency(modal.data.sales_by_item_type[itemTypeName].price_cents / 100.0)}</td>
                            </tr>
                        ))}
                        <tr><td className='bold'>Pre-Tax Item Types Sales</td><td>{numberToCurrency(modal.data.pos_total_cents / 100.0)}</td></tr>
                    </tbody>
                </table>

                <div className='category'>POS ITEM CATEGORY SALES</div>
                <table className='w-100'>
                    <tbody>
                        {Object.keys(modal.data.sales_by_item_category).map((categoryName, index) => (
                            <tr key={index} className={(index + 1) === Object.keys(modal.data.sales_by_item_category).length ? 'border-bottom' : ''}>
                                <td>
                                    {categoryName} x {modal.data.sales_by_item_category[categoryName].quantity}
                                </td>
                                <td>{numberToCurrency(modal.data.sales_by_item_category[categoryName].price_cents / 100.0)}</td>
                            </tr>
                        ))}
                        <tr><td className='bold'>Pre-Tax Item Category Sales</td><td>{numberToCurrency(modal.data.pos_total_cents / 100.0)}</td></tr>
                    </tbody>
                </table>

                <div className='category'>DISCOUNTS & COMPS</div>
                <table className='w-100'>
                    <tbody>
                        <tr><td>Reservation Discounts</td><td>{numberToCurrency(modal.data.reservation_discounts / 100.0 * -1)}</td></tr>

                        <tr className={Object.keys(modal.data.comps_by_reason).length > 0 ? '' : 'border-bottom'}>
                            <td>POS Discounts</td><td>{numberToCurrency(modal.data.pos_discounts / 100.0 * -1)}</td>
                        </tr>

                        {Object.keys(modal.data.comps_by_reason).map((compReasonName, index) => (
                            <tr key={index} className={(index + 1) === Object.keys(modal.data.comps_by_reason).length ? 'border-bottom' : ''}>
                                <td>{compReasonName} x {modal.data.comps_by_reason[compReasonName].quantity}</td>
                                <td>{numberToCurrency(modal.data.comps_by_reason[compReasonName].price_cents / 100.0 * -1)}</td>
                            </tr>
                        ))}

                        <tr className='bold'><td>Total Discounts</td><td>{numberToCurrency((modal.data.reservation_discounts + modal.data.pos_discounts + modal.data.comp_total_cents) / 100.0 * -1)}</td></tr>
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}
