import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { setInputLocked } from "@/features/Calendar/calendarSlice"
import { addAlert } from '@/features/Notifications/notificationSlice'

import {
    clearAdyenState,
    setProcessing as setAdyenProcessing,
    cancelTerminalTransaction as cancelAdyenTransaction 
} from '@/features/Adyen/adyenSlice'

import {
    setProcessing as setPaysafeProcessing,
    cancelTransaction as cancelPaysafeTransaction
} from '@/features/Terminal/terminalSlice'

import { PREVENT_LOADER, ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'
import { SecureHash } from '@/lib/Crypto'

const defaultState = {
    open: false,
    loading: false,
    tab: 'credit',
    amountType: 'remaining',
    paymentType: 'credit_card',
    waiver: null,
    name: null,
    email: null,
    sendReceipt: true,
    cardOnFile: null,
    cardNumber: null,
    cardMonth: null,
    cardYear: null,
    cardCvv: null,
    cardZip: null,
    cashAmount: null,
    cashChangeDue: null,
    checkNumber: null,
    checkAmount: null,
    giftCardNumber: null,
    giftCards: [],
    giftCardBalance: null,
    customAmount: null,
    tipType: null,
    tipAmount: null,
    creditToken: null,
    customerTypeCounts: {},
    depositToApply: null,
    customersToPayFor: 0,
    creditMethod: null,
    amountToPay: 0,
    autoGratuityAmount: 0,
    isValidAmount: null
}

export const bookingPaymentSlice = createSlice({
    name: 'bookingPayment',
    initialState: defaultState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setAmountType: (state, action) => {
            state.amountType         = action.payload
            state.customerTypeCounts = {}
            state.customersToPayFor  = 0
            state.depositToApply     = null
        },
        setPaymentType: (state, action) => {
            state.paymentType = action.payload
        },
        setWaiver: (state, action) => {
            state.waiver = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setSendReceipt: (state, action) => {
            state.sendReceipt = action.payload
        },
        setCardOnFile: (state, action) => {
            state.cardOnFile = action.payload
        },
        setCardNumber: (state, action) => {
            state.cardNumber = action.payload
        },
        setCardMonth: (state, action) => {
            state.cardMonth = action.payload
        },
        setCardYear: (state, action) => {
            state.cardYear = action.payload
        },
        setCardCvv: (state, action) => {
            state.cardCvv = action.payload
        },
        setCardZip: (state, action) => {
            state.cardZip = action.payload
        },
        setCashAmount: (state, action) => {
            state.cashAmount = action.payload
        },
        setCashChangeDue: (state, action) => {
            state.cashChangeDue = action.payload
        },
        setCheckNumber: (state, action) => {
            state.checkNumber = action.payload
        },
        setCheckAmount: (state, action) => {
            state.checkAmount = action.payload
        },
        setGiftCardNumber: (state, action) => {
            state.giftCardNumber = action.payload
        },
        setGiftCardBalance: (state, action) => {
            state.giftCardBalance = action.payload
        },
        setCustomAmount: (state, action) => {
            state.customAmount = action.payload
        },
        clearForm: () => {
            // completely reset the form state
            return defaultState
        },
        setTipType: (state, action) => {
            state.tipType = action.payload
        },
        setTipAmount: (state, action) => {
            state.tipAmount = action.payload
        },
        setCustomerTypeCounts: (state, action) => {
            state.customerTypeCounts = action.payload
        },
        setDepositToApply: (state, action) => {
            state.depositToApply = action.payload
        },
        setCustomersToPayFor: (state, action) => {
            state.customersToPayFor = action.payload
        },
        setCreditToken: (state, action) => {
            state.creditToken = action.payload
        },
        setCreditMethod: (state, action) => {
            state.creditToken = null
            state.cardZip = null
            state.creditMethod = action.payload
        },
        switchToCustom: (state, action) => {
            state.customerTypeCounts = {}
            state.customersToPayFor  = 0
            state.amountType         = 'custom'
            state.customAmount       = action.payload
        },
        setAutoGratuityAmount: (state, action) => {
            state.autoGratuityAmount = action.payload
        },
        setAmountToPay: (state, action) => {
            state.amountToPay = action.payload
        },
        setIsValidAmount: (state, action) => {
            state.isValidAmount = action.payload
        },
        setGiftCards: (state, action) => {
            state.giftCards = action.payload
        },
        setCustomerTypeCounts: (state, action) => {
            state.customerTypeCounts = action.payload
        }
    }
})

export const {
    setOpen,
    setLoading,
    setTab,
    setAmountType,
    setPaymentType,
    setWaiver,
    setName,
    setEmail,
    setSendReceipt,
    setCardOnFile,
    setCardNumber,
    setCardMonth,
    setCardYear,
    setCardCvv,
    setCardZip,
    setCashAmount,
    setCashChangeDue,
    setCheckNumber,
    setCheckAmount,
    setGiftCardNumber,
    setCustomAmount,
    clearForm,
    setTipType,
    setTipAmount,
    setGiftCardBalance,
    setCustomerTypeCounts,
    setDepositToApply,
    setCustomersToPayFor,
    setCreditToken,
    setCreditMethod,
    switchToCustom,
    setAutoGratuityAmount,
    setAmountToPay,
    setIsValidAmount,
    setGiftCards
} = bookingPaymentSlice.actions

export const selectOpen               = state => state.bookingPayment.open
export const selectLoading            = state => state.bookingPayment.loading
export const selectTab                = state => state.bookingPayment.tab
export const selectAmountType         = state => state.bookingPayment.amountType
export const selectPaymentType        = state => state.bookingPayment.paymentType
export const selectWaiver             = state => state.bookingPayment.waiver
export const selectName               = state => state.bookingPayment.name
export const selectEmail              = state => state.bookingPayment.email
export const selectSendReceipt        = state => state.bookingPayment.sendReceipt
export const selectCardOnFile         = state => state.bookingPayment.cardOnFile
export const selectCardNumber         = state => state.bookingPayment.cardNumber
export const selectCardMonth          = state => state.bookingPayment.cardMonth
export const selectCardYear           = state => state.bookingPayment.cardYear
export const selectCardCvv            = state => state.bookingPayment.cardCvv
export const selectCardZip            = state => state.bookingPayment.cardZip
export const selectCashAmount         = state => state.bookingPayment.cashAmount
export const selectCashChangeDue      = state => state.bookingPayment.cashChangeDue
export const selectCheckNumber        = state => state.bookingPayment.checkNumber
export const selectCheckAmount        = state => state.bookingPayment.checkAmount
export const selectGiftCardNumber     = state => state.bookingPayment.giftCardNumber
export const selectCustomAmount       = state => state.bookingPayment.customAmount
export const selectTipType            = state => state.bookingPayment.tipType
export const selectTipAmount          = state => state.bookingPayment.tipAmount
export const selectGiftCardBalance    = state => state.bookingPayment.giftCardBalance
export const selectCustomerTypeCounts = state => state.bookingPayment.customerTypeCounts
export const selectDepositToApply     = state => state.bookingPayment.depositToApply
export const selectCustomersToPayFor  = state => state.bookingPayment.customersToPayFor
export const selectCreditToken        = state => state.bookingPayment.creditToken
export const selectCreditMethod       = state => state.bookingPayment.creditMethod
export const selectAmountToPay        = state => state.bookingPayment.amountToPay
export const selectAutoGratuityAmount = state => state.bookingPayment.autoGratuityAmount
export const selectIsValidAmount      = state => state.bookingPayment.isValidAmount

export function submitPayment(bookingId, creditToken, paymentResponse, partialAmountCharged, adyenTerminal) {
    return async (dispatch, getState) => {

        const paymentProcessor = getState().location.paymentProcessor
        const token            = getState().session.formToken
        const payment          = getState().bookingPayment
        const paymentType      = getState().bookingPayment.paymentType
        const adyenPayment     = getState().adyen.adyenPayment

        window.axiosTransactionSource = axios.CancelToken.source()

        // display an invisible layer that prevents all clicks above
        // the entire calendar to prevent any changes/moves to the booking
        // while the moving of the booking is "in-flight"
        dispatch(setInputLocked(true))

        // temporarily disable the usual loading animation and immediately
        // display the one specific to finalizing a booking instead
        window.sessionStorage.setItem(PREVENT_LOADER, true)
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))
        dispatch(setLoading(true))

        if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
            if (paymentProcessor === 'adyen')   { dispatch(setAdyenProcessing(true))   }
            if (paymentProcessor === 'paysafe') { dispatch(setPaysafeProcessing(true)) }
        }

        // here if we have a partial amount charged, it's essentially the same as a custom payment...
        // but can we overwrite the custom_amount field and the amount_type, or do we have to make
        // new ones? we also need to set the is_partial_payment flag

        axios.request({
            url: `/bookings/${bookingId}/payments`,
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token:   token,
                // amount_type:          payment.amountType,
                amount_type:          !!partialAmountCharged ? 'custom' : payment.amountType,
                payment_type:         payment.paymentType,
                waiver:               payment.waiver,
                name:                 payment.name,
                email:                payment.email,
                send_receipt:         payment.sendReceipt,
                card_on_file:         payment.cardOnFile,
                card_number:          payment.cardNumber,
                card_month:           payment.cardMonth,
                card_year:            payment.cardYear,
                card_cvv:             payment.cardCvv,
                card_zip:             payment.cardZip,
                cash_amount:          payment.cashAmount,
                check_number:         payment.checkNumber,
                check_amount:         payment.checkAmount,
                gift_card_number:     payment.giftCardNumber,
                // custom_amount:        payment.customAmount,
                custom_amount:        !!partialAmountCharged ? partialAmountCharged : payment.customAmount,
                tip_type:             payment.tipType,
                tip_amount:           payment.tipAmount,
                auto_gratuity_amount: payment.autoGratuityAmount,
                customer_type_counts: payment.customerTypeCounts,
                deposit_to_apply:     payment.depositToApply,
                customers_to_pay_for: payment.customersToPayFor,
                credit_token:         creditToken || payment.creditToken,
                credit_method:        payment.creditMethod,
                payment_response:     paymentResponse,
                is_partial_payment:   !!partialAmountCharged,
                adyen_payment:        adyenPayment,
                terminal_id:          adyenTerminal,
                sale_id:              window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                service_id:           window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
            },
            headers: {
                timeout: 30 // 30 seconds, 0 indicates no timeout
            }
        }).then(({ data }) => {
            if (data.success && !data.error) {
                dispatch(addAlert({ type: 'success', text: data?.message?.toUpperCase() || 'TRANSACTION SUCCESSFUL' }))
                dispatch(clearForm())
                dispatch(clearAdyenState())
                return
            }

            if (data.success && data.error) {
                dispatch(addAlert({ type: 'warning', text: data.message.toUpperCase() }))
                return
            }

            dispatch(addAlert({ type: 'error', text: data.message.toUpperCase() }))
        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log(e.message.toUpperCase())
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'TRANSACTION TIMED OUT' }))

                    if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
                        if (paymentProcessor === 'adyen')   { dispatch(cancelAdyenTransaction())   }
                        if (paymentProcessor === 'paysafe') { dispatch(cancelPaysafeTransaction()) }
                    }
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource

            if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
                if (paymentProcessor === 'adyen')   { dispatch(setAdyenProcessing(false))   }
                if (paymentProcessor === 'paysafe') { dispatch(setPaysafeProcessing(false)) }
            }

            dispatch(setInputLocked(false))
            dispatch(setLoading(false))
        })
    }
}

export function submitMultipleMethodPayment(bookingId, creditToken, creditZip) {
    return async (dispatch, getState) => {

        const token        = getState().session.formToken
        const payment      = getState().bookingPayment
        const adyenPayment = getState().adyen.adyenPayment

        return axios.post(`/bookings/${bookingId}/payments/create_multiple_methods_payment`, {
            authenticity_token:   token,
            amount_type:          payment.amountType,
            email:                payment.email,
            send_receipt:         payment.sendReceipt,
            customer_type_counts: payment.customerTypeCounts,
            customers_to_pay_for: payment.customersToPayFor,
            gift_cards:           payment.giftCards,
            credit_token:         creditToken,
            credit_zip:           creditZip,
            custom_amount:        payment.customAmount,
            adyen_payment:        adyenPayment,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(clearForm())
                dispatch(clearAdyenState())
                return data
            }

            dispatch(addAlert({ type: 'error', text: data.message }))
        }).catch((e) => {
            if (console) { console.warn(e) }
            dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'Payment Failed!' }))
        })
    }
}

export function checkGiftCardBalance() {
    return async (dispatch, getState) => {
        const number = getState().bookingPayment.giftCardNumber
        const token = getState().session.formToken

        if (!number) {
            dispatch(addAlert({ type: 'warning', text: 'Please enter a gift card number.' }))
            return
        }

        axios.post(`/gift-cards/${number}/balance`, {
            authenticity_token: token,
            card_number: number
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setGiftCardBalance(data.balance))

                const amountToPay = getState().bookingPayment.amountToPay

                dispatch(setIsValidAmount(amountToPay <= data.balance/100))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export default bookingPaymentSlice.reducer
