import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from "../../../Locations/locationSlice";
import { selectBooking, selectNumberOfResources } from '../../bookingSlice'
import { selectResourceType } from "../../../ResourceType/resourceTypeSlice";
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export default function Confirmation() {

    const dispatch            = useDispatch()
    const location            = useSelector(selectLocation)
    const booking             = useSelector(selectBooking)
    const resourceType        = useSelector(selectResourceType)
    const numberOfResources   = useSelector(selectNumberOfResources)
    const [copied, setCopied] = useState(false)

    const ifYouNeedToMakeChangesVerbiage = <>
        If you need to make a change to your reservation, please call us at <a href={`tel:${location.contact_phone}`}>{location.contact_phone}</a>
        &nbsp;or email us at <a href={`mailto:${location.contact_email}`}>{location.contact_email}</a>
    </>

    const waiverUrl = `${window.location.origin}/companies/${location.company_id}/locations/${location.id}/waivers/${booking.reservationNumber}`

    const handleCopyLink = (e) => {
        e.preventDefault()

        try {
            navigator.clipboard.writeText(waiverUrl).then(() => {
                setCopied(true)
                dispatch(addAlert({ type: 'success', text: 'Link Copied!' }))
            }, (error) => {
                handleUnableToCopyLink(error)
            })
        } catch(error) {
            handleUnableToCopyLink(error)
        }
    }

    const handleUnableToCopyLink = (error) => {
        if (error && debug && console) { console.error(error) }
        setCopied(false)
        dispatch(addAlert({
            type: 'warning',
            text: 'Could not copy the link.<br /><br />Please right click on the button and select "copy" instead.'
        }))
    }

    return (
        <div className="xbm-step-content xbm-confirmation">

            <div className="xbm-step-content-main">
                {
                    booking.reservationNumber &&
                        <div className="xbm-step-counter">Reservation Number</div>
                }

                <div className="xbm-step-question">
                    {
                        booking.reservationNumber &&
                            <h3 className="mb-4 text-center">
                                {booking.reservationNumber}
                            </h3>
                    }
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10 text-center">
                                <p><strong>Thank you for choosing<br />{location.name}!</strong></p>
                                {booking.reservationNumber &&
                                <p>
                                    <a
                                        href={`/bookings/${booking.reservationNumber}/icalendar`}
                                        target="_blank">
                                        <i className="far fa-calendar-alt mr-1"/>
                                        Add to calendar
                                    </a>
                                </p>
                                }
                                {
                                    numberOfResources !== null && <p>
                                        This reservation is for {numberOfResources}
                                        &nbsp;
                                        {numberOfResources === 1 ? resourceType.singular_name : resourceType.plural_name}
                                    </p>
                                }
                                <p>
                                    A confirmation email has been sent to <strong>{booking.email}</strong> with all of the reservation details.
                                    Please keep this information for your records and have your reservation number ready at check-in.
                                </p>

                                {
                                    !resourceType?.waivers_required && (
                                        <p>{ ifYouNeedToMakeChangesVerbiage }</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                resourceType?.waivers_required && (
                    <div className="xbm-running-ledger" style={{ padding: "1em" }}>
                        <h2 className="mb-4">Next Steps</h2>
                        <ol>
                            <li>Fill out your waiver by clicking on the button below. All attendees must sign their own waiver.</li>
                            <li>{ ifYouNeedToMakeChangesVerbiage }</li>
                        </ol>
                        <div className="row mt-4 mt-md-5 mx-0 mx-md-auto">
                            <div className="col-sm-6 mb-3">
                                <a href={waiverUrl} target="_blank" rel="noopener" className="btn btn-outline btn-block">
                                    <i className="fas fa-file-signature mr-2" /> Sign My Waiver
                                </a>
                            </div>
                            <div className="col-sm-6 mb-3">
                                <a className="btn btn-outline btn-block" href={waiverUrl} onClick={handleCopyLink}>
                                    {
                                        copied
                                            ? <><i className="fas fa-check-circle mr-2" /> Copied!</>
                                            : <><i className="fas fa-copy mr-2" /> Copy Waiver Link</>
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
