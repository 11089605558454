import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    closeModal,
    selectModals,
    sendReceipt,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import { validationPatterns } from "@/lib/Errors"

export default function EmailReceiptModal() {

    const dispatch                  = useDispatch()
    const { emailReceipt: modal } = useSelector(selectModals)

    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)

    useEffect(() => {
        if (modal.tab?.email) {
            setEmail(modal.tab.email)
        }
    }, [modal.tab])

    const handleClose = () => {
        setEmail('')
        setError(null)
        dispatch(closeModal('emailReceipt'))
    }

    const handleSend = () => {
        if (!validationPatterns.email.value.test(email)) {
            setError('Invalid email address')
            return
        }

        dispatch(sendReceipt(email)).then((data) => {
            if (data.success) {
                handleClose()
            }
        })
    }

    const footerButtons = (
        <div className='d-flex justify-content-between footer-button-horizontal w-100'>
            <button
                children="Cancel"
                className="btn btn-outline-secondary text-white"
                onClick={handleClose}
            />

            <button
                children="Send"
                className="btn btn-primary"
                disabled={!email}
                onClick={handleSend}
            />
        </div>
    )

    return modal.isOpen && (
        <Modal
            title='Email Receipt'
            className='email-receipt-modal'
            size={modal.size}
            isOpen={modal.isOpen}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <div className="m-4">
                <input
                    className='form-control'
                    placeholder='Email...'
                    name='email'
                    type='email'
                    autoComplete='off'
                    value={email}
                    onChange={e => setEmail(e.target.value)} />

                <div className="invalid-feedback d-block">
                    {error}
                </div>
            </div>
        </Modal>
    )
}
