import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    fetchBookingPriceBreakdown,
    selectCurrentCheck,
    setCurrentComponent,
    splitReservationPayment,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { addAlert } from '@/features/Notifications/notificationSlice'
import ButtonGroup from '@/features/AdvancedPointOfSale/components/buttons/ButtonGroup'
import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import UniformOrCustomerTypePricing from '@/features/AdvancedPointOfSale/components/reservation-splitting/UniformOrCustomerTypePricing'
import GroupPricing from '@/features/AdvancedPointOfSale/components/reservation-splitting/GroupPricing'
import { addBookingTabToGeneralTab, checkNameForBreadcrumbs } from '@/features/AdvancedPointOfSale/lib/Checks'
import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'

export default function ReservationSplitting({
    self='ReservationSplitting',
    onChange=() => {},
}) {
    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const check       = useSelector(selectCurrentCheck)
    const booking     = check?.booking
    const tabs        = addBookingTabToGeneralTab(check.tabs)
    const isGroup     = /group/i.test(booking.package_pricing_type)

    const [loaded, setLoaded]                                               = useState(false)
    const [totalCentsRemainingToAllocate, setTotalCentsRemainingToAllocate] = useState(null)
    const [prices, setPrices]                                               = useState(null)
    const [splitConfig, setSplitConfig]                                     = useState(null)
    const [changesMade, setChangesMade]                                     = useState(false)
    const [canSave, setCanSave]                                             = useState(false)
    const [resets, setResets]                                               = useState(0)

    const handleChangesMade = (state) => {
        // mirrored into Window state so that the breadcrumbs can check against it
        window.__reservationSplitChanged = state

        setChangesMade(state)
    }

    const handleGoBack = async () => {
        if (changesMade) {
            if (await confirm('All changes will be lost!', { header_text: 'Are you sure?'})) {
                dispatch(setCurrentComponent('ManageCheck'))
            }
        } else {
            dispatch(setCurrentComponent('ManageCheck'))
        }
    }

    const handleResetAll = async () => {
        if (changesMade) {
            if (await confirm('All changes will be lost!', { header_text: 'Are you sure?'})) {
                setResets(resets + 1)
            }
        } else {
            setResets(resets + 1)
        }
    }

    const handleSave = () => {
        dispatch(splitReservationPayment(check.id, splitConfig))
        .then(({ success }) => {
            if (!success) { return }
            dispatch(setCurrentComponent('ManageCheck'))
        })
    }

    useEffect(() => {
        if (!check || !booking) { return }

        onChange(
            <Breadcrumbs
                id='advanced-pos--terminal-navigation--breadcrumbs'
                data={[
                    { id: 1, name: checkNameForBreadcrumbs(check, (booking?.resource_name || '')), type: 'check' },
                    { id: 2, name: 'Manage Check', type: null },
                    { id: 3, name: 'Manage Payment Split', type: null },
                ]}
                onClick={async (breadcrumb) => {
                    if (window.__reservationSplitChanged) {
                        if (await confirm('All changes will be lost!', { header_text: 'Are you sure?'})) {
                            if (breadcrumb.id === 1) {
                                dispatch(setCurrentComponent('DefaultMenu'))
                            }
                            if (breadcrumb.id === 2) {
                                handleGoBack()
                            }
                        }
                    } else {
                        if (breadcrumb.id === 1) {
                            dispatch(setCurrentComponent('DefaultMenu'))
                        }
                        if (breadcrumb.id === 2) {
                            handleGoBack()
                        }
                    }
                }}
            />
        )

        if (!prices && !splitConfig) {
            dispatch(fetchBookingPriceBreakdown(booking.id, {
                include_payments: false,
                include_price_summary: false,
                include_addon_times: false,
            }))
            .then(({ payload }) => {
                setSplitConfig(check.booking_split_config)
                setPrices(payload.pricing)
            })
        }

        setLoaded(true)

        return () => {
            delete window.__reservationSplitChanged
        }
    }, [])

    useEffect(() => {
        if (!check || !loaded) { return }

        if (check.has_adv_pos_booking_payment_been_received) {
            dispatch(setCurrentComponent('ManageCheck'))
            dispatch(addAlert({ type: 'error', text: 'Payment splitting is no longer allowed once payments have been made towards the booking.' }))
        }

        if (!check.is_booking_split) {
            dispatch(setCurrentComponent('ManageCheck'))
            dispatch(addAlert({ type: 'error', text: 'Payment splitting has been reset since participant changes have been made towards the booking.' }))
        }
    }, [check.has_adv_pos_booking_payment_been_received, check.is_booking_split])

    return !!check && !!booking && !!prices && (
        <div id={`advanced-pos-terminal--${parameterize(self)}`} className='pt-3 pb-2'>
            <h1
                id={`advanced-pos-terminal--${parameterize(self)}--header`}
                className='d-flex justify-content-between align-items-center border-bottom mx-4 pb-2 mb-0 font-weight-lighter'
            >
                <span className='pr-3'>
                    {booking.package.name}
                </span>

                <div className='price d-flex align-items-end justify-content-end'>
                    {
                        totalCentsRemainingToAllocate !== null && (
                            <span
                                className={`${totalCentsRemainingToAllocate < 0 ? 'text-warning fa-fade' : (totalCentsRemainingToAllocate == 0 ? 'text-success' : 'text-gray4')} font-italic font-weight-lighter pr-3`}
                                style={{ fontSize: '65%' }}
                            >
                                {numberToCurrency((totalCentsRemainingToAllocate || 0) / 100)}
                            </span>
                        )
                    }
                    <span>{numberToCurrency(booking.price_cents_without_addon_times / 100)}</span>
                </div>
            </h1>

            {
                isGroup ? (
                    <GroupPricing
                        id={`advanced-pos-terminal--${parameterize(self)}--content`}
                        tabs={tabs}
                        prices={prices}
                        existingSplitConfig={splitConfig}
                        shouldTriggerReset={resets}
                        changesMade={changesMade}
                        onChangesMade={handleChangesMade}
                        onChange={(newSplitConfig, totalCentsRemainingToAllocate) => {
                            setSplitConfig(newSplitConfig)
                            setTotalCentsRemainingToAllocate(totalCentsRemainingToAllocate)
                        }}
                        onValidate={(result) => setCanSave(result)}
                    />
                ) : (
                    <UniformOrCustomerTypePricing
                        id={`advanced-pos-terminal--${parameterize(self)}--content`}
                        tabs={tabs}
                        prices={prices}
                        existingSplitConfig={splitConfig}
                        shouldTriggerReset={resets}
                        onChangesMade={handleChangesMade}
                        onChange={(newSplitConfig) => setSplitConfig(newSplitConfig)}
                        onValidate={(result) => setCanSave(result)}
                    />
                )
            }

            <div
                id={`advanced-pos-terminal--${parameterize(self)}--footer`}
                className='d-flex flex-row justify-items-between px-4'
            >
                <button
                    type='button'
                    children={<><i className='fa-regular fa-chevron-left ml-n2 mr-2' />Back</>}
                    className='btn btn-link mr-auto text-uppercase my-2'
                    onClick={handleGoBack}
                />

                <ButtonGroup className='theme--link-outline-solid py-2'>
                    <button
                        children='Reset All'
                        type='button'
                        disabled={!changesMade}
                        onClick={handleResetAll}
                    />
                    <button
                        children='Save'
                        type='button'
                        disabled={splitConfig === null || !changesMade || !canSave}
                        onClick={handleSave}
                    />
                </ButtonGroup>
            </div>
        </div>
    )
}
