import React, {useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid'
import BookingModalFooter from '../BookingModalFooter'
import { PACKAGE_GROUP_STEP0 } from '@/lib/Storage'

export default function Step0({
  modalStyles,
  packageGroup,
  packages
}) {
    const handleClick = () => {
      window.sessionStorage.setItem(PACKAGE_GROUP_STEP0, window.location.href)
    }

    // remove any step 0 url previously
    // saved by another package group
    useEffect(() => {
      window.sessionStorage.removeItem(PACKAGE_GROUP_STEP0)
    }, [])

    return <>
        <div className="xbm-header">
          <h1>{packageGroup.name}</h1>
        </div>

        <div className="xbm-step-content">
          <div className="xbm-step-content-main package-groups">
            <h3>Select a package</h3>
            <div className="container">
              <div className="row">
                {
                  packages.map((p) => {
                    const packageUrl = window.location.href.split('package_groups')[0] + `packages/${p.url_hash}/bookings/new${window.location.search}`

                    return (
                      <div className="col-sm-6 col-md-4 d-flex" key={uuidv4()}>
                        <div className="package-group-option shadow py-4 px-5 w-100 d-flex flex-column">
                          <div className="flex-1 pb-2">
                            <h5>{p.display_name}</h5>
                            <div className="mb-3 mb-sm-0">{p.description}</div>
                          </div>
                          <div className='p-sm-3 flex-0'>
                            <a
                              children='SELECT PACKAGE'
                              type="button"
                              className="btn btn-primary"
                              href={packageUrl}
                              onClick={handleClick}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <BookingModalFooter modalStyles={modalStyles} step0 />
    </>
}
