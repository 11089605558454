import React from 'react'
import { useSelector } from 'react-redux'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import { numberToCurrency } from '@/lib/Number'
import { debug } from '@/lib/Debug'

export default function BookingTotals({
    newBooking=false,
    quoteMode=false,
    paymentMode=false,
    basePrice,
    taxesAndFees,
    depositToApply,
    autoGratuity,
    tip,
    remainingBalance,
    reservationTotal
}) {

    const pakage  = useSelector(selectPackage)
    const booking = useSelector(selectBooking)

    const includesTaxes = (
        !booking?.is_tax_exempt
        && (
            (booking?.legacy_tax_rate_and_fee_data?.reservation_tax_rate || 0)
                ||
            (pakage?.reservation_tax_rate || 0)
        ) > 0
    )

    return (
        <div className="drawer-booking-ledger-section drawer-booking-ledger-section-totals">
            <table>
                <tbody>
                    <tr>
                        <th>Subtotal</th>
                        <td>{numberToCurrency(Number.parseFloat(basePrice))}</td>
                    </tr>
                    <tr>
                        <th>{ debug && !includesTaxes ? 'Fees' : 'Taxes & Fees' }</th>
                        <td>{numberToCurrency(taxesAndFees)}</td>
                    </tr>
                    {
                        !!depositToApply && (
                            <tr>
                                <th>Deposit to Apply</th>
                                <td>{numberToCurrency(depositToApply)}</td>
                            </tr>
                        )
                    }
                    {
                        autoGratuity > 0 && (
                            <tr>
                                <th>Gratuity</th>
                                <td>{numberToCurrency(autoGratuity)}</td>
                            </tr>
                        )
                    }
                    {
                        !!tip && (
                            <tr>
                                <th>Tip</th>
                                <td>{numberToCurrency(tip)}</td>
                            </tr>
                        )
                    }
                    {
                        !newBooking && !paymentMode && !quoteMode && (
                            <tr>
                                <th>Reservation Total</th>
                                <td>{numberToCurrency(!booking || isNaN(reservationTotal) ? remainingBalance : reservationTotal)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
