import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '@/lib/Constants'
import { selectOpenServerSelectModal, setOpenServerSelectModal, selectBooking } from '@/features/EditBooking/editBookingSlice'
import { fetchUsers, createCheck } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export default function ServerSelectModal() {

    const dispatch   = useDispatch()
    const open       = useSelector(selectOpenServerSelectModal)
    const booking    = useSelector(selectBooking)
    const companyId  = booking?.company_id
    const locationId = booking?.location_id

    const [users, setUsers]                   = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null)

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape' &&
                !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN) &&
                !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            dispatch(setOpenServerSelectModal(false))
        }
    }

    const handleCreateCheck = () => {
        dispatch(createCheck({
            companyId: companyId,
            locationId: locationId,
            check: {
                booking_id: booking.id,
                server_id: selectedUserId,
            },
            closeModals: false,
            loadDefaultMenuOnSuccess: false,
            successMessage: 'Sent to Point of Sale',
            deviceFingerPrint: {
                location_id: locationId,
                name: 'System',
                slug: 'system'
            }
        }))
        .then(({ success }) => {
            if (success) {
                dispatch(setOpenServerSelectModal(false))
                return
            }
        })
        .catch((error) => {
            if (debug && console) { console.warn(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.error || error || 'Unable to create POS check' }))
        })
    }

    // Handle the initial mount and unmount of the component
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (open) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    useEffect(() => {
        if (open && !!booking) {
            dispatch(fetchUsers(companyId, locationId)).then((data) => {
                setUsers(data)
                setSelectedUserId(data[0]?.id)
            })
        }
    }, [open, booking])

    return open && (
        <div className="modal modal-backdrop modal--xs">
            <div className="modal-container">
                <div className="modal-header pb-2">
                    <h3>Select a Server</h3>
                </div>
                <div className="modal-body p-4">
                    <div className="container px-1">
                        <select
                            children={users.map(({ id, name}) => <option key={id} value={id} children={name} />)}
                            className="custom-select"
                            onChange={(e) => setSelectedUserId(e.target.value)}
                        />

                        <hr className='my-4' />

                        <div className="alert alert-primary rounded" role="alert">
                            <h6 className='alert-heading'>Please Note!</h6>
                            <p>This will disable the ability to take further payments via the Reservation Payment modal.</p>
                            <p className='mb-0'>Once sent, payments towards this reservation will need to be made in the RKd Advanced POS.</p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        children='Cancel'
                        type="button"
                        className="btn btn-outline-primary mr-auto"
                        onClick={() => dispatch(setOpenServerSelectModal(false))}
                    />

                    <button
                        children='Send to POS'
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCreateCheck}
                    />
                </div>
            </div>
        </div>
    )
}
