import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    selectCurrentCheck,
    selectCheckoutMode,
    selectCheckoutTab,
    selectAmountTowardsBalance,
    selectBalanceRemainingCents,
    selectTipAmount,
    selectChangeDue,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import ButtonTabNav from '@/features/AdvancedPointOfSale/components/tabs/ButtonTabNav'
import AmountTowardsBalance from './AmountTowardsBalance'
import PayFullAmount from './PayFullAmount'
import TipAmount from './TipAmount'
import { isPaid, isOverPaid } from '@/features/AdvancedPointOfSale/lib/Checks'
import { numberToCurrency } from '@/lib/Number'
import scrollHinting from '@/lib/ScrollHinting'

/**
 * Wrapper around shared checkout components. The calculator, amount received, and tip amounts seem to be rendered
 * on every page of checkout. So, instead of repeating that code for each checkout payment type, this just wraps
 * all of that and renders children below the tip amount for whatever additional UI we need for each payment type.
 */
export default function PaymentForm({
    self,
    totalDue=null,
    hideTipField=false,
    checkNumberField=null,
    children,
    creditTabs=null,
    creditCurrentTab=null,
    onCreditTabChange=null,
}) {
    const check                 = useSelector(selectCurrentCheck)
    const tab                   = useSelector(selectCheckoutTab)
    const checkoutMode          = useSelector(selectCheckoutMode)
    const tipAmount             = useSelector(selectTipAmount)
    const amountTowardsBalance  = useSelector(selectAmountTowardsBalance)
    const changeDue             = useSelector(selectChangeDue)
    const balanceRemainingCents = useSelector(selectBalanceRemainingCents)

    const partialPaymentsAllowed = useMemo(() => (
        checkoutMode === 'single-tab'
        || (checkoutMode === 'full-check' && check?.tabs?.length <= 2) // general and/or booking tabs only
    ), [checkoutMode])

    useEffect(() => {
        scrollHinting.search()
    }, [])

    return <>
        {
            !!creditTabs && !!creditCurrentTab && !!onCreditTabChange && (
                <ButtonTabNav
                    id={`advanced-pos-terminal--${parameterize(self)}--payment-form--credit-method-nav`}
                    className='d-flex justify-content-between pb-2'
                    tabs={creditTabs}
                    currentTab={creditCurrentTab}
                    onChange={onCreditTabChange}
                />
            )
        }

        <div id={`advanced-pos-terminal--${parameterize(self)}--payment-form`} className='scrollhint'>
            <div className='scrollhint--inner container p-0' style={{ overflowX: 'hidden' }}>
                <PayFullAmount
                    enabled={
                        totalDue > 0
                        && partialPaymentsAllowed
                        && !isPaid(check)
                        && !isOverPaid(check)
                        && !isPaid(tab)
                        && !isOverPaid(tab)
                    }
                />

                <AmountTowardsBalance />
                <TipAmount enabled={!hideTipField} />
                { checkNumberField }

                { children }

                <hr className='border-thick border-color-gray4 mt-4 mb-3' />

                <table className='table-sm w-100'>
                    <colgroup>
                        <col width='90%' />
                        <col width='1%' />
                        <col width='9%' />
                    </colgroup>
                    <tbody>
                        {
                            (!!changeDue || changeDue === 0) && (
                                <tr>
                                    <td className='text-right text-bold'>Change Due</td>
                                    <td />
                                    <td
                                        className={`text-left text-bold ${changeDue < 0 ? 'text-red fa-fade' : 'text-success fa-fade'}`}
                                        children={numberToCurrency(changeDue)}
                                    />
                                </tr>
                            )
                        }
                        <tr>
                            <td className='text-right text-bold'>Amount to Process</td>
                            <td />
                            <td
                                className='text-left text-bold'
                                children={numberToCurrency(parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0))}
                            />
                        </tr>
                        {
                            (!!balanceRemainingCents || balanceRemainingCents === 0) && totalDue > 0 && (
                                <tr>
                                    <td className='text-right text-bold'>Balance After Payment</td>
                                    <td />
                                    <td
                                        className={`text-left text-bold ${balanceRemainingCents === 0 ? 'text-success' : 'text-warning fa-fade'}`}
                                        children={numberToCurrency(balanceRemainingCents / 100)}
                                    />
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
