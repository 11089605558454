import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import Checkbox from '@/components/Form/Checkbox'
import Select from '@/components/Form/Select'
import FormRow from '@/components/Form/FormRow'
import MomentTimePicker from '@/components/Form/MomentTimePicker'
import LocationHoursSpecialHours from "./LocationHoursSpecialHours"
import Helpjuice from '@/features/Helpjuice/Helpjuice'

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export default function LocationHoursForm({
  form,
  errors,
  timezones,
  newLocation,
  handleUpdate,
  handleManualUpdate,
  handleCheckboxUpdate,
}) {
  const [hoursTabLoaded, setHoursTabLoaded] = useState(false)

  useEffect(() => {
    if (!hoursTabLoaded) {
      setHoursTabLoaded(true)
    }
  }, [])

  //
  // note: MomentTimePicker can accept null or
  // Moment objects as values, just not strings
  //
  const value_for = (value, allowBlank=false) => {
    // supply the timepicker with null so that it renders
    // a blank/empty field since we don't have a value
    if (!value && allowBlank) { return null }

    // if this is a new location, we don't yet know the timezone so
    // default to Eastern so that we're at least on the right day.
    // we will be stripping out the timezone information before
    // saving it to the database anyway
    if (value !== null && newLocation && !allowBlank) {
      return moment(value).tz('UTC')
    }

    // the time picker supplies a default timezone of UTC
    // when it's already rendered a null value, so since
    // we have a string/chosen time, simply render it as
    // it's already in UTC as expected
    if (typeof value === 'string' && newLocation) {
      return moment(value)
    }

    // for existing locations, supply the timepicker with
    // a moment object in the location's preferred timezone
    if (typeof value === 'string' && !newLocation) {
      return moment(value).tz(form.time_zone)
    }

    // in all other case, supply the timepicker with
    // null so that it renders a blank/empty field
    if (console) { console.warn('LocationHours: this should never happen!') }
    return null
  }

  return !hoursTabLoaded ? '' : (
    <div>
      <FormRow>
        <Select
          name='time_zone'
          label='Location Time Zone'
          handleChange={handleUpdate}
          req={true}
          options={timezones}
          value={form.time_zone}
          errors={errors}
          validation={{ 'time_zone': { required: true } }}
        />
      </FormRow>

      {
        Boolean(form.time_zone) && <>
          <div>
            <h3>
              Standard Open Hours
              <Helpjuice articleId='location-hours' extraClasses='' />
            </h3>
            {
              days.map((day, index) => {
                const spacingClasses = index > 0 ? 'border-top mt-3 pt-4' : ''
                const bgClasses = form[`${day}_closed`] ? 'bg-light' : ''

                return (
                  <React.Fragment key={`day_${index}`}>
                    <FormRow className={`p-2 ${spacingClasses} ${bgClasses}`}>
                      <div className="col-3">
                        <h2 className="mt-4 text-capitalize">{day}</h2>
                      </div>

                      <MomentTimePicker
                        label='Opens At'
                        cols='4'
                        name={`${day}_open`}
                        value={value_for(form[`${day}_open`])}
                        tz={form.time_zone}
                        placeholder='XX:00 AM'
                        errors={errors}
                        validation={{ [`${day}_open`]: { required: true } }}
                        preventTyping={true}
                        handleUpdate={handleUpdate}
                      />

                      <div className="col-1" />

                      <MomentTimePicker
                        cols='4'
                        label='Peak Time Starts At'
                        name={`${day}_peak_start`}
                        value={value_for(form[`${day}_peak_start`], true)}
                        tz={form.time_zone}
                        placeholder='XX:00 AM'
                        preventTyping={true}
                        disabled={form[`${day}_closed`]}
                        handleUpdate={handleUpdate}
                      />

                      <Checkbox
                        cols='3 pt-4 mt-1'
                        name={`${day}_closed`}
                        value={form[`${day}_closed`]}
                        label='Closed?'
                        handleChange={handleCheckboxUpdate}
                      />

                      <MomentTimePicker
                        cols='4'
                        label='Closes At'
                        name={`${day}_close`}
                        value={value_for(form[`${day}_close`])}
                        tz={form.time_zone}
                        placeholder='XX:00 PM'
                        errors={errors}
                        validation={{ [`${day}_close`]: { required: true } }}
                        preventTyping={true}
                        handleUpdate={handleUpdate}
                      />

                      <div className="col-1" />

                      <MomentTimePicker
                        cols='4'
                        label='Peak Time Ends At'
                        name={`${day}_peak_end`}
                        value={value_for(form[`${day}_peak_end`], true)}
                        tz={form.time_zone}
                        placeholder='XX:00 PM'
                        preventTyping={true}
                        disabled={form[`${day}_closed`]}
                        handleUpdate={handleUpdate}
                      />
                    </FormRow>

                    {form.overridden_days.includes(day) && <div className="alert alert-warning">One or more Resource Types have hours override applied for this day of the week</div>}
                  </React.Fragment>
                )
              })
            }
          </div>

          <LocationHoursSpecialHours
            specialHours={form.special_hours || []}
            newLocation={newLocation}
            tz={form.time_zone}
            handleManualUpdate={handleManualUpdate}
            handleCheckboxUpdate={handleCheckboxUpdate}
            errors={errors}
          />
        </>
      }
    </div>
  )
}
