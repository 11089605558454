import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectLocationId } from '@/features/Locations/locationSlice'
import { setLoading } from '@/features/Schedule/scheduleSlice'
import { setInputLocked } from '@/features/Calendar/calendarSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'

import {
    selectBookingId,
    selectOpenAddCardToBookingModal,
    setOpenAddCardBookingModal,
    addCardToBooking,
} from '@/features/EditBooking/editBookingSlice'

import {
    selectTerminalResponse,
    authorizeCardViaTerminal,
    cancelTerminalTransaction,
    clearAdyenState
} from '@/features/Adyen/adyenSlice'

import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function AddCardToBookingModal() {

    const dispatch         = useDispatch()
    const bookingId        = useSelector(selectBookingId)
    const locationId       = useSelector(selectLocationId)
    const isOpen           = useSelector(selectOpenAddCardToBookingModal)
    const terminalResponse = useSelector(selectTerminalResponse)

    const handleClose = () => {
        dispatch(cancelTerminalTransaction())
        dispatch(setOpenAddCardBookingModal(false))
        dispatch(clearAdyenState())
    }

    const handleAuthorizeCard = () => {
        dispatch(setLoading(true))
        dispatch(setInputLocked(true))
        dispatch(authorizeCardViaTerminal(locationId))
    }

    // automatically save and close once the
    // response comes back from the terminal
    useEffect(() => {
        if (isOpen && !!terminalResponse) {
            dispatch(addCardToBooking(bookingId, terminalResponse))
            .then(({ data }) => {
                if (data.success) {
                    dispatch(addAlert({ type: 'success', text: data.message }))
                } else {
                    dispatch(addAlert({ type: 'error', text: data.message }))
                }
            })
            .catch((e) => {
                if (console) { console.error(e.response.data.error) }
                dispatch(addAlert({ type: 'error', text: e.response.data.message }))
            })
            .finally(() => {
                dispatch(setLoading(false))
                dispatch(setInputLocked(false))
                handleClose()
            })
        }
    }, [isOpen, terminalResponse])

    useEffect(() => {
        if (isOpen) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            dispatch(clearAdyenState())
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [isOpen])

    return isOpen && (
        <div className='modal modal-backdrop modal--sm add-card-to-booking-modal'>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-container modal-content">
                    <div className="modal-header">
                        <div className='modal-title'>
                            Capture Payment Card
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="modal-body-main">
                            <div className="modal-body-main-section text-center p-4 mt-3">
                                {
                                    <AdyenTerminalTransaction
                                        locationId={locationId}
                                        buttonText='Capture'
                                        buttonTextProcessing='Capturing...'
                                        buttonTextProcessed='Captured!'
                                        menuClassName='border border-color-gray4'
                                        transactionCallback={handleAuthorizeCard}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type='button'
                            className='btn btn-outline-primary px-5'
                            children='Cancel'
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
