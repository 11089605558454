import React from 'react'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import CheckCard from '@/features/AdvancedPointOfSale/components/cards/CheckCard'

export default function ChecksGrid({
    content:checks=[],
    currentTab=null,
    loading=false,
    onCheckClick=() => {}
}) {

    const content = () => {
        switch(true) {
            case loading === true : return (
                <CenteredText textClassName='text-gray2'>
                    <i className='fa-duotone fa-loader fa-spin-pulse fa-2xl' />
                </CenteredText>
            )

            case Array.isArray(checks) && checks.length === 0 : return (
                <CenteredText>None Found</CenteredText>
            )

            case Array.isArray(checks) && checks.length > 0 : return (
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 align-items-stretch no-gutters'>
                    {
                        checks.map((check) => (
                            <CheckCard
                                key={`check-${check.id}`}
                                className='col'
                                data={check}
                                currentTab={currentTab}
                                showSinceTimer
                                onClick={onCheckClick}
                            />
                        ))
                    }
                </div>
            )

            default : return null
        }
    }

    return content()
}
