import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { selectLocationId } from '@/features/Locations/locationSlice'

import {
    closeModal,
    createTabForCheck,
    openModal,
    selectCurrentCheck,
    selectComponent,
    selectHasVirtualKeyboard,
    selectModals,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import {
    authorizeCardViaTerminal,
    clearAdyenState,
    cancelTerminalTransaction
} from '@/features/Adyen/adyenSlice'

import ButtonTabNav from '@/features/AdvancedPointOfSale/components/tabs/ButtonTabNav'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import NameInput from '@/features/AdvancedPointOfSale/components/forms/NameInput'
import Checkbox from '@/components/Form/Checkbox'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import ExistingCardsOnFile from '@/features/AdvancedPointOfSale/components/ExistingCardsOnFile'
import { PREVENT_LOADER } from '@/lib/Storage'

const defaultModes = ['custom_name']

export default function CreateNewTabModal() {

    const dispatch               = useDispatch()
    const formMethods            = useForm({ mode: 'all' })
    const name                   = formMethods.watch('name')
    const hasVirtualKeyboard     = useSelector(selectHasVirtualKeyboard)
    const currentComponent       = useSelector(selectComponent)
    const currentCheck           = useSelector(selectCurrentCheck)
    const { createNewTab:modal } = useSelector(selectModals)
    const locationId             = useSelector(selectLocationId)
    const submitButtonRef        = useRef()

    const [modes, setModes]                            = useState(defaultModes)
    const [mode, setMode]                              = useState(defaultModes[0])
    const [participant, setParticipant]                = useState(null)
    const [verticallyCentered, shouldVerticallyCenter] = useState(true)
    const [selectedProfile, setSelectedProfile]        = useState(null)
    const [hasMembership, setHasMembership]            = useState(false)
    const [membershipId, setMembershipId]              = useState(null)
    const [isCreating, setIsCreating]                  = useState(null)

    const participants = useMemo(() => (
        currentCheck?.booking?.customers || []
    ), [currentCheck])

    const tabEmails = useMemo(() => currentCheck?.tabs?.map(tab => tab.email), [currentCheck])

    const customer = useMemo(() => ({
        name: name || participant?.name,
        email: participant?.email || ''
    }), [name, participant])

    const handleClose = (skipReset=false) => {
        if (skipReset) {
            dispatch(cancelTerminalTransaction())
            dispatch(closeModal('createNewTab'))
        } else {
            formMethods.reset()
            setParticipant(null)
            setSelectedProfile(null)
            setHasMembership(false)
            setMembershipId(null)
            setModes(defaultModes)
            setMode(defaultModes[0])
            handleClose(true)
        }
    }

    const handleModeChange = (mode) => {
        formMethods.reset()
        setParticipant(null)
        setMode(mode)
    }

    // either set the customer or deselect the customer if
    // clicking on the same name in the list a second time
    const handleParticipantSelection = (customer) => {
        if (customer.id === participant?.id) {
            setParticipant(null)
        } else {
            setParticipant(customer)
            setMembershipId(customer.member?.active_membership?.membership_id)
        }
    }

    const handleCreate = () => {
        setIsCreating(true)

        dispatch(createTabForCheck(customer, selectedProfile, membershipId))
        .then((data) => {
            if (data.success) {
                dispatch(clearAdyenState())
                handleClose()

                if (/^MenuItem$/i.test(currentComponent)) {
                    dispatch(openModal('addToChitTab'))
                }
            }
        })
        .finally(() => {
            setIsCreating(false)
        })
    }

    const handleAuthorizeCard = () => {
        setSelectedProfile(null)
        dispatch(authorizeCardViaTerminal(locationId))
    }

    useEffect(() => {
        if (modal.isOpen) {
            formMethods.reset()
            window.sessionStorage.setItem(PREVENT_LOADER, true)

            if (
                !modes.includes('participants')
                && !!currentCheck.booking
                && participants.length > 0
            ) {
                const updatedModes = [...modes]
                updatedModes.splice(1,0, 'participants')
                setModes(updatedModes)
            }
        } else {
            formMethods.reset()
            dispatch(clearAdyenState())
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [modal.isOpen])

    return modal.isOpen && (
        <FormProvider {...formMethods}>
            <Modal
                className='create-new-tab-modal'
                title='Create New Tab'
                isOpen={modal.isOpen}
                verticallyCenter={verticallyCentered}
                footerButtons={<>
                    <button
                        type='button'
                        className='btn btn-outline-secondary text-white text-bold mr-auto py-2'
                        children='Cancel'
                        onClick={handleClose}
                    />
                    <button
                        type='button'
                        ref={submitButtonRef}
                        children={isCreating ? 'Please Wait' : 'Create'}
                        className="btn btn-primary ml-auto py-2"
                        disabled={
                            isCreating
                            || (mode === 'custom_name' && !customer.name)
                            || (mode === 'participants' && !customer.name)
                            || (hasMembership && !membershipId)
                        }
                        onClick={handleCreate}
                    />
                </>}
                onClose={handleClose}
            >
                {
                    modal.isOpen && (
                        <div className="px-4 pt-4 pb-0 mt-2 w-100">
                            {
                                modes.length > 1 && (
                                    <ButtonTabNav
                                        tabs={modes}
                                        currentTab={mode}
                                        className='d-flex justify-content-between pb-4 mb-2'
                                        onChange={handleModeChange}
                                    />
                                )
                            }

                            {
                                // custom name input
                                mode === modes[0] && <>
                                    <div className='pb-4'>
                                        <NameInput
                                            field='name'
                                            className='my-n2'
                                            inputContainerClassName='col-12 px-1'
                                            autoFocus={false}
                                            withWrapper={false}
                                            onFocus={() => { if (hasVirtualKeyboard) { shouldVerticallyCenter(false) }}}
                                            onBlur={() => { if (hasVirtualKeyboard) { window.setTimeout(() => shouldVerticallyCenter(true), 200) }}}
                                            onEnterCallback={() => { submitButtonRef.current.click() }}
                                        />
                                    </div>
                                    <Checkbox
                                        cols=''
                                        name='has_membership'
                                        label="Has Membership?"
                                        value={hasMembership}
                                        className=''
                                        handleChange={() => {
                                            if (hasMembership) {
                                                setMembershipId(null)
                                            }

                                            setHasMembership(!hasMembership)
                                        }}
                                    />
                                    {hasMembership &&
                                        <input
                                            className={`form-control`}
                                            placeholder="Member ID..."
                                            value={membershipId || ''}
                                            type="text"
                                            onChange={(e) => setMembershipId(e.target.value)}
                                        />
                                    }

                                    <hr className='mt-2 mb-1' />
                                </>
                            }

                            {
                                // waivers/participants
                                modes.includes('participants') && mode === modes[1] && (
                                    <div className='participants-list scrollhint'>
                                        <div className='border-bottom border-color-gray3 pb-4 mx-n1 scrollhint--inner'>
                                            {
                                                participants.map((customer) => (
                                                    <ModalListButton
                                                        key={customer.id}
                                                        children={<>
                                                            <span>{customer.name}</span>
                                                            <span className='ml-auto'>
                                                                {customer.is_checked_in && <span className='ml-2 text-success'><i className="fa-solid fa-badge-check"></i></span>}
                                                                {customer.is_minor && <span className='ml-2 badge badge-pill badge-warning p-1'>M</span>}
                                                            </span>
                                                            {customer.member?.active_membership &&
                                                                <span className='badge badge-info ml-2'>
                                                                    <i className="fa-solid fa-id-badge"></i>
                                                                    <span className='ml-1'>{customer.member.active_membership.membership_id}</span>
                                                                </span>
                                                            }
                                                        </>}
                                                        disabled={tabEmails.includes(customer.email) || !customer.is_checked_in}
                                                        selected={customer.id === participant?.id}
                                                        onClick={() => handleParticipantSelection(customer)}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }

                            {
                                currentCheck?.booking?.payment_profiles?.length > 0 && (
                                    <div className="alert alert-warning rounded my-4 text-center font-weight-bold font-italic" role="alert">
                                        <p className='mb-0'>Optional: Attach a credit card to the new tab.</p>
                                        <p className='mb-0'>Either capture a new credit card or choose an existing stored card.</p>
                                    </div>
                                )
                            }

                            <div className='card-capture pt-4 pb-3'>
                                <h6>
                                    Capture a credit card
                                    <small className='pl-1 font-weight-light'>(Optional)</small>
                                </h6>
                                <AdyenTerminalTransaction
                                    locationId={locationId}
                                    buttonText='Capture'
                                    buttonTextProcessing='Capturing...'
                                    buttonTextProcessed='Captured!'
                                    menuClassName='border border-color-gray4'
                                    transactionCallback={handleAuthorizeCard}
                                />
                            </div>

                            <ExistingCardsOnFile
                                className='pb-4'
                                profiles={currentCheck?.booking?.payment_profiles}
                                selected={selectedProfile}
                                isOptional
                                onChange={(profile) => {
                                    dispatch(cancelTerminalTransaction())
                                    setSelectedProfile(profile)
                                }}
                            />
                        </div>
                    )
                }
            </Modal>
        </FormProvider>
    )
}
