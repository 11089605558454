import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBooking, setOpenAddCardBookingModal } from '@/features/EditBooking/editBookingSlice'
import { isBookingCancelled } from '@/lib/Booking'

export default function CapturePaymentCard({
    className='btn-primary',
    disabled=false
}) {

    const dispatch = useDispatch()
    const booking  = useSelector(selectBooking)

    const open = () => {
        dispatch(setOpenAddCardBookingModal(true))
    }

    return (
        <button
            children={Object.values(booking.payment_profiles).length >= 1 ? 'Card Captured' : 'Capture Payment Card'}
            className={`btn ${className} btn-block`}
            disabled={disabled || isBookingCancelled(booking) || !!booking.check}
            onClick={open}
        />
    )
}
