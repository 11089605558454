import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
    sendPaymentReceipt,
    sendRefundReceipt,
    sendConfirmation,
    notifyChange,
    notifyCancellation,
    notify24Hour,
} from './bookingHistorySlice'
import { selectTimeZone } from "../Locations/locationSlice"
import { selectBooking } from '../EditBooking/editBookingSlice'
import moment from 'moment'

export default function BookingHistoryEmails() {

    const dispatch         = useDispatch()
    const booking          = useSelector(selectBooking)
    const selectedTimeZone = useSelector(selectTimeZone)

    const [init, setInit]           = useState(false)
    const [emailLogs, setEmailLogs] = useState([])

    useEffect(() => {
        axios.get(`/bookings/${booking.id}/email_logs`).then(({ data }) => {
            setEmailLogs(data)
        })
    }, [])

    useEffect(() => {
        if (init || !booking.id) {
            return
        }

        setInit(true)
    }, [init, booking])

    const resendEmail = (emailLog) => {
        switch (emailLog.title) {
            case "Payment Receipt":
                return dispatch(sendPaymentReceipt(emailLog.extra_data.payment_id))
            case "Confirmation Email":
                return dispatch(sendConfirmation())
            case "Reservation Cancelled":
                return dispatch(notifyCancellation())
            case "Reservation Changed":
                return dispatch(notifyChange(emailLog.extra_data.version_id))
            case "Refund Receipt":
                return dispatch(sendRefundReceipt(emailLog.extra_data.refund_id))
            case "24 Hour Reminder":
                return dispatch(notify24Hour())
        }
    }

    return (
        <table className="table table-middle table-sm mb-0">
            <colgroup>
                <col width='120px' />
                <col width='auto' />
                <col width='auto' />
                <col width='130px' />
            </colgroup>
            <thead>
                <tr>
                    <th className='border-bottom text-gray3 px-1'>Date &amp; Time</th>
                    <th className='border-bottom text-gray3 px-1'>Type</th>
                    <th className='border-bottom text-gray3 px-1'>Recipient</th>
                    <th className='border-bottom text-gray3 px-1'></th>
                </tr>
            </thead>
            <tbody>
                {
                    emailLogs.map(emailLog => {
                        return (
                            <tr key={emailLog.id}>
                                <td className="text-right text-gray3 smaller pr-4">
                                    <strong>
                                        {moment.tz(emailLog.createdAt, selectedTimeZone).format('MMM D, YYYY')}
                                        <br />
                                        {moment.tz(emailLog.createdAt, selectedTimeZone).format('h:mm:ss:SSS A')}
                                    </strong>
                                </td>
                                <td><strong>{emailLog.title}</strong></td>
                                <td>{emailLog.email_to}</td>
                                <td>
                                    {emailLog.email_to && !/follow up|staff confirmation/i.test(emailLog.title) && (
                                        <button
                                            children='Resend'
                                            className="btn btn-primary w-100"
                                            name={emailLog.title}
                                            onClick={() => resendEmail(emailLog)}
                                        />
                                    )}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
