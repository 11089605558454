import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    setCreditToken,
    setBookingFormValue,
    setPaymentMethod,
    selectCreditToken,
    selectGiftCards,
    selectPaymentMethod,
    selectPaymentReady,
    finalizeBooking,
} from '../../bookingSlice'

import { selectPaymentTypes } from '@/features/Packages/packageSlice'
import { selectPaymentProcessor, selectLocation } from '../../../Locations/locationSlice'

import GiftCardFields from '@/features/Bookings/components/Step6/GiftCardFields'
import CreditCard from './CreditCard'
import AdyenCreditCard from '../../../Adyen/AdyenCreditCard'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentMethods({
    booking,
    errors,
    handleChange,
    handleManualChange,
    handleOnError,
    onNext,
    adyenOnNext,
    showGiftCardFields=false,
    customAmountToPay=null,
    setAdyenCheckout,
    adyenCheckout,
    paymentProcessor,
    locationId,
    locationTimeZone,
    environment,
})
{

    const dispatch                 = useDispatch()
    const creditToken              = useSelector(selectCreditToken)
    const giftCards                = useSelector(selectGiftCards)
    const paymentTypes             = useSelector(selectPaymentTypes)
    const location                 = useSelector(selectLocation)
    const locationPaymentProcessor = useSelector(selectPaymentProcessor)
    const paymentMethod            = useSelector(selectPaymentMethod)
    const paymentReady             = useSelector(selectPaymentReady)

    const targetPaymentProcessor = paymentProcessor || locationPaymentProcessor?.name
    const targetLocationId       = location?.id || locationId
    const targetLocationTimeZone = location?.time_zone || locationTimeZone

    const renderCreditCard = () => (
        targetPaymentProcessor === 'paysafe' ? (
            <CreditCard
                onNext={onNext}
                creditToken={creditToken}
                setToken={(token) => dispatch(setCreditToken(token))}
                setZip={(e) => dispatch(setBookingFormValue({ name: 'creditZip', value: e.target.value }))}
            />
        ) : (
            <AdyenCreditCard
                locationId={targetLocationId}
                locationTimeZone={targetLocationTimeZone}
                environment={environment}
                booking={booking}
                zipcode={booking.creditZip || ''}
                validateZipCode={!paymentReady}
                adyenCheckout={adyenCheckout}
                setAdyenCheckout={setAdyenCheckout}
                setZipCode={(e) => dispatch(setBookingFormValue({ name: 'creditZip', value: e.target.value }))}
                onSubmit={adyenOnNext || (() => dispatch(finalizeBooking()))}
            />
        )
    )

    // if the gift cards are ever wiped out, such as when
    // switching between full amount and deposit only, then
    // set the payment method to credit card in order to
    // bring back the credit card fields
    useEffect(() => {
        if (giftCards.length === 0) {
            dispatch(setPaymentMethod('credit_card'))
        }
    }, [giftCards])

    return paymentMethod !== 'none' && <>
        {
            (showGiftCardFields || paymentTypes.authorize_card_only === false) && (
                <div className="container form-container payment-container">
                    <h5>Gift Card</h5>

                    <GiftCardFields
                        booking={booking}
                        errors={errors}
                        customAmountToPay={customAmountToPay}
                        handleOnError={handleOnError}
                        handleManualChange={handleManualChange}
                        handleChange={handleChange}
                    />

                    <div className="mt-2 mb-4">
                        {
                            giftCards.map(giftCard => (
                                <div key={giftCard.cardNumber} className="mb-4 mb-md-2">
                                    <i className="fa fa-check mr-2" />

                                    <span className="text-monospace text-uppercase pr-md-4 d-inline-block mb-1">
                                        { giftCard.cardNumber }
                                    </span>

                                    <small className="d-inline-block mb-1 bg-success text-white py-1 px-3 rounded-pill text-bold">
                                        { numberToCurrency(giftCard.appliedAmount / 100.0) } APPLIED
                                    </small>

                                    <small className="d-inline-block mb-1 pl-2 pl-md-3 text-bold">
                                        Remaining <span className="d-none d-md-inline">Balance</span>:&nbsp;
                                        { numberToCurrency((giftCard.balance - giftCard.appliedAmount) / 100.0) }
                                    </small>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }

        <div className={`container form-container payment-container my-3 ${/^(credit_card|multiple)$/i.test(paymentMethod) ? 'd-block' : 'd-none'}`}>
            <h5>Credit Card</h5>
            { renderCreditCard() }
        </div>
    </>
}
