import React from 'react'

export default function StoredCreditCard({
    card=null,
    className='d-flex align-items-center justify-content-between text-monospace',
    shopperReference=null,
    isSelected=false,
    selectable=false,
    onClick=() => {},
}) {

    return !!card && (
        <span
            className={`stored-credit-card ${className} ${selectable ? 'cursor-pointer' : ''}`.trim()}
            onClick={onClick}
        >
            <span>
                <i className="fas fa-credit-card mr-3" />
                {`${card?.name}`}
            </span>

            <span className='pl-3'>
                {`(${card?.lastFour} ${card?.expiryMonth}/${card?.expiryYear})`}
            </span>

            {
                selectable && (
                    <span className='ml-auto'>
                        <input
                            readOnly
                            type="radio"
                            name="on_file_card"
                            value={shopperReference}
                            checked={isSelected}
                            onClick={onClick}
                        />
                    </span>
                )
            }
        </span>
    )
}
