import React from 'react'
import PartySizeMessageFormRow from './PartySizeMessageFormRow'
import PartySizeMessagesList from './PartySizeMessagesList'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function PartySizeMessages({ handleUpdate, messages }) {
    return (
        <div className="party-size-messages">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Party Size Selection Messages</span>
                    <Helpjuice articleId='party-size-selection-messages' extraClasses='' />
                </span>
            </h3>

            <PartySizeMessageFormRow
                handleUpdate={handleUpdate}
                messages={messages} />

            <PartySizeMessagesList
                handleUpdate={handleUpdate}
                messages={messages} />

        </div>
    )
}
