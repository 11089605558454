import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import calendarStore from '@/stores/calendarStore'
import memberStore from '@/stores/memberStore'
import notificationStore from '@/stores/notificationStore'

const stores = {
    calendarStore: calendarStore,
    memberStore: memberStore,
    notificationStore: notificationStore,
}

export default function Loader({ component=null, data }) {
    if (component === null || 'name' in component === false) {
        if (console) { console.error('YOU MUST PASS IN COMPONENT INFO') }
        return ''
    }

    const AdvPosDashboardCharts = lazy(() => import('@/components/AdvancedPointOfSale/DashboardCharts'))
    const ChooseDestination     = lazy(() => import('@/components/Sessions/ChooseDestination'))
    const ConfirmationLink      = lazy(() => import('@/components/ConfirmationLink'))
    const ContextSwitcher       = lazy(() => import('@/components/ContextSwitcher'))
    const LogoutButton          = lazy(() => import('@/components/Sessions/LogoutButton'))
    const ThemeToggle           = lazy(() => import('@/features/Utilities/ThemeToggle'))
    const Receipt               = lazy(() => import('@/features/AdvancedPointOfSale/components/ordering/Receipt'))
    const Totals                = lazy(() => import('@/features/AdvancedPointOfSale/components/ordering/receipt/Totals'))
    const RefundForm            = lazy(() => import('@/components/AdvancedPointOfSale/Checks/RefundForm'))
    const HelpjuiceLink         = lazy(() => import('@/components/HelpjuiceLink'))

    const renderComponent = () => {
        switch(true) {
            case component.name === 'ConfirmationLink' :
                return <ConfirmationLink {...data} />

            case component.name === 'ContextSwitcher' :
                return <ContextSwitcher {...data} />

            case component.name === 'LogoutButton' :
                return <LogoutButton {...data} />

            case component.name === 'ThemeToggle' :
                return <ThemeToggle {...data} />

            case component.name === 'ChooseDestination' :
                return <ChooseDestination {...data} />

            case component.name === 'AdvPosDashboardCharts' :
                return <AdvPosDashboardCharts {...data} />

            case component.name === 'Receipt' :
                return <Receipt {...data} />

            case component.name === 'Totals' :
                return <Totals {...data} />

            case component.name === 'HelpjuiceLink' :
                return <HelpjuiceLink {...data} />

            case component.name === 'RefundForm' :
                return <RefundForm {...data} />

            default :
                return ''
        }
    }

    return (
        <Provider store={stores[component.store || 'notificationStore']}>
            <Suspense fallback=''>
                { renderComponent() }
            </Suspense>
        </Provider>
    )
}
