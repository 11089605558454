import React from 'react'
import CustomerTypePricing from './CustomerTypePricing'
import DurationPricing from './DurationPricing'
import PricingHelper from '@/lib/Pricing'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function Pricing({ form={}, pricing, pricingType, customerTypes, isSpecialEvent=false, handleManualUpdate }) {

    const handlePriceUpdate = (e, duration, group, index) => {
        if (group.individualPricing && pricingType === PricingHelper.BY_CUSTOMER_TYPE) {
            // find the group -> map customer type prices -> map duration objects, update price for [e.target.name]: [e.target.value] if price.customerType === duration.customerType
            handleManualUpdate('pricing', pricing.map((pricingGroup, groupIndex) => {
                if (groupIndex === index) {
                    return {
                        ...pricingGroup,
                        prices: pricingGroup.prices.map(customerTypePrices => {
                            return customerTypePrices.map(durationPrice => {
                                // find the customer type & duration entry for this customer type duration
                                if (durationPrice.customerType === duration.customerType && durationPrice.duration === duration.duration) {
                                    return {
                                        ...durationPrice,
                                        [e.target.name]: e.target.value
                                    }
                                } else {
                                    return durationPrice
                                }
                            })
                        })
                    }
                } else {
                    return pricingGroup
                }
            }))
        } else {
            // find the group -> map duration objects where duration is the same
            handleManualUpdate('pricing', pricing.map((pricingGroup, groupIndex) => {
                if (groupIndex === index) {
                    return {
                        ...pricingGroup,
                        prices: pricingGroup.prices.map(price => {
                            if (price.duration === duration.duration) {
                                return {
                                    ...price,
                                    [e.target.name]: e.target.value
                                }
                            } else {
                                return price
                            }
                        })
                    }
                } else {
                    return pricingGroup
                }
            }))
        }
    }

    return pricing.sort((a, b) => (Number(a.groupMin + a.groupMax) > Number(b.groupMin + b.groupMax)) ? 1 : -1)
                .map((group, index) => {

        const pricingText = (group.individualPricing
                                ? 'Per Person'
                                : 'Ranged Group') + ' Pricing'

        const participantsPerLane = isSpecialEvent && parseInt(form?.special_event_capacity || 0) > 0 && parseInt(form?.available_lanes?.length || 0) > 0
                                        ? Math.ceil(parseInt(form.special_event_capacity) / parseInt(form.available_lanes?.length))
                                        : isSpecialEvent ? null : group.participantsPerLane

        const participantsPerLaneText = participantsPerLane
                                            ? `${participantsPerLane} ${participantsPerLane == 1 ? 'Participant' : 'Participants'} Per Lane`
                                            : 'Standard'

        return (
            <div className="price-group" key={index}>

                <div className="row mb-2">
                    <div className="col-6 pr-0">
                        <h3 className="d-flex justify-content-between align-items-center">
                            <span className='d-flex'>
                                <span>{group.groupMin} to {group.groupMax} ({pricingText})</span>
                                <Helpjuice articleId='group-size-pricing-table' extraClasses='' />
                            </span>
                        </h3>
                    </div>
                    <div className="col-6 pl-0 text-right">
                        <h3 className="mb-3">
                            Resource Occupancy: {participantsPerLaneText}
                        </h3>
                    </div>
                </div>
                <div className="row">
                        {
                            group.prices.map((prices, priceIndex) => {
                                if (group.individualPricing && pricingType === PricingHelper.BY_CUSTOMER_TYPE) {
                                    return (
                                        <div className="col-6" key={`ip-${group.groupMin + group.groupMax}-${priceIndex}`}>
                                            <CustomerTypePricing
                                                customerTypePrices={prices}
                                                customerTypes={customerTypes}
                                                handleUpdate={(e, duration) => handlePriceUpdate(e, duration, group, index)}
                                            />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col-6" key={`gp-${group.groupMin + group.groupMax}-${priceIndex}`}>
                                            <DurationPricing
                                                duration={prices}
                                                handleUpdate={(e, duration) => handlePriceUpdate(e, duration, group, index)}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                </div>

            </div>
        )
    })
}
