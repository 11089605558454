import React from 'react'
import { useDispatch } from 'react-redux'
import {
    openModal,
    setInMoveItemsMode,
    configureModal,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function NewTabButton({ id=null, className=null, disabled=false }) {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setInMoveItemsMode(false))
        dispatch(configureModal({ modal: 'selectTab', config: { uuids: [], fromTabId: null, toTabId: null } }))
        dispatch(openModal('createNewTab'))
    }

    return (
        <button
            id={id}
            type='button'
            className={className}
            children='Create New Tab'
            disabled={disabled}
            onClick={handleClick}
        />
    )
}
