import React from 'react'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import { numberToCurrency } from '@/lib/Number'

import {
    calculateSubtotalAmountCents,
    calculateTaxCents,
    modifierCentsFor,
    sortByAddedDateTime,
    groupItems,
    isDiscountable
} from '@/features/AdvancedPointOfSale/lib/Items'

export default function TabDetails({
    className='',
    tab={},
    check={},
    collapsable=false,
    collapsedByDefault=false,
    compact=false,
    hideCredits=true,
}) {
    const filteredItems = tab.items.filter(({ item }) => hideCredits ? !/^credit$/i.test(item.amount_type) : false)
    const groupedItems  = groupItems(sortByAddedDateTime(filteredItems), check)

    return <>
        <h6
            className='border-bottom thick-border pl-2 pr-1 pb-2 mb-0 d-flex justify-content-between align-items-center receipt-header'
            data-toggle={collapsable && groupedItems.length > 0 ? 'collapse' : null}
            data-target={collapsable && groupedItems.length > 0 ? `#collapsable-${tab.id}` : null}
            aria-expanded={!collapsedByDefault}
        >
            <span className='d-flex-inline text-truncate pr-2 align-items-center'>
                <span className='d-flex-inline align-items-center'>
                    { tab.is_tab_of_primary_check_owner && <i className='fas fa-star text-yellow mr-2' /> }
                    { !!tab.payment_profile && <i className="fa-regular fa-credit-card mr-2 text-white" /> }
                    { tab.membership &&
                        <span className='badge badge-info mr-2' style={{ fontSize: '75%', padding: '4px 6px', position: 'relative', top: '-1px' }}>
                            <i className="fa-solid fa-id-badge"></i>
                        </span>
                    }
                    { tab.is_general && check?.booking?.name || tab.name }
                </span>
                <TabStatus tab={tab} />
            </span>

            {
                collapsable && groupedItems.length > 0 && (
                    <span
                        children={<i className='fa-solid fa-chevron-up' />}
                        className='collapse-button text-white mr-2'
                    />
                )
            }
        </h6>

        <div
            id={collapsable ? `collapsable-${tab.id}` : null}
            className={`w-100 ${className} ${collapsable ? 'collapse' : ''} ${collapsable && collapsedByDefault ? '' : 'show'}`.trim()}
        >
            {
                groupedItems.length > 0 && <>
                    <table className='table-sm w-100 mt-1'>
                        <tbody className='list-border-bottom'>
                            {
                                groupedItems.map((data, index) => {
                                    const { item, config, notes, discount, count } = data

                                    if (!item) { return null }
                                    if (hideCredits && /^(credit)$/i.test(item.amount_type)) { return null }
                                    if (/^(manager_adjustment)$/i.test(item.type)) { return null }

                                    const comp     = (check.comps || []).find(({ item_uuid }) => item_uuid === item.uuid)
                                    const isComped = /^(approved)$/i.test(comp?.status)

                                    let finalPriceCents, priceCents, discountCents = 0
                                    let itemName

                                    switch(true) {
                                        // UNSPLIT BOOKING CARD
                                        case (/^(booking|general_booking)$/i.test(item.type)) : {
                                            const addonTimeBasePriceCents = item.addon_times.reduce((sum, item) => sum + item.base_price_cents, 0)
                                            const addonTimeDiscountCents  = item.addon_times.reduce((sum, item) => sum + item.discount_cents, 0)

                                            itemName        = 'RESERVATION AMOUNT'
                                            priceCents      = item.base_price_cents + item.discount_cents - addonTimeBasePriceCents
                                            discountCents   = item.discount_cents - addonTimeDiscountCents
                                            finalPriceCents = priceCents
                                            break
                                        }

                                        // SPLIT BOOKING CARDS
                                        // - booking split items (CT, uniform, group)
                                        // - addon time debits
                                        // - pre-payment credits
                                        // - deposit credits
                                        // - etc.
                                        case /^(booking[_]+.*)$/i.test(item.type) :
                                            itemName        = item.name
                                            priceCents      = item.subtotal_cents
                                            discountCents   = item.discount_cents
                                            finalPriceCents = (priceCents + discountCents) * (count || 1)
                                            break

                                        // F&B ITEMS
                                        case (/^(item)$/i.test(item.type)) : {
                                            if (isDiscountable(discount)) {
                                                const subtotal = calculateSubtotalAmountCents(item, config, discount)
                                                priceCents     = subtotal.priceCents
                                                discountCents  = subtotal.discountCents
                                            } else {
                                                const allModifiersSubtotalCents = calculateTaxCents({
                                                    amountCents: modifierCentsFor(item, config),
                                                    priceIncludesSalesTax: item.price_includes_sales_tax,
                                                    salesTaxRate: item.sales_tax_rate
                                                })?.[1] || 0

                                                priceCents = calculateSubtotalAmountCents(item, config) - allModifiersSubtotalCents
                                            }

                                            finalPriceCents = priceCents * (count || 1)

                                            itemName = item.name
                                            break
                                        }
                                    }

                                    return (
                                        <React.Fragment key={`check-tab-item-${index}`}>
                                            <tr className={`check-tab-item ${/^(booking|general_booking)$/i.test(item.type) ? 'font-weight-bold' : ''}`.trim()}>
                                                <td valign='top' className='check-tab-item-name pt-1 pb-0 text-left'>
                                                    { itemName } { count > 1 && ` x ${count}`}
                                                    {
                                                        !!notes && (
                                                            compact
                                                                ? <small><i className='fa-solid fa-circle-info ml-2' /></small>
                                                                : <small className='d-block mt-n1 ml-2 font-italic text-gray5'>—&nbsp;{ notes }</small>
                                                        )
                                                    }
                                                </td>
                                                <td valign='top' className='check-tab-item-price pt-1 pb-0 text-right'>
                                                    { numberToCurrency(finalPriceCents / 100) }
                                                </td>
                                            </tr>

                                            {
                                                Array.isArray(config) && config.filter((modifier) => modifier.chit_action).map((modifier) => {
                                                    const modifierSubtotalCents = calculateTaxCents({
                                                        amountCents: modifier.amount_cents,
                                                        priceIncludesSalesTax: item.price_includes_sales_tax,
                                                        salesTaxRate: item.sales_tax_rate
                                                    })?.[1]

                                                    return (
                                                        <tr key={modifier.id}>
                                                            <td className='pt-0 pb-1'>
                                                                <small className='d-block mt-n1 ml-2'>
                                                                    —&nbsp;
                                                                    { modifier.chit_action === 'add' ? 'ADD' : 'REMOVE'}&nbsp; &nbsp;
                                                                    { modifier.name }&nbsp;&nbsp;
                                                                </small>
                                                            </td>
                                                            <td valign='top' className='check-tab-item-price text-right text-nowrap py-0'>
                                                                <small className='d-block mt-n1 ml-2'>
                                                                    { modifier.chit_action === 'add' ? '+' : '-'}&nbsp;
                                                                    { numberToCurrency((modifier.chit_action === 'unavailable' ? 0 : modifierSubtotalCents) / 100) }
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                            {
                                                discountCents > 0 && (
                                                    <tr>
                                                        <td colSpan='2' valign='top' className='check-tab-item-price text-center text-success text-nowrap py-0'>
                                                            <small className='d-block mt-n1 w-100 font-italic ml-2'>
                                                                { !!discount && discount?.membership_id && 'Membership ' }
                                                                Discount
                                                                { !!discount && discount?.amount_type === 'percent' && ` (-${discount.amount}%)` }
                                                                { !!discount && discount?.notes && !compact && <span className='pl-1'>—&nbsp;&nbsp;{discount?.notes}</span> }
                                                                <span className='pl-2'>{ numberToCurrency((discountCents * (count || 1)) / 100 * -1) }</span>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            {
                                                isComped && (
                                                    <tr>
                                                        <td colSpan='2' valign='top' className='check-tab-item-price text-center text-success text-nowrap py-0'>
                                                            <small className='d-block mt-n1 w-100 font-italic ml-2'>
                                                                Comped
                                                                { !compact && comp?.comp_reason?.name && <span className='pl-2'>({ comp?.comp_reason?.name })</span> }
                                                                { !compact && comp?.notes && <span className='pl-2'>-&nbsp;{ comp?.notes }</span> }
                                                                <span className='pl-2'>{ numberToCurrency(priceCents * (count || 1) / 100 * -1) }</span>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                            <tr><td colSpan='2' className='py-1' /></tr>
                        </tbody>
                    </table>
                </>
            }
        </div>
    </>
}
