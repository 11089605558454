import React from 'react'
// import { useFormContext } from 'react-hook-form'
import Helpjuice from '@/features/Helpjuice/Helpjuice'
import ConfirmationChecksFields from './ConfirmationChecksFields'

export default function ConfirmationChecks({
    errors,
    confirmationChecks=[],
    disabledIfMembers,
    handleUpdate
}) {
    // const { register } = useFormContext()

    const name = 'confirmation_checks'

    // const field = register(name, {
    //     validate: () => {
    //         const empties = []
    //         confirmationChecks.map((item) => {
    //             if (item.text === '') {
    //               empties.push(item)
    //             }
    //         })
    //         return empties.length === 0 || 'All confirmation check fields must be filled out'
    //     }
    // })

    const handleAdd = () => {
        handleUpdate(name, confirmationChecks.concat({ text: '' }))
    }

    return (
        <div className="confirmation-checks">
            <h3>
                Confirmation Checks
                <Helpjuice articleId='membership-confirmation-checks' extraClasses='' />
            </h3>

            <button
                type='button'
                children='+ Add Confirmation Check'
                className="drawer-side-action btn btn-link px-0 mt-n1"
                disabled={disabledIfMembers}
                onClick={handleAdd}
            />

            { confirmationChecks.length > 0 ? (
                    <ConfirmationChecksFields
                        confirmationChecks={confirmationChecks}
                        disabledIfMembers={disabledIfMembers}
                        handleUpdate={handleUpdate}
                    />
                ) : (
                    <div className="row text-center">
                      <div className="col-12 text-muted">
                        None right now — why don't you add some?
                      </div>
                    </div>
                )
            }

            {
                errors && errors[name] && (
                    <div className="invalid-feedback d-block">
                        { errors[name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
