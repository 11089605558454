import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAmountTowardsBalance, selectTipAmount, selectIsAmountValid } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectLocationId } from '@/features/Locations/locationSlice'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import PaymentForm from '../PaymentForm'
import { numberToCurrency } from '@/lib/Number'

export default function Terminal({
    self,
    totalDue,
    tabs,
    currentTab,
    onTabChange,
    onLoad,
    onSubmit,
}) {

    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const isAmountValid        = useSelector(selectIsAmountValid)
    const tipAmount            = useSelector(selectTipAmount)
    const locationId           = useSelector(selectLocationId)

    const amountToProcess = useMemo(() => (
        parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0)
    ), [tipAmount, amountTowardsBalance])

    const processButton = useMemo(() => (
        <AdyenTerminalTransaction
            id='adyen-terminal-transaction'
            buttonText={`Process ${numberToCurrency(amountToProcess)}`}
            buttonTextProcessing='Please Wait...'
            buttonClassName='process-button float-right text-nowrap'
            menuClassName='border border-color-gray4'
            locationId={locationId}
            isAmountValid={isAmountValid}
            displayAsInline
            transactionCallback={() => {
                onSubmit({
                    totalReceivedAmount: amountToProcess,
                    tipAmount: tipAmount || 0,
                })
            }}
        />
    ), [isAmountValid, tipAmount, amountToProcess, locationId])

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            creditTabs={tabs}
            creditCurrentTab={currentTab}
            onCreditTabChange={onTabChange}
        />
    )
}
