import React from 'react'
import { Provider } from 'react-redux'
import HelpjuiceModal from '@/features/Helpjuice/HelpjuiceModal'
import calendarStore from '@/stores/calendarStore'
import memberStore from '@/stores/memberStore'

const stores = {
    calendarStore: calendarStore,
    memberStore: memberStore,
}

export default function NotificationContainer({ store='calendarStore' }) {
    return (
        <Provider store={stores[store]}>
            <HelpjuiceModal />
        </Provider>
    )
}
