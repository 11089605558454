import React from 'react'
import { useFormContext } from 'react-hook-form'
import ConfirmationChecksFields from './ConfirmationChecksFields'
import Helpjuice from '@/features/Helpjuice/Helpjuice'

export default function ConfirmationChecks({ errors, confirmationChecks=[], handleUpdate }) {
    const { register } = useFormContext()

    const name = 'confirmation_checks'

    const field = register(name, {
        validate: () => {
            const empties = []
            confirmationChecks.map((item) => {
                if (item.text === '') {
                  empties.push(item)
                }
            })
            return empties.length === 0 || 'All confirmation check fields must be filled out'
        }
    })

    const handleAdd = () => {
        handleUpdate(name, confirmationChecks.concat({ text: '' }))
    }

    return (
        <div className="confirmation-checks">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Confirmation Checks</span>
                    <Helpjuice articleId='confirmation-checks' extraClasses='' />
                </span>
            </h3>

            <div className="drawer-side-action btn btn-link" onClick={handleAdd}>
                + Add Confirmation Check
            </div>

            { confirmationChecks.length > 0 ? (
                    <ConfirmationChecksFields
                        confirmationChecks={confirmationChecks}
                        handleUpdate={handleUpdate} />
                ) : (
                    <div className="row text-center">
                      <div className="col-12 text-muted">
                        None right now — why don't you add some?
                      </div>
                    </div>
                )
            }

            {
                errors && errors[name] && (
                    <div className="invalid-feedback d-block">
                        { errors[name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
