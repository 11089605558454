import React, { useRef } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { selectTimeZone } from "../Locations/locationSlice"
import { selectBookingSource, selectBookingDeposit, selectBooking } from '../EditBooking/editBookingSlice'
import { setOpenResendReceiptModal, setResendReceiptPayment } from './bookingHistorySlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import RefundForm from './RefundForm'
import RefundRow from './RefundRow'
import StatusBadge from './StatusBadge'
import { inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'
import { numberToCurrency } from '@/lib/Number'
import titleize from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function PaymentRow({
    index,
    parentId,
    payment,
    preventRefunds=false,
    preventEmailingReceipts=false,
    onRefundRowToggle = () => {},
}) {

    const collapseElement  = useRef([])
    const dispatch         = useDispatch()
    const bookingSource    = useSelector(selectBookingSource)
    const bookingDeposit   = useSelector(selectBookingDeposit)
    const selectedTimeZone = useSelector(selectTimeZone)
    const location         = useSelector(selectLocation)
    const booking          = useSelector(selectBooking)

    const creditCardUsed = (
        payment?.payment_profile?.storedPaymentMethods?.[0]?.lastFour
        || payment?.payment_response?.card?.lastDigits
        || (payment?.transaction_type == 'terminal' ? 'In Person' : false)
        || 'Card On File'
    )

    // NOTE only credit/terminal transactions will have a processor ID so all
    // others are assumed to be complete because they're cash/check/gift card
    const paymentStatus = (
        payment.payment_processor_id
            ? payment.adyen_status || payment.payment_response?.response?.resultCode || payment.payment_response?.status
            : 'complete'
    )

    const handleResendReceipt = () => {
        dispatch(setOpenResendReceiptModal(true))
        dispatch(setResendReceiptPayment(payment))
    }

    const handleRefundRowToggle = () => {
        window.setTimeout(() => {
            onRefundRowToggle(jQuery(`#${parentId} .collapse.show`).length > 0)
        }, 500)
    }

    return <>
        {
            !preventRefunds && payment.refunds.map((refund) => (
                <RefundRow key={refund.id} refund={refund} />
            ))
        }

        <tr id={`payment-${payment.id}`} className="payment-row">
            {
                debug && (
                    <td className='pl-0 text-muted'>
                        <strong>{payment.id}</strong>
                    </td>
                )
            }

            <td className="pl-0">
                <strong>
                    {moment.tz(payment.created_at, selectedTimeZone).format('M/D/YYYY')}<br />
                    {moment.tz(payment.created_at, selectedTimeZone).format('h:mm:ss A z')}
                </strong>
            </td>

            <td className="pl-0">
                <strong>{payment.payee_name || "N/A"}</strong>
            </td>

            <td className="pl-0">
                <strong>{titleize(payment.transaction_type)}</strong>
                <span className="text-monospace">
                    {/^credit|terminal$/i.test(payment?.transaction_type) && !!(payment?.payment_profile || payment?.payment_response) && ` (${creditCardUsed})`}
                    {payment.transaction_type === 'check' && payment.check_number && ` (${payment.check_number})`}
                    {payment.transaction_type === 'gift_card' && payment.gift_card_last_digits && ` (${String(payment.gift_card_last_digits).toUpperCase()})`}
                </span>
            </td>

            <td className="pl-0">
                <strong className='text-success'>{numberToCurrency((payment?.payment_amount_cents || 0) / 100)}</strong>
            </td>

            <td className="pl-0">
                {
                    !!booking.check && !!payment.payment_amount_cents && (payment?.amount_refundable_booking_cents || 0) > 0 && (
                        <strong className='text-success'>{numberToCurrency((payment?.amount_refundable_booking_cents || 0) / 100) }</strong>
                    )
                }
            </td>

            <td className='px-0'>
                { Boolean(payment.tip_cents) &&
                    <span className="badge badge-success">{numberToCurrency(payment.tip_cents / 100)} TIP</span>
                }
                { Boolean(bookingDeposit) && bookingDeposit === payment.payment_amount_cents &&
                    <span className="badge badge-success">DEPOSIT</span>
                }
            </td>

            <td className='px-0'>
                <StatusBadge status={paymentStatus} />
            </td>

            <td className='px-0'>
                <span className='badge badge-primary pill'>
                    {(!!payment.check_id || !!payment.tab_id) ?
                        <span>Paid Via POS</span>
                    :
                    <>
                        {payment.paid_via_payment_link ? "Payment Link" : `Paid ${ /^online$/i.test(bookingSource) ? 'Online' : 'Via Admin'}` }
                    </>
                    }
                </span>
            </td>

            <td className="px-0 text-right">
                {payment.settled && !preventRefunds && payment.amount_refundable_booking_cents > 0 && !inAlteration(booking?.check) && !booking?.check?.last_altered_at && <>
                    {!!booking.check ?
                        <a
                            children='Refund'
                            title="Refund this payment"
                            className="btn btn-primary btn-sm mr-1"
                            type="button"
                            href={`/companies/${location.company_id}/locations/${location.id}/pos/checks/${booking.check.check_number}/payments/${payment.id}/refunds/new`}
                            target="_blank"
                        />
                    :
                        <button
                            children='Refund'
                            title="Refund this payment"
                            className="btn btn-primary btn-sm mr-1"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapseRefund-${payment.id}`}
                            onClick={(e) => { handleRefundRowToggle(); return e; }}
                        />
                    }
                </>}

                {
                    !preventEmailingReceipts && (
                        <button
                            children={<i className="far fa-receipt" />}
                            title="Resend Payment Receipt"
                            className="btn btn-primary btn-sm"
                            onClick={handleResendReceipt}
                        />
                    )
                }
            </td>
        </tr>

        {
            !preventRefunds && payment.amount_refundable_booking_cents > 0 && (
                <tr className="refund-form-row">
                    <td colSpan={debug ? 10 : 9} className="border-top-0 p-0">
                        <div
                            className="collapse"
                            id={`collapseRefund-${payment.id}`}
                            data-parent={`#${parentId} tbody`}
                            ref={(e) => collapseElement.current[index] = e}
                        >
                            <RefundForm
                                payment={payment}
                                collapseElement={collapseElement}
                                index={index}
                                onClose={handleRefundRowToggle}
                            />
                        </div>
                    </td>
                </tr>
            )
        }
    </>
}
