import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectComponent } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import StaffName from '@/features/AdvancedPointOfSale/components/StaffName'
import SinceTimer from '@/features/AdvancedPointOfSale/components/SinceTimer'
import { determineReservationPartySize, inAlteration, isOpen } from '@/features/AdvancedPointOfSale/lib/Checks'
import { numberToCurrency } from '@/lib/Number'

export default function CheckCard({
    id=null,
    className='',
    data:check=null,
    currentTab:resourceTypeTab=null,
    layout='1-col',
    footerPrice=null,
    showStaff=true,
    showPartySize=true,
    showResourceTypeName=false,
    showSinceTimer=false,
    clickable=true,
    onClick=() => {},
    children
}) {

    const currentComponent     = useSelector(selectComponent)
    const timezone             = useSelector(selectTimeZone)
    const reservationPartySize = useMemo(() => determineReservationPartySize(check), [check])

    return !!check && (
        <div id={id} className={`check-card ${className}`.trim()}>
            <button
                type='button'
                className='btn check-card'
                disabled={!clickable}
                onClick={(e) => onClick(check, e)}
            >
                <div className='check-card--content'>
                    <div className='col--main'>
                        {
                            !!check?.booking && (
                                <div className='check-card--header mb-2'>
                                    {
                                        !!check.booking?.resource_name && (
                                            <strong className='d-block reservation-resources'>
                                                { check.booking.resource_name }
                                            </strong>
                                        )
                                    }

                                    {
                                        showPartySize && reservationPartySize && (
                                            <div className='reservation-participants nowrap'>
                                                Party Size: { reservationPartySize }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }

                        <div className='check-card--body'>
                            <div>
                                <span className='h4 p-0 mt-0 mb-1 font-weight-lighter'>
                                    { check?.name || check?.title || check?.booking?.name || '' }
                                </span>
                                { currentComponent === 'OpenChecks' && check.has_card_on_file && <i className="fa-regular fa-credit-card ml-2 text-white" /> }
                            </div>


                            {
                                // additional info that displays only for unassigned/anonymous/retail-checks
                                !!check.check_number && !check?.resource_type_id && (
                                    <div className='d-flex justify-content-between align-items-center pt-1 w-100'>
                                        <span>#{ check.check_number }</span>

                                        {
                                            !(showStaff && !!check.server && showSinceTimer) && (
                                                <SinceTimer
                                                    label='OPEN FOR'
                                                    className='ml-auto'
                                                    time={check.created_at}
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                !!check?.booking && (
                                    <span className='pt-1'>
                                        <strong className='pr-2'>RESERVATION</strong>
                                        { `${moment.tz(check.booking.start_time, timezone).format('LT')} - ${moment.tz(check.booking.calculated_end_time, timezone).format('LT')}` }
                                    </span>
                                )
                            }

                            {
                                (showResourceTypeName || (!!resourceTypeTab && /-1/.test(resourceTypeTab.id))) && !!check.resource_type_name && (
                                    <span className='pt-1'>
                                        <strong className='pr-2'>RESOURCE</strong>
                                        { check.resource_type_name }
                                    </span>
                                )
                            }

                            {
                                !!check.booking?.occasion_type && (
                                    <span className='pt-1'>
                                        <strong className='pr-2'>OCCASION</strong>
                                        { check.booking.occasion_type }
                                    </span>
                                )
                            }
                        </div>
                    </div>

                    {
                        /^1-col-with-actions$/i.test(layout) && (
                            <div className='col--actions'>
                                { children }
                            </div>
                        )
                    }
                </div>

                {
                    ((showStaff && !!check.server) || showSinceTimer || !!footerPrice) && (
                        <div className='check-card--footer'>
                            {
                                showStaff && !!check.server && (
                                    <StaffName className='staff' text={check.server.name} />
                                )
                            }

                            {
                                !!footerPrice && (
                                    <span className='price'>
                                        { numberToCurrency(footerPrice) }
                                    </span>
                                )
                            }

                            {
                                showSinceTimer && isOpen(check) && (
                                    <SinceTimer
                                        label='OPEN FOR'
                                        className='ml-auto'
                                        time={check.created_at}
                                    />
                                )
                            }

                            {
                                showSinceTimer && inAlteration(check) && !!check.last_altered_at && (
                                    <SinceTimer
                                        label='IN ALTERATION FOR'
                                        className='ml-auto'
                                        time={check.last_altered_at}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </button>
        </div>
    )
}
